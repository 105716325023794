import { useMutation, useQuery } from '@tanstack/react-query'
import { Group, GroupDto } from 'api/dto'
import axios from 'config/axios'
import { useCallback } from 'react'

export const listGroups = async (projectId: string, pageId: string, layerId: string) => {
  const { data } = await axios.get<GroupDto[]>('/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups')
  return data
}

export const listGroupsQuery = (projectId: string, pageId: string, layerId: string) => ({
  queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups'],
  queryFn: () => listGroups(projectId, pageId, layerId),
})

export const useListGroups = (projectId: string, pageId: string, layerId: string) => {
  return useQuery<GroupDto[], Error, Group[]>({
    queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups'],
    queryFn: () => listGroups(projectId, pageId, layerId),
    select: useCallback((response) => response.map((group) => new Group(group)), []),
    staleTime: 1000 * 60 * 15, // 15 minutes
  })
}

export const useGetGroup = (projectId: string, pageId: string, layerId: string, groupId: string) => {
  return useQuery({
    queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups', groupId],
    queryFn: async () => {
      const { data } = await axios.get<GroupDto>(
        '/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups/' + groupId,
      )
      return data
    },
    select: (response) => new Group(response),
  })
}

export const useCreateGroup = () => {
  return useMutation({
    mutationFn: ({ projectId, pageId, layerId, group }: { projectId: string; pageId: string; layerId: string; group: GroupDto }) => {
      return axios.post('/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups/', group)
    },
  })
}

export const useUpdateGroup = () => {
  return useMutation({
    mutationFn: ({ projectId, pageId, layerId, group }: { projectId: string; pageId: string; layerId: string; group: GroupDto }) => {
      const { id, ...rest } = group
      return axios.put('/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups/' + group.id, rest)
    },
  })
}

export const useDeleteGroup = () => {
  return useMutation({
    mutationFn: ({ projectId, pageId, layerId, groupId }: { projectId: string; pageId: string; layerId: string; groupId: string }) => {
      return axios.delete('/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups/' + groupId)
    },
  })
}

export const useUpdateGroupsOrder = () => {
  return useMutation({
    mutationFn: ({ projectId, pageId, layerId, groupIds }: { projectId: string; pageId: string; layerId: string; groupIds: string[] }) => {
      return axios.post('/projects/' + projectId + '/pages/' + pageId + '/layers/' + layerId + '/groups/order', { groupIds })
    },
  })
}
