import { GroupCanvas } from 'canvas/group'
import { IGroupCanvas } from 'canvas/types'
import { StateCreator } from 'zustand'
import { GroupSlice, createGroupSlice } from './group.store'
import { ShapeSlice, createShapeSlice } from './shape.store'

export const DELIMITER = '|'

export interface GroupShapeSlice {
  select: (id: string) => void
  pulsate: (id: string) => void
  changeVisibility: (id: string) => void
  resetSelectedEntities: () => void
  recalculateGroups: () => void
  recalculateGroup: (groupId: string) => void
  updateGroup: (group: Omit<IGroupCanvas, 'type'>) => void
  saveGroup: (groupId: string) => void
  deleteSelected: () => void
}

export interface SelectedSlice {
  selectedIdsToDelete?: string[]
  setSelectedIdsToDelete: (id?: string) => void

  selectedIds?: string[]
  setSelectedIds: (id?: string) => void
}

export const createSelectedSlice: StateCreator<SelectedSlice, [], []> = (set) => ({
  setSelectedIds: (id) => set((s) => ({ selectedIds: id ? s.selectedIds?.concat(id) : undefined })),
  setSelectedIdsToDelete: (id) => set((s) => ({ selectedIdsToDelete: id ? s.selectedIdsToDelete?.concat(id) : undefined })),
})

export const createGroupShapeSlice: StateCreator<GroupSlice & ShapeSlice & SelectedSlice, [], [], GroupShapeSlice> = (set, get, store) => ({
  select: (id) =>
    set(() => {
      const splitted = id.split(DELIMITER)
      const groups = get().groups
      const shapesIds: string[] = []
      const toDelete: string[] = []
      let selectedGroup: GroupCanvas | undefined
      if (splitted.length > 1) {
        selectedGroup = groups.find((g) => g.id === splitted[0])
        shapesIds.push(splitted[1])
        toDelete.push(id)
      } else if (splitted.length === 1) {
        selectedGroup = groups.find((g) => g.id === splitted[0])
        selectedGroup?.shapes.forEach((s) => {
          shapesIds.push(s.id)
          toDelete.push(`${s.groupId}${DELIMITER}${s.id}`)
        })

        toDelete.push(splitted[0])
      }

      return {
        selectedGroupToDelete: selectedGroup?.id,
        selectedIdsToDelete: toDelete,
        selectedIds: [splitted[0], ...shapesIds],
        selectedGroup,
      }
    }),
  pulsate: (id) => {
    const group = get().groups.find((g) => g.id == id)
    if (group) {
      const shapes = group.shapes
      for (let i = 0; i < shapes.length; i++) {
        const shape = shapes[i]
        shape.pulsate()
      }
    }
  },
  changeVisibility: (id) => {
    const group = get().groups.find((g) => g.id == id)
    if (group) {
      group.changeVisibility()
      createGroupSlice(set, get, store).updateGroupVisibility(group.id, group.isVisible)
    }
  },
  resetSelectedEntities: () => {
    const slice = createSelectedSlice(set, get, store)

    slice.setSelectedIds(undefined)
    slice.setSelectedIdsToDelete(undefined)

    createGroupSlice(set, get, store).setSelectedGroup(undefined)
    createShapeSlice(set, get, store).setSelectedShape(undefined)
  },
  recalculateGroup: (groupId: string) => createGroupSlice(set, get, store).recalculateGroup(groupId),
  recalculateGroups: () => {
    const groups = get().groups

    groups.forEach((g) => {
      g.calculate()
    })

    createGroupSlice(set, get, store).setGroups(groups)
  },
  updateGroup: (group: Omit<IGroupCanvas, 'type'>) => createGroupSlice(set, get, store).updateGroup(group),
  saveGroup: (groupId: string) => createGroupSlice(set, get, store).saveGroup(groupId),
  deleteSelected: () => {
    const selectedIds = get().selectedIdsToDelete

    const { deleteGroup, deletePoint, deleteShape, recalculateGroup, setSelectedGroup } = createGroupSlice(set, get, store)
    const { setSelectedIds, setSelectedIdsToDelete } = createSelectedSlice(set, get, store)

    if (selectedIds) {
      for (let i = 0; i < selectedIds.length; i++) {
        const selectedId = selectedIds[i]

        const selected = selectedId?.split(DELIMITER)
        if (!selected) {
          continue
        }

        const group = selected[0] // group
        const shape = selected[1] // shape
        const point = selected[2] // point

        if (point) {
          deletePoint(group, shape, point)
          recalculateGroup(group)
          continue
        }

        if (shape) {
          deleteShape(group, shape)
          recalculateGroup(group)
          continue
        }

        deleteGroup(group)
        recalculateGroup(group)
      }

      setSelectedIdsToDelete(undefined)
      setSelectedIds(undefined)
      setSelectedGroup(undefined)
    }
  },
})
