import { Button, Group, Modal, Text, Box, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showNotification } from '@mantine/notifications'
import { useAuth } from 'hooks/useAuth'
import { wait } from 'utils/timeout'
import { useCreateOrganization } from 'api/query/organization'
import { useStore } from 'zustand'
import { globalStore } from 'store/store'
import { ModalTypes } from './types'

interface FormProps {
  name: string
  description?: string
}

export function CreateOrganizationModal({ opened }: { opened: boolean }) {
  const { refreshSession } = useAuth()
  const { toggleModal } = useStore(globalStore, (state) => ({ toggleModal: state.toggleModal }))
  const { mutateAsync: createOrganization } = useCreateOrganization()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const form = useForm<FormProps>({
    initialValues: {
      name: '',
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      setIsSubmitting(true)
      await createOrganization(values)
      // we're waiting for the GSI to take effect
      await wait(1)
      await refreshSession()
      showNotification({
        title: t('Organization.OrganizationCreated'),
        message: t('Organization.OrganizationCreatedSuccessfully'),
        color: 'green',
      })

      toggleModal(ModalTypes.None)
      setIsSubmitting(false)
      form.reset()
    },
    [form, refreshSession, t, toggleModal, createOrganization],
  )

  return (
    <>
      <Modal
        opened={opened}
        size="lg"
        radius="xxs"
        onClose={() => toggleModal(ModalTypes.None)}
        title={
          <Text fz={20} lh="30px" fw={400}>
            {t('Organization.CreateOrganization')}
          </Text>
        }
      >
        <Box>
          <Text size="md" c="gray" mb={20}>
            {t('Organization.CreateOrganizationDescription')}
          </Text>
        </Box>

        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <Box display="flex" mt={10} mb={10} sx={{ flexFlow: 'row', marginBottom: '30px' }}>
            <TextInput
              sx={{ flex: 1 }}
              withAsterisk
              label={t('Organization.Name')}
              placeholder={t('Organization.Name') || ''}
              type="text"
              {...form.getInputProps('name')}
            />
          </Box>
          <Box mb={20} display="flex">
            <Textarea
              sx={{ flex: 1 }}
              label={t('Organization.Description')}
              placeholder={t('Organization.Description') || ''}
              rows={4}
              {...form.getInputProps('description')}
            />
          </Box>

          <Group justify="right" mt="md">
            <Button size="sm" radius="xxs" loading={isSubmitting} disabled={isSubmitting} type="submit">
              {t('Common.Create')}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}
