import { useListOrganizationProjects } from 'api/query/project'
import { Projects } from './projects'
import { Loading } from 'components/loading/loading'
import { ProjectView } from 'api/dto'

export function OrganizationProjects() {
  const { isLoading, data: organizationProjects, isSuccess } = useListOrganizationProjects()

  if (isLoading) {
    return <Loading height="100%" />
  }

  if (!isSuccess) {
    return null
  }

  return <Projects view={ProjectView.ORGANIZATION} projects={organizationProjects} isLoading={isLoading} />
}
