import { StateCreator } from 'zustand/vanilla'
import { CombinedShapeTypes } from 'canvas/shape/types/utils'

export interface ShapeSlice {
  selectedShape?: CombinedShapeTypes
  setSelectedShape: (shape: CombinedShapeTypes | undefined) => void
}

export const createShapeSlice: StateCreator<ShapeSlice, [], [], ShapeSlice> = (set) => ({
  setSelectedShape: (shape: CombinedShapeTypes | undefined) => set(() => ({ selectedShape: shape })),
})
