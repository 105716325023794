import { IPoint, IShape } from 'canvas/types'
import Konva from 'konva'
import { BaseShape } from './abstract-shape'
import { Anchor } from './anchor'
import { takeoffStore } from 'store/store'
import { hasAccess } from 'utils/features'
import { TakeoffFeature } from 'api/dto/feature'

export class Measure extends BaseShape<Konva.Group> implements IShape {
  line: Konva.Line
  label: Konva.Label
  distance: Konva.Text

  anchorGroup: Konva.Group
  anchors: Anchor[] = []

  #unsubscribe: () => void

  constructor(shape: IShape) {
    super(shape, new Konva.Group())

    const currentPoints = this.points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b), [])

    this.line = new Konva.Line({
      points: currentPoints,
      stroke: this.color,
      strokeWidth: 10,
    })

    this.anchorGroup = new Konva.Group()

    this.line.perfectDrawEnabled(false)

    this.line.on('click', this.onMouseClick)
    this.line.on('tap', this.onMouseClick)

    this.points.forEach((point) => {
      if (hasAccess(TakeoffFeature.TOOLBAR)) {
        this.#addAnchor(point)
      }
    })

    const box = this.line.getSelfRect()
    const x = box.x + box.width / 2
    const y = box.y + box.height / 2

    this.label = new Konva.Label({
      x,
      y,
    })

    this.label.add(
      new Konva.Tag({
        pointerDirection: 'down',
        pointerWidth: 10,
        pointerHeight: 0,
        lineJoin: 'round',
        shadowColor: 'black',
        shadowBlur: 10,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
        shadowOpacity: 0.5,
      }),
    )

    this.distance = new Konva.Text({
      fontSize: 20,
      padding: 2,
      fill: 'red',
    })

    this.label.add(this.distance)

    this.instance.add(this.line, this.label)

    this.#unsubscribe = takeoffStore.subscribe((state) => {
      if (this.isSelected(state.selectedIds)) {
        this.line.stroke('red')
      } else {
        this.line.stroke(this.color)
      }
    })
  }

  addPoint(point: IPoint) {
    const layer = this.instance.getLayer()
    if (layer == null) {
      return
    }

    this.points.push(point)

    const points = this.points || []

    this.line.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.#addAnchor(point)
  }

  movePoint(point: IPoint) {
    const points = [...this.points]
    const pointIndex = points.findIndex((p) => p.id === point.id)
    if (pointIndex === -1) {
      return
    }

    points[pointIndex] = point

    this.line.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.points = points

    this.moveLabel(this.line, this.label)
  }

  changeDistance(text: string) {
    this.distance?.text(text)
  }

  remove(): void {
    this.instance.remove()
    this.instance.destroy()

    this.label.destroy()
    this.distance.destroy()

    this.anchors.forEach((s) => s.remove())
    this.anchorGroup.remove()
    this.anchorGroup.destroy()

    this.#unsubscribe()
  }

  #addAnchor(point: IPoint, index?: number) {
    const anchor = new Anchor({
      shapeId: this.id,
      groupId: this.groupId,
      point,
      movePoint: (p: IPoint) => this.movePoint(p),
    })

    this.anchorGroup.add(anchor.instance)

    if (index) {
      this.anchors.splice(index, 0, anchor)
    } else {
      this.anchors.push(anchor)
    }
  }
}
