import { Box, Loader } from '@mantine/core'
import { ImgHTMLAttributes, useCallback, useState } from 'react'

const MAX_RELOAD = 10

interface ImageLoaderProps extends ImgHTMLAttributes<HTMLElement> {
  alt: string
  reloadTime: number
  isLoading?: boolean
}

export function ImageLoader({ src, alt, reloadTime, isLoading, style, ...rest }: ImageLoaderProps) {
  const [timesReloaded, setTimesReloaded] = useState(0)
  const [imageSrc, setImageSrc] = useState<string | undefined>(src)

  const handleImageError = useCallback(() => {
    setImageSrc(undefined)
    if (timesReloaded === MAX_RELOAD) {
      return
    }

    setTimeout(() => {
      setImageSrc(src)
      setTimesReloaded(timesReloaded + 1)
    }, reloadTime)
  }, [src, reloadTime, timesReloaded])

  if (isLoading) {
    return (
      <Box style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Box>
    )
  }

  return <img {...rest} style={style} src={imageSrc} onError={handleImageError} />
}
