import { Avatar as MantineAvatar, DefaultMantineColor } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useGetMeUser } from 'api/query/user'
import { forwardRef } from 'react'

type ExtendedCustomColors = 'custom-gray' | DefaultMantineColor

interface AvatarProps {
  backgroundColor: ExtendedCustomColors
  color: string
  clickable?: boolean
}

const useAvatarStyles = createStyles((_, { clickable = true }: { clickable?: boolean }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: clickable ? 'pointer' : 'default',
  },
}))

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({ backgroundColor, color, clickable, ...others }, ref) => {
  const { classes } = useAvatarStyles({ clickable })
  const { data: user, isLoading } = useGetMeUser()

  if (!user || isLoading) {
    return null
  }

  return (
    <MantineAvatar size="md" color={backgroundColor} c={color} ref={ref} {...others} className={classes.root}>
      {user.getInitials().toUpperCase()}
    </MantineAvatar>
  )
})
