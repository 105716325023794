import { Button, Group, Modal, Text, Box, FileButton, Progress } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fileAxios } from 'api/file/file-axios'
import { UploadPdf } from 'components/dropzone/dropzone'
import { AxiosProgressEvent } from 'axios'
import { useGetProjectSignedUrl } from 'api/query/project'

export function AddFile({
  opened,
  toggle,
  projectId,
  startPolling,
}: {
  opened: boolean
  toggle: () => void
  projectId?: string
  startPolling?: () => void
}) {
  const { t } = useTranslation()
  const { mutateAsync: getSignedUrl } = useGetProjectSignedUrl()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercentageCompleted, setUploadPercentageCompleted] = useState<number | null>(null)
  const [file, setFile] = useState<File | null>()

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 100))
    setUploadPercentageCompleted(percentCompleted)
  }

  const addFile = useCallback(async () => {
    setIsUploading(true)
    if (file && projectId) {
      //TODO notification

      const response = await getSignedUrl({ projectId, fileName: file.name })

      if (response) {
        await fileAxios.put(response.data, file, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'content-type': file.type,
          },
          onUploadProgress,
        })

        setTimeout(() => startPolling?.(), 1500)
      }
    }

    setIsUploading(false)
    setFile(null)
    toggle()
  }, [startPolling, getSignedUrl, projectId, file, toggle])

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      closeOnClickOutside={false}
      closeOnEscape={false}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Project.AddFile')}
        </Text>
      }
    >
      <Box>
        <Text>{t('Project.AddFileExplanation')}</Text>
      </Box>

      <Box>
        <UploadPdf onDrop={(files) => setFile(files[0])} />
      </Box>

      {uploadPercentageCompleted && (
        <Box mb={10} mt={10} display="flex" sx={{ flexFlow: 'column', alignItems: 'center' }}>
          <Text size="sm" fw={400}>
            Uploading file... {uploadPercentageCompleted}% completed
          </Text>
          <Progress w="100%" striped animated value={uploadPercentageCompleted} color="teal" size="sm" mt={10} mb={10} />
        </Box>
      )}

      <Group justify="right" mt="md">
        <Button disabled={!file} size="sm" loading={isUploading} radius="xxs" onClick={async () => await addFile()}>
          {t('Common.Add')}
        </Button>
      </Group>
    </Modal>
  )
}
