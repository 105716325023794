import { useCallback, useEffect } from 'react'
import { Actions } from './message'
import { useWebsocket } from './useWebsocket'
import { globalStore } from 'store/store'
import { globalQueryClient } from 'api/client'

export const usePageLayerConnect = () => {
  const { send, isInReadyState, result } = useWebsocket()

  const sendPageLayerConnect = useCallback(
    (userId: string, projectId: string, pageId: string, layerId: string) => {
      send(JSON.stringify({ action: Actions.CONNECT_PAGE, message: { userId, projectId, pageId, layerId } }))

      void globalQueryClient.invalidateQueries({
        queryKey: ['pages', 'recent'],
      })
    },
    [send],
  )

  useEffect(() => {
    if (!result) {
      return
    }

    if (result.isOnPageConnect) {
      globalStore.getState().setUsersConnected(result.message as string[])
    }
  }, [result])

  return {
    sendPageLayerConnect,
    isInReadyState,
  }
}
