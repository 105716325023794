import { StateCreator } from 'zustand/vanilla'
import { Renderer } from 'canvas/renderer/renderer'

export interface RendererSlice {
  renderer?: Renderer
  setRenderer: (renderer: Renderer | undefined) => void
}

export const createRendererSlice: StateCreator<RendererSlice, [], [], RendererSlice> = (set) => ({
  setRenderer: (renderer) => set({ renderer }),
})
