import { Modal, Button, Box, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface DeleteModalProps {
  opened: boolean
  name?: string
  toggle: () => void
  deleteFunction: () => void
}

export function DeleteModal({ opened, toggle, deleteFunction, name }: DeleteModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Common.Delete')}
        </Text>
      }
    >
      <Box mb={15}>
        <Text>{t('Delete.AreYouSure').replace('{0}', name || '')}</Text>
      </Box>
      <Box
        mt={20}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button size="sm" radius="xxs" variant="light" onClick={toggle}>
          {t('Common.Cancel')}
        </Button>
        <Button
          ml={10}
          size="sm"
          radius="xxs"
          variant="filled"
          color="red"
          onClick={() => {
            deleteFunction()
            toggle()
          }}
        >
          {t('Common.Delete')}
        </Button>
      </Box>
    </Modal>
  )
}
