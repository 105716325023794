import { IPoint } from 'canvas/types'
import { getDistanceBetweenTwoPoints } from '../math/math'
import { convertToFactor, ManualRepresentation } from 'utils/measure'
import { ScaleDto } from 'api/dto/layer'

export enum ScaleType {
  Manual = 'manual',
  Automatic = 'automatic',
}

export enum Axis {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface ScaleFactor {
  x: number
  y: number
}

interface AutomaticScale {
  chosenScale?: string
}

interface ManualScale {
  x?: string
  y?: string
}

interface CalculatedScale {
  xCalculated?: number
  yCalculated?: number
}

export class LayerScale {
  automatic: AutomaticScale
  manual: ManualScale
  calculated: CalculatedScale

  get parsedX(): ManualRepresentation | undefined {
    if (!this.manual.x) {
      return undefined
    }

    const parsed = JSON.parse(this.manual.x)

    return {
      first: parsed.first?.split(' ')[0] || undefined,
      second: parsed.second?.split(' ')[0] || undefined,
      fraction: parsed.fraction
        ? {
            num: parsed.fraction.num || undefined,
            den: parsed.fraction.den || undefined,
          }
        : undefined,
    }
  }

  get parsedY(): ManualRepresentation | undefined {
    if (!this.manual.y) {
      return undefined
    }

    const parsed = JSON.parse(this.manual.y)

    return {
      first: parsed.first?.split(' ')[0] || undefined,
      second: parsed.second?.split(' ')[0] || undefined,
      fraction: parsed.fraction
        ? {
            num: parsed.fraction.num || undefined,
            den: parsed.fraction.den || undefined,
          }
        : undefined,
    }
  }

  constructor(scale?: ScaleDto) {
    this.automatic = scale?.automatic || {}
    this.manual = scale?.manual || {}
    this.calculated = scale?.calculated || {}
  }

  hasCalculatedScale = (): boolean => {
    return this.calculated != undefined && this.calculated.xCalculated != undefined && this.calculated.yCalculated != undefined
  }

  isManual = (): boolean => {
    return this.manual.x != undefined || this.manual.y != undefined
  }

  calculateManualScale = (axis: Axis, pointA: IPoint, pointB: IPoint) => {
    const stringRepresentation = axis === Axis.Horizontal ? this.manual?.x : this.manual?.y
    const factor = convertToFactor(stringRepresentation!)

    if (this.manual && factor) {
      const distance = getDistanceBetweenTwoPoints(pointB, pointA)
      console.log('distance', distance)
      console.log('factor / distance', factor, distance, factor / distance)
      const calculated = factor / distance

      if (axis === Axis.Horizontal) {
        this.calculated.xCalculated = calculated
      } else {
        this.calculated.yCalculated = calculated
      }
    }

    return this
  }

  toDto() {
    return {
      automatic: this.automatic,
      manual: this.manual,
      calculated: this.calculated,
    }
  }
}
