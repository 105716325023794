import { ShapeTypes } from 'canvas/types'
import { StateCreator } from 'zustand'

export interface UserSlice {
  userId?: string
  setUserId: (userId: string) => void

  usersConnected: string[]
  setUsersConnected: (usersConnected: string[]) => void
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  setUserId: (userId: string) => set(() => ({ userId })),
  usersConnected: [],
  setUsersConnected: (usersConnected) => set({ usersConnected }),
})

export interface DrawingSlice {
  isDrawing: boolean
  drawingType: ShapeTypes
  setDrawingType: (type: ShapeTypes) => void
}

export const createDrawingSlice: StateCreator<DrawingSlice, [], [], DrawingSlice> = (set) => ({
  isDrawing: false,
  drawingType: ShapeTypes.None,
  setDrawingType: (type) => set(() => ({ drawingType: type, isDrawing: type !== ShapeTypes.None })),
})

export interface DrawerSlice {
  drawerOpened: boolean
  toggleDrawer: () => void
}

export const createDrawerSlice: StateCreator<DrawerSlice, [], [], DrawerSlice> = (set) => ({
  drawerOpened: false,
  toggleDrawer: () => set((state) => ({ drawerOpened: !state.drawerOpened })),
})

export interface ConfigSlice {
  config: {
    projectId?: string
    pageId?: string
    layerId?: string
  }
  setConfig: (config: { projectId: string; pageId: string; layerId: string }) => void
}

export const createConfigSlice: StateCreator<ConfigSlice, [], [], ConfigSlice> = (set) => ({
  config: {},
  setConfig: (config) => set(() => ({ config })),
})
