import { showNotification } from '@mantine/notifications'
import { globalQueryClient } from 'api/client'
import { Layer, LayerDto, UpdateLayerDto } from 'api/dto/layer'
import axios from 'config/axios'
import { takeoffStore } from 'store/store'
import { LayerScale } from 'utils/scale/scale'

export class LayerService {
  private static get takeoffConfig() {
    return takeoffStore.getState().config
  }

  static async updateLayer(layer: Partial<LayerDto>) {
    const { projectId, pageId, layerId } = this.takeoffConfig || {}

    const currentLayer = globalQueryClient.getQueryData<LayerDto>(['projects', projectId, 'pages', pageId, 'layers', layerId])

    if (!currentLayer) {
      showNotification({
        title: 'Error',
        message: 'Layer not found',
        color: 'red',
      })
      return
    }

    const layerToUpdate = new Layer({
      ...currentLayer,
      ...layer,
    })

    try {
      const { data } = await axios.put<UpdateLayerDto>(
        `/projects/${projectId}/pages/${pageId}/layers/${layerId}`,
        layerToUpdate.toUpdateDto(),
      )
      await globalQueryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId],
      })
    } catch (error: any) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    }
  }
}
