import { stringify } from 'csv-stringify/sync'

/**
 * @desc make csv from given data
 * @param rows
 * @param filename
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const exportToCsv = <T extends Record<string, any>>(rows: T[], filename: string) => {
  if (!rows.length) {
    return
  }

  const csvContent = stringify(rows, {
    header: true,
    columns: Object.keys(rows[0]).map((key) => ({
      key,
      header: key,
    })),
  })

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
