import { Group, Button, Checkbox, Text, Box, Image, TextInput } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { LabelWithAsterisk } from 'components/label/labelWithAsterisk'
import { useNavigate } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { validatePassword } from 'utils/password'
import PasswordIcon from 'assets/icons/lock.svg?react'
import MailIcon from 'assets/icons/envelope.svg?react'
import Plan from 'assets/img/plan.png'
import Logo from 'assets/img/ttlogowithnamewhite.svg?react'
import { IconUsers } from '@tabler/icons-react'

interface FormValues {
  email: string
  password: string
  confirmPassword: string
  given_name: string
  family_name: string
  organization: string
  hasAcceptedTermsAndConditions: boolean
}

const useRegisterStyles = createStyles(() => ({
  terms: {
    display: 'flex',
  },
}))

export const Register = () => {
  const { signUp } = useAuth()
  const { classes } = useRegisterStyles()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      given_name: '',
      family_name: '',
      organization: '',
      hasAcceptedTermsAndConditions: false,
    },
    validate: {
      given_name: (value) => (value.length > 0 ? null : 'First name is required'),
      family_name: (value) => (value.length > 0 ? null : 'Last name is required'),
      organization: (value) => (value.length > 0 ? null : 'Organization is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) =>
        validatePassword(value) ? null : 'The password must contain one uppercase, one lowercase, a number and at least 8 characters',
      // eslint-disable-next-line quotes
      confirmPassword: (value, values) => (value === values?.password ? null : "Password doesn't match"),
      hasAcceptedTermsAndConditions: (value) => (value ? null : 'You must accept terms and conditions'),
    },
    validateInputOnChange: true,
  })

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    try {
      await signUp(values.email, values.password, {
        email: values.email,
        family_name: values.family_name,
        given_name: values.given_name,
        organization: values.organization,
      })

      // if (import.meta.env.VITE_ENV === 'DEV' || import.meta.env.VITE_ENV === 'PROD') {
      //   await createPayment(values.email)
      // }
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'An error occurred, please input a valid email',
        color: 'red',
        sx: {
          padding: 10,
          paddingLeft: 20,
        },
      })
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  return (
    <Box display="flex" w="100%">
      <Box
        w={416}
        bg="black"
        p={30}
        mx="auto"
        sx={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', color: 'white', overflow: 'hidden' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '200px',
            left: '-70px',
            right: 0,
            bottom: 0,
            height: '100%',
            minWidth: '900px',
            maxWidth: '900px',
          }}
        >
          <Image src={Plan} alt="plan" height="100%" width="100%" />
        </Box>
        <Logo width={100} height={70} />
        <Box w="100%" pt={150} display="flex" sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', flexFlow: 'column' }}>
          <Text sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>Already have </Text>
          <Text mb={20} sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>
            an account?
          </Text>
          <Text mb={211} sx={{ fontSize: '20px', lineHeight: '24px' }}>
            Welcome back!
          </Text>
          <Button
            variant="filled"
            onClick={() => navigate('/session/login')}
            radius="xxs"
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              color: 'white',
              fontWeight: 400,
              borderRadius: '4px',
              width: '216px',
              height: '50px',
              borderColor: 'white',
            }}
          >
            Sign in here
          </Button>
        </Box>
      </Box>
      <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexFlow: 'column', zIndex: 100 }}>
        <Box mb={50} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text size="xl">{t('Authentication.CreateAnAccount')}</Text>
        </Box>

        <Box maw={364} display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
          <form onSubmit={form.onSubmit(onSubmit)}>
            <Box display="flex" mb={10} w="100%">
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                mr={5}
                withAsterisk
                type="text"
                label={t('User.FirstName')}
                {...form.getInputProps('given_name')}
              />
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                withAsterisk
                type="text"
                label={t('User.LastName')}
                {...form.getInputProps('family_name')}
              />
            </Box>

            <Group mb={10}>
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                leftSection={<MailIcon width={20} height={20} />}
                withAsterisk
                label={t('User.Email')}
                {...form.getInputProps('email')}
              />
            </Group>

            <Group mb={10}>
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                leftSection={<IconUsers width={20} height={20} />}
                withAsterisk
                label={t('User.OrganizationName')}
                {...form.getInputProps('organization')}
              />
            </Group>

            <Group mb={10}>
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                leftSection={<PasswordIcon width={20} height={20} />}
                withAsterisk
                type="password"
                label={t('User.Password')}
                {...form.getInputProps('password')}
              />
            </Group>

            <Group mb={10}>
              <TextInput
                sx={{ flex: 1 }}
                styles={{
                  input: {
                    height: 40,
                  },
                }}
                leftSection={<PasswordIcon width={20} height={20} />}
                withAsterisk
                type="password"
                label={t('User.ConfirmPassword')}
                {...form.getInputProps('confirmPassword')}
              />
            </Group>

            <Group mt={20}>
              <Checkbox
                radius="4px"
                sx={{ flexGrow: 1 }}
                label={
                  <LabelWithAsterisk className={classes.terms}>
                    <Text size="sm">{t('Authorization.AcceptTerms')}</Text>
                    <a target="_self" href="https://google.com">
                      <Text
                        ml={5}
                        size="sm"
                        td="underline"
                        sx={{
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        {t('Authorization.TermsAndConditions')}
                      </Text>
                    </a>
                  </LabelWithAsterisk>
                }
                {...form.getInputProps('hasAcceptedTermsAndConditions')}
              />
            </Group>

            <Group justify="center" sx={{ marginTop: 30 }}>
              <Button
                disabled={!form.isValid()}
                radius="xxs"
                w={isLoading ? 238 : 208}
                sx={{ height: 50 }}
                type="submit"
                loading={isLoading}
              >
                <Text sx={{ fontSize: '18px', lineHeight: '22px', fontWeight: 400 }}>{t('Authentication.CreateAnAccount')}</Text>
              </Button>
            </Group>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
