import { GlobalFeature } from './feature'
import { PageDto } from './page'
import { Access } from './permission'

const MAX_RECENT_ITEMS = 5

export enum ProjectView {
  MY_PROJECTS = 'MY_PROJECTS',
  SHARED = 'SHARED',
  ORGANIZATION = 'ORGANIZATION',
}

export enum System {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export interface ProjectDto {
  id: string
  name: string
  system: System
  organizationId?: string
  pages?: PageDto[]
  createdAt?: string
  isOwner: boolean
  lastModifiedAt?: string
  isSharedProject?: boolean
  pagesWithTakeoff?: string[]
  numberOfPages?: number
  globalFeatures: GlobalFeature[]
  access: Access
}

export function sortRecentProjects(projects: Project[]): Project[] {
  return projects.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()).reverse()
}

export function getRecentProjects(project: Project[]): Project[] {
  return project
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .reverse()
    .slice(0, MAX_RECENT_ITEMS)
}

export class Project {
  id: string
  name: string
  system: System
  organizationId?: string
  isOwner: boolean
  createdAt: string
  lastModifiedAt: string
  isSharedProject?: boolean
  pagesWithTakeoff?: string[]
  numberOfPages: number
  globalFeatures: GlobalFeature[]
  access: Access

  constructor(projectDto: ProjectDto) {
    this.id = projectDto.id
    this.name = projectDto.name
    this.system = projectDto.system
    this.organizationId = projectDto.organizationId
    this.isOwner = projectDto.isOwner
    this.createdAt = projectDto.createdAt || ''
    this.lastModifiedAt = projectDto.lastModifiedAt || ''
    this.isSharedProject = projectDto.isSharedProject
    this.pagesWithTakeoff = projectDto.pagesWithTakeoff
    this.numberOfPages = projectDto.numberOfPages || 0
    this.globalFeatures = projectDto.globalFeatures
    this.access = projectDto.access
  }

  get numberOfPagesWithTakeoff() {
    return this.pagesWithTakeoff?.length || 0
  }

  hasAccess(feature: GlobalFeature) {
    return this.globalFeatures.includes(feature)
  }

  hasManagerAccess() {
    return this.access === Access.MANAGER || this.access === Access.OWNER
  }

  hasEditorAccess() {
    return this.access === Access.EDITOR || this.access === Access.MANAGER || this.access === Access.OWNER
  }

  hasOnlyEditorAccess() {
    return this.access === Access.EDITOR
  }
}

export interface CreateProject {
  project: {
    name: string
    system: System
  }
  organizationId?: string
}

export interface UpdateProject {
  project: {
    id: string
    name?: string
    system?: System
  }
  organizationId?: string
}

export interface DeleteProject {
  projectId: string
}
