/* eslint-disable @typescript-eslint/naming-convention */
import { Box, SimpleGrid, Text } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { Project, ProjectView } from 'api/dto'
import { useListRecentPages } from 'api/query/page'
import { useListRecentProjects } from 'api/query/project'
import { useGetMeUser } from 'api/query/user'
import { Loading } from 'components/loading/loading'
import { AddFile } from 'components/modals/add-file.modal'
import { AddProject } from 'components/modals/add-project.modal'
import { EditProject } from 'components/modals/edit-project.modal'
import { ShareProjectModal } from 'components/modals/share-project.modal'
import { PageTile } from 'components/tile/page-tile'
import { ProjectTile } from 'components/tile/project-tile'
import { PageTitle } from 'components/title/page-title'
import Welcome from 'components/welcome/welcome'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useDashboardStyles = createStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  recent: {
    display: 'flex',
  },
}))

export function Dashboard() {
  const { classes } = useDashboardStyles()
  const { t } = useTranslation()
  const { data: user, isLoading: isLoadingUser } = useGetMeUser()
  const { isLoading: isLoadingProjects, data: projects, isSuccess: isSuccessProjects } = useListRecentProjects()
  const { isLoading: isLoadingRecentPages, data: recentPages, isSuccess: isSuccessRecentPages } = useListRecentPages()
  const [project, setProject] = useState<Project | undefined>()
  const [addFileModalOpened, setAddFileModalOpened] = useState(false)
  const [shareProjectModalOpened, setShareProjectModalOpened] = useState(false)
  const [editProjectModalOpened, setEditProjectModalOpened] = useState(false)

  const currentOrgId = user?.currentOrgId

  const openEditModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setEditProjectModalOpened(true)
        return project
      })
    },
    [setProject, setEditProjectModalOpened],
  )

  const openShareModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setShareProjectModalOpened(true)
        return project
      })
    },
    [setProject, setShareProjectModalOpened],
  )

  const openAddFileModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setAddFileModalOpened(true)
        return project
      })
    },
    [setProject, setAddFileModalOpened],
  )

  if (isLoadingProjects || isLoadingRecentPages || isLoadingUser) {
    return <Loading height="100%" />
  }

  if (!isSuccessProjects || !isSuccessRecentPages) {
    return <Text>{t('Common.Error')}</Text>
  }

  return (
    <>
      {projects.length === 0 && <Welcome />}
      {projects.length > 0 && (
        <>
          <Box className={classes.title}>
            <PageTitle title={t('Pages.Title.RecentProjects')} />
            <AddProject view={ProjectView.MY_PROJECTS} />
          </Box>
          <Box mt={23}>
            <SimpleGrid
              cols={{
                base: 1,
                xs: 2,
                sm: 3,
                md: 3,
                xl: 4,
              }}
              spacing="sm"
            >
              {projects.map((p, i) => (
                <ProjectTile
                  item={p}
                  index={i}
                  total={projects.length}
                  openShareModal={openShareModal}
                  openAddFileModal={openAddFileModal}
                  openEditModal={openEditModal}
                  viewType={
                    p.organizationId && currentOrgId === p.organizationId
                      ? ProjectView.ORGANIZATION
                      : p.isSharedProject
                        ? ProjectView.SHARED
                        : ProjectView.MY_PROJECTS
                  }
                />
              ))}
            </SimpleGrid>
          </Box>

          <Box className={classes.title} mt={40}>
            <Text size="lg">{t('Pages.Title.RecentPages')}</Text>
          </Box>

          <Box mt={23}>
            <SimpleGrid
              cols={{
                base: 1,
                xs: 2,
                sm: 3,
                md: 3,
                xl: 4,
              }}
              spacing="sm"
            >
              {recentPages.map((page, i) => (
                <Box mr={25}>
                  <PageTile item={page} index={i} total={recentPages.length} showMenu canNavigate />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </>
      )}
      <AddFile projectId={project?.id} opened={addFileModalOpened} toggle={() => setAddFileModalOpened(false)} />
      <ShareProjectModal project={project} open={shareProjectModalOpened} toggle={() => setShareProjectModalOpened(false)} />
      <EditProject project={project} open={editProjectModalOpened} toggle={() => setEditProjectModalOpened(false)} />
    </>
  )
}
