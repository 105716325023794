import { Button, Group, Modal, TextInput, Text, Box, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Role } from 'api/dto/role'
import { User } from 'api/dto'
import { showNotification } from '@mantine/notifications'
import { useUpdateUserRoleInOrganization } from 'api/query/organization'

interface FormProps {
  role: Role
}

export function ChangeRole({ user, opened, toggle }: { user?: User; opened: boolean; toggle: () => void }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { mutateAsync: updateRole } = useUpdateUserRoleInOrganization()
  const { t } = useTranslation()
  const form = useForm<FormProps>({
    initialValues: {
      role: user?.role || Role.USER,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      setIsSubmitting(true)

      if (!user) {
        return
      }

      await updateRole({ userId: user?.id, role: values.role })
      showNotification({
        title: t('User.ChangedRole'),
        message: t('User.ChangedRoleSuccessfully'),
        color: 'green',
      })
      toggle()
      setIsSubmitting(false)
      form.reset()
    },
    [form, user, toggle, updateRole, t],
  )

  useEffect(() => {
    if (user) {
      form.setFieldValue('role', user.role || Role.USER)
    }

    return () => {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Organization.ChangeRole')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Box mb={20} display="flex">
          <Select
            sx={{ flex: 1 }}
            label={t('User.Role')}
            withAsterisk
            {...form.getInputProps('role')}
            onChange={(e) => form.setFieldValue('role', e as Role)}
            data={[
              {
                value: Role.USER,
                label: t('User.UserRole.User'),
              },
              {
                value: Role.ADMIN,
                label: t('User.UserRole.Admin'),
              },
            ]}
          />
        </Box>

        <Group justify="right" mt="md">
          <Button radius="xxs" size="sm" loading={isSubmitting} disabled={isSubmitting} type="submit">
            {t('Common.Update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
