export const PopoverTypes = {
  ScaleX: 1,
  ScaleY: 2,
  None: -1,
} as const

export type PopoverTypes = (typeof PopoverTypes)[keyof typeof PopoverTypes]
export const MINIMIZED_WIDTH = 0
export const DEFAULT_LEFT_SIDEBAR_WIDTH = 300
export const DEFAULT_RIGHT_SIDEBAR_WIDTH = 500
