/* eslint-disable @typescript-eslint/naming-convention */
import { IconUpload, IconFile3d, IconX } from '@tabler/icons-react'
import { Dropzone, DropzoneProps, PDF_MIME_TYPE } from '@mantine/dropzone'
import { Box, Button, Collapse, Group, Text } from '@mantine/core'
import { ColorScheme } from 'theme/theme'
import { useState } from 'react'
import { showNotification } from '@mantine/notifications'
interface UploadPdfProps extends Partial<DropzoneProps> {
  withLoading?: boolean
  withCollapse?: boolean
  opened?: boolean
  toggle?: () => void
}

export function UploadPdf({ opened, toggle, withCollapse, ...props }: UploadPdfProps) {
  return withCollapse ? (
    <>
      <Group justify="right" mb={5}>
        <Button size="xs" radius="xxs" variant="subtle" onClick={toggle}>
          {opened ? 'Collapse uploader' : 'Show uploader'}
        </Button>
      </Group>
      <Collapse in={opened || false}>
        <PdfDropZone {...props} />
      </Collapse>
    </>
  ) : (
    <PdfDropZone {...props} />
  )
}

function PdfDropZone(props: UploadPdfProps) {
  const [file, setFile] = useState<File | undefined>()
  return (
    <Dropzone
      maxFiles={1}
      onDrop={(files) => {
        if (files[0].size < 5 * 1024) {
          showNotification({
            title: 'File size is too small',
            message: 'Please select a file larger than 5kb',
            color: 'red',
          })
        } else {
          if (!props.withLoading) {
            setFile(files[0])
          }
          props.onDrop?.(files)
        }
      }}
      onReject={(files) => {
        files[0].errors.forEach((e) =>
          showNotification({
            title: 'An error occurred',
            message: e.message,
            color: 'red',
          }),
        )
      }}
      maxSize={250 * 1024 ** 2}
      accept={PDF_MIME_TYPE}
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0,
        backgroundColor: theme.colors[ColorScheme.customGray][4],

        '&[data-accept]': {
          color: theme.white,
          backgroundColor: theme.colors.blue[6],
        },

        '&[data-reject]': {
          color: theme.white,
          backgroundColor: theme.colors.red[6],
        },
      })}
    >
      <Group justify="center" style={{ minHeight: '212px', pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <IconUpload size="3.2rem" />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size="3.2rem" />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconFile3d size="3.2rem" />
        </Dropzone.Idle>

        <Box>
          {file ? (
            <>
              <Text size="md" inline>
                File to be uploaded
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                {file?.name}
              </Text>
            </>
          ) : (
            <>
              <Text size="md" inline>
                Drag pdf here or click to select file
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                File should not exceed 250mb
              </Text>
            </>
          )}
        </Box>
      </Group>
    </Dropzone>
  )
}
