import { Box, Tooltip } from '@mantine/core'
import { useMemo } from 'react'
import { ColorScheme } from 'theme/theme'
import { createStyles } from '@mantine/emotion'

interface ProjectProgressBarProps {
  filledPages: number
  totalPages: number
}

const useProjectProgressBar = createStyles((theme) => ({
  root: {
    width: '100%',
    height: 4,
    position: 'relative',
    borderRadius: 4,
  },
  bar: {
    zIndex: 1,
    borderRadius: 4,
    backgroundColor: theme.colors[ColorScheme.customGray][4],
    height: '100%',
    width: '100%',
  },
  filled: {
    zIndex: 2,
    top: 0,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: theme.colors[ColorScheme.customGray][3],
    height: '100%',
  },
}))

export function ProjectProgressBar({ filledPages, totalPages }: ProjectProgressBarProps) {
  const { classes } = useProjectProgressBar()
  const percentage = useMemo(() => filledPages / totalPages, [filledPages, totalPages])
  const filledPagesPercentage = useMemo(() => percentage * 100, [percentage])

  return (
    <Tooltip label={`${filledPages}/${totalPages} pages with takeoff`} withArrow>
      <Box className={classes.root}>
        <Box className={classes.bar} />
        <Box className={classes.filled} style={{ width: `${filledPagesPercentage}%` }} />
      </Box>
    </Tooltip>
  )
}
