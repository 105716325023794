import { Button, Group, Modal, NumberInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { globalStore, takeoffStore } from 'store/store'
import { ModalTypes } from './types'
import { useStore } from 'zustand'

interface ZoomModalProps {
  opened: boolean
}

interface FormProps {
  zoom: number
}

export function ZoomModal({ opened }: ZoomModalProps) {
  const { t } = useTranslation()
  const { toggleModal } = useStore(globalStore, (state) => ({ toggleModal: state.toggleModal }))
  const close = useCallback(() => toggleModal(ModalTypes.None), [toggleModal])
  const form = useForm<FormProps>({
    initialValues: {
      zoom: Number(localStorage.getItem('page-zoom')) || 1.2,
    },
  })

  const formSubmit = useCallback(
    (values: FormProps) => {
      localStorage.setItem('page-zoom', values.zoom.toString())
      close()
    },
    [close],
  )

  return (
    <Modal opened={opened} size="lg" radius="xxs" onClose={close} title={t('Page.ChangeZoom')}>
      <form onSubmit={form.onSubmit(formSubmit)}>
        <NumberInput
          mt={16}
          label={t('Page.Zoom')}
          decimalScale={2}
          step={0.02}
          min={1}
          max={1.7}
          {...form.getInputProps('zoom')}
          error={undefined}
        />

        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" type="submit">
            {t('Common.Apply')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
