import { Box, NumberInput, Text, TextInput } from '@mantine/core'
import { ShapeTypes } from 'canvas/types'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ManualRepresentation } from 'utils/measure'

export function MeasureField({
  name,
  value,
  setValue,
}: {
  name: string
  type: ShapeTypes
  value?: ManualRepresentation
  setValue: Dispatch<SetStateAction<ManualRepresentation>>
}) {
  const { t } = useTranslation()

  return (
    <>
      <Text fz={18} fw={500} mt={16}>
        {name}
      </Text>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <NumberInput
          autoFocus
          label={t('Common.Feet')}
          value={value?.first}
          defaultValue={0}
          onChange={(e) => setValue((old) => ({ ...old, first: e ? Number(e) : undefined }))}
        />
        <>
          <NumberInput
            ml={20}
            value={value?.second}
            label={t('Common.Inches')}
            defaultValue={0}
            onChange={(e) => setValue((old) => ({ ...old, second: e ? Number(e) : undefined }))}
          />

          <Box ml={20}>
            <NumberInput
              value={value?.fraction?.num}
              label={t('Common.Fraction')}
              defaultValue={0}
              onChange={(e) =>
                setValue((old) => ({
                  ...old,
                  fraction: { den: old.fraction?.den || 1, num: e ? Number(e) : undefined },
                }))
              }
            />
            <Box mt={2} mb={2} sx={{ border: '1px solid #000' }} />
            <NumberInput
              value={value?.fraction?.den}
              defaultValue={0}
              onChange={(e) =>
                setValue((old) => ({
                  ...old,
                  fraction: { den: e ? Number(e) : undefined, num: old.fraction?.num || 1 },
                }))
              }
            />
          </Box>
        </>
      </Box>
    </>
  )
}
