/* eslint-disable @typescript-eslint/naming-convention */
import { ColorScheme } from 'theme/theme'
import { createStyles } from '@mantine/emotion'

export const useScaleToolbarStyles = createStyles((theme) => ({
  box: {
    padding: '5px 15px',
    flexFlow: 'row',
    height: '62px',
    marginTop: 10,
    backgroundColor: theme.colors[ColorScheme.customGray][2],
    color: theme.white,
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;',
    position: 'absolute',
    width: '500px',
    left: 'calc(50% - 13.125rem)',
    bottom: 19,
    zIndex: 10,
    borderRadius: theme.radius.sm,
  },
  button: {
    backgroundColor: theme.white,
    width: '142px',
    borderRadius: '4px',

    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    color: theme.colors.dark,
    fontSize: theme.fontSizes.sm,
    height: '32px',

    ':disabled': {
      backgroundColor: theme.colors[ColorScheme.customGray][3],
      color: theme.colors[ColorScheme.customGray][0],
    },
  },
  popover: {
    width: '432px',
    height: '185px',

    backgroundColor: theme.colors[ColorScheme.customGray][2],
    borderColor: theme.colors[ColorScheme.customGray][2],
  },
}))
