/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Button, Group, Loader, Modal, Select, Text, TextInput } from '@mantine/core'
import { ProjectDto } from 'api/dto'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useListPermission, useCreatePermission } from 'api/query/permission'
import { Access } from 'api/dto/permission'
import capitalize from 'lodash/capitalize'
import { UserPermission } from 'components/permission/user-permission'
import { useGetMeUser } from 'api/query/user'

interface ShareProjectModalProps {
  open: boolean
  toggle: () => void
  project?: ProjectDto
}

const emailRegex = /^\S+@\S+$/

export function ShareProjectModal({ open, toggle, project }: ShareProjectModalProps) {
  const { t } = useTranslation()
  const { data: user, isLoading: isLoadingUser } = useGetMeUser()
  const { data: users, isLoading: isLoadingSharedWith } = useListPermission(project?.id)
  const { mutateAsync: share } = useCreatePermission()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [access, setAccess] = useState(Access.VIEWER)
  const [emailError, setEmailError] = useState<string | undefined>()

  useEffect(() => {
    if (!user || isLoadingUser) {
      return
    }

    if (!email || !emailRegex.test(email)) {
      return
    }

    if (user.email === email) {
      setEmailError('You cannot share project with yourself')
    } else {
      setEmailError(undefined)
    }
  }, [user, email, isLoadingUser])

  return (
    <Modal
      opened={open}
      size="lg"
      radius="xxs"
      padding={25}
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Project.ShareProject').replace('{0}', `"${project?.name}"` || '')}
        </Text>
      }
    >
      <Box mih={300}>
        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <TextInput sx={{ flex: 1 }} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" error={emailError} />
          <Select
            ml={10}
            w={120}
            placeholder="Access"
            data={Object.values(Access).map((a) => ({ label: capitalize(a), value: a }))}
            value={access}
            onChange={(e) => e && setAccess(e as Access)}
            comboboxProps={{
              position: 'bottom',
              withinPortal: true,
            }}
          />
          <Button
            ml={10}
            radius="xxs"
            size="sm"
            color="black"
            loading={loading}
            disabled={!email || !emailRegex.test(email) || emailError != null || !project || loading}
            onClick={async () => {
              setLoading(true)
              if (!email || !project) {
                setLoading(false)
                return
              }

              await share({ projectId: project.id, email, access })

              setLoading(false)
              setEmail('')
              setAccess(Access.VIEWER)
            }}
          >
            <Text size="sm" fw={400}>
              {t('Project.Share')}
            </Text>
          </Button>
        </Box>

        {isLoadingSharedWith && (
          <Box mt={30} display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Loader />
          </Box>
        )}

        {!isLoadingSharedWith && (!users || users.length === 0) && (
          <Box mt={30} display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
            <Text size="md" fw={500}>
              {t('Project.NoSharedWith')}
            </Text>
          </Box>
        )}

        {!isLoadingSharedWith && users && users.length > 0 && (
          <Box mt={30}>
            <Text size="sm" fw={700} mb={20}>
              {users.length} {t('Project.SharedWith')}
            </Text>

            {users.map((user) => (
              <UserPermission key={user.id} userPermission={user} />
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  )
}
