import { Button, Group, Modal, Text, Box, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Role } from 'api/dto/role'
import { showNotification } from '@mantine/notifications'
import { useGetOrganizations } from 'api/query/organization'
import { useCreateUser } from 'api/query/user'

interface FormProps {
  firstName: string
  lastName: string
  email: string
  orgId?: string
  role?: Role
}

export function AdminCreateUser({ toggle, opened }: { toggle: () => void; opened: boolean }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const { data: organizations } = useGetOrganizations()
  const { mutateAsync: createUser } = useCreateUser()
  const form = useForm<FormProps>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: Role.USER,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      setIsSubmitting(true)
      await createUser({ ...values })
      showNotification({
        title: t('User.Added'),
        message: t('User.AddedSuccessfully'),
        color: 'green',
      })
      toggle()
      setIsSubmitting(false)
      form.reset()
    },
    [createUser, t, toggle, form],
  )

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('User.CreateUser')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Box display="flex" mt={10} mb={10} sx={{ flexFlow: 'row', marginBottom: '30px' }}>
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            label={t('User.FirstName')}
            placeholder={t('User.FirstName') || ''}
            type="text"
            {...form.getInputProps('firstName')}
          />

          <TextInput
            withAsterisk
            sx={{ marginLeft: '10px', flex: 1 }}
            label={t('User.LastName')}
            placeholder={t('User.LastName') || ''}
            type="text"
            {...form.getInputProps('lastName')}
            error={undefined}
          />
        </Box>
        <Box mb={10} display="flex">
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            label={t('User.Email')}
            placeholder={t('User.Email') || ''}
            type="text"
            {...form.getInputProps('email')}
            error={undefined}
          />
        </Box>
        <Box display="flex" mb={10}>
          <Select
            sx={{ flex: 1 }}
            label={t('User.Organization')}
            value={form.values.orgId || ''}
            onChange={(e) => {
              form.setFieldValue('orgId', e as string)
            }}
            data={organizations?.map((organization) => ({ value: organization.id, label: organization.name }))}
          />
        </Box>
        {form.values.orgId && (
          <Box display="flex" mb={10}>
            <Select
              sx={{ flex: 1 }}
              label={t('User.Role')}
              withAsterisk
              value={form.values.role || ''}
              onChange={(e) => {
                form.setFieldValue('role', e as Role)
              }}
              data={[
                { value: Role.USER, label: t('User.UserRole.User') },
                { value: Role.ADMIN, label: t('User.UserRole.Admin') },
              ]}
            />
          </Box>
        )}

        <Group justify="right" mt="md">
          <Button radius="xxs" size="sm" loading={isSubmitting} disabled={isSubmitting} type="submit">
            {t('User.CreateUser')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
