/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/require-await */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDrawing } from 'hooks/useDrawing'
import { ShapeTypes } from 'canvas/types'
import { ActionIcon, Box, Button, Text, TextInput } from '@mantine/core'
import { getShapeToolbarIcon } from 'utils/icon'
import { getGroupType } from 'canvas/shape/types/utils'
import { useTranslation } from 'react-i18next'
import { ColorScheme } from 'theme/theme'
import { PageDto } from 'api/dto'
import { PageTile } from 'components/tile/page-tile'
import StopIcon from 'assets/icons/stop.svg?react'
import ListIcon from 'assets/icons/list.svg?react'
import TrashIcon from 'assets/icons/trash.svg?react'
import ThumbnailIcon from 'assets/icons/thumbnail.svg?react'
import { TableMode } from 'components/table/mode'
import { useParams } from 'react-router-dom'
import { useScrollIntoView } from '@mantine/hooks'
import { DEFAULT_LEFT_SIDEBAR_WIDTH, MINIMIZED_WIDTH } from './types'
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand } from '@tabler/icons-react'
import { useToolbarStyles } from './toolbar.styles'
import { useStore } from 'zustand'
import { useListPages } from 'api/query/page'
import { useGetProject } from 'api/query/project'
import dayjs from 'dayjs'
import { takeoffStore } from 'store/store'
import { ProjectService } from 'api/services/project-service'
import { showNotification } from '@mantine/notifications'
import { useGetLayer } from 'api/query/layer'
import { TakeoffFeature } from 'api/dto/feature'

export const Toolbar = () => {
  const { classes } = useToolbarStyles()
  const { t } = useTranslation()
  const { projectId, pageId, layerId } = useParams()
  const { data: layer } = useGetLayer(projectId!, pageId!, layerId!)

  const { selectedGroup, renderer, selectedIds, deleteSelected } = useStore(takeoffStore, (state) => ({
    selectedGroup: state.selectedGroup,
    selectedIds: state.selectedIds,
    renderer: state.renderer,
    deleteSelected: state.deleteSelected,
  }))
  const { isDrawing, onStart: onStartDrawing, onStop } = useDrawing()
  const [sidebarWidth, setSidebarWidth] = useState(MINIMIZED_WIDTH)
  const isMinimized = useMemo(() => sidebarWidth === MINIMIZED_WIDTH, [sidebarWidth])
  const type = useMemo(() => getGroupType(selectedGroup?.type), [selectedGroup])
  const canEdit = layer?.hasAccess(TakeoffFeature.TOOLBAR)

  return (
    <Box className={classes.container}>
      <Box className={classes.toolbar}>
        <Box display="flex" sx={{ flexFlow: 'column', alignItems: 'center', flex: 1 }}>
          <ActionIcon
            radius="xs"
            className={classes.icon}
            title={t('Takeoff.Linear')}
            p={3}
            mb={20}
            disabled={!canEdit || (type >= 0 && type !== ShapeTypes.Line)}
            onClick={() => onStartDrawing(ShapeTypes.Line)}
          >
            {getShapeToolbarIcon(ShapeTypes.Line)}
          </ActionIcon>
          <ActionIcon
            radius="xs"
            className={classes.icon}
            mb={20}
            p={3}
            title={t('Takeoff.Area')}
            disabled={!canEdit || (type >= 0 && type !== ShapeTypes.Area)}
            onClick={() => onStartDrawing(ShapeTypes.Area)}
          >
            {getShapeToolbarIcon(ShapeTypes.Area)}
          </ActionIcon>

          <ActionIcon
            radius="xs"
            title={t('Takeoff.Count')}
            className={classes.icon}
            p={3}
            mb={20}
            disabled={!canEdit || (type >= 0 && type !== ShapeTypes.Count)}
            sx={{ mr: 2, p: 0 }}
            onClick={() => onStartDrawing(ShapeTypes.Count)}
          >
            {getShapeToolbarIcon(ShapeTypes.Count)}
          </ActionIcon>

          <ActionIcon
            radius="xs"
            className={classes.icon}
            title={t('Takeoff.Measure')}
            p={3}
            mb={20}
            disabled={!canEdit || (type >= 0 && type !== ShapeTypes.Measure)}
            sx={{ mr: 2, p: 0 }}
            onClick={() => onStartDrawing(ShapeTypes.Measure)}
          >
            {getShapeToolbarIcon(ShapeTypes.Measure)}
          </ActionIcon>

          <ActionIcon
            radius="xs"
            mb={20}
            className={classes.stop}
            title={t('Takeoff.Stop')}
            disabled={!isDrawing}
            onClick={() => {
              onStop()
            }}
          >
            <StopIcon width="30px" height="30px" />
          </ActionIcon>

          <ActionIcon
            className={classes.icon}
            p={5}
            title={t('Takeoff.Delete')}
            disabled={!canEdit || isDrawing || selectedIds == null}
            onClick={() => deleteSelected()}
          >
            <TrashIcon width="30px" height="30px" />
          </ActionIcon>
        </Box>
        <Box display="flex" sx={{ flexFlow: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <ActionIcon
            radius="xs"
            title={t('Takeoff.Minimize')}
            onClick={() => {
              setSidebarWidth((prev) => (prev === MINIMIZED_WIDTH ? DEFAULT_LEFT_SIDEBAR_WIDTH : MINIMIZED_WIDTH))
              renderer?.fitStageIntoParentContainer()
              setTimeout(() => renderer?.fitStageIntoParentContainer(), 200)
            }}
          >
            {isMinimized ? <IconLayoutSidebarLeftExpand /> : <IconLayoutSidebarLeftCollapse />}
          </ActionIcon>
        </Box>
      </Box>
      <PageTiles width={sidebarWidth} isMinimized={isMinimized} />
    </Box>
  )
}

interface PageTilesProps {
  width: number
  isMinimized: boolean
}

function PageTiles({ width, isMinimized }: PageTilesProps) {
  const { t } = useTranslation()
  const { classes } = useToolbarStyles()
  const { projectId, pageId, layerId } = useParams()
  const { data: project, isLoading: isLoadingProject } = useGetProject(projectId)
  const { data: pages, isLoading: isLoadingPages, isSuccess } = useListPages(projectId!)
  const { data: layer } = useGetLayer(projectId!, pageId!, layerId!)
  const { scrollableRef, scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({})

  const [selectedMode, setSelectedMode] = useState(TableMode.THUMBNAIL)
  const [search, setSearch] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedExportPages, setSelectedExportPages] = useState<string[]>([])

  const listHeight = selectedExportPages.length ? 'calc(100% - 150px)' : 'calc(100% - 10px)'

  const sortedPages = useMemo(() => pages?.sort((a, b) => a.order - b.order) || [], [pages])

  const onCheck = useCallback((page: PageDto) => {
    setSelectedExportPages((prev) => {
      if (prev.includes(page.id)) {
        return prev.filter((p) => p !== page.id)
      }
      return [...prev, page.id]
    })
  }, [])

  useEffect(() => {
    if (pageId && targetRef && !isMinimized) {
      scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef, scrollIntoView, isMinimized])

  if (isLoadingProject || isLoadingPages) {
    return null
  }

  if (!isSuccess || !project) {
    return null
  }

  return (
    <Box className={classes.navigation} sx={{ position: 'relative', width }}>
      <Box
        mb={20}
        sx={{
          alignSelf: 'flex-start',
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          visibility: isMinimized ? 'hidden' : 'visible',
        }}
      >
        <TextInput
          styles={{
            label: {
              color: '#fff',
            },
            input: {
              outline: '1px solid #fff',
              color: '#fff',

              ':focus': {
                outline: '1px solid #fff',
              },
            },
            control: {
              color: '#fff',
            },
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          variant="outlined"
          radius="xs"
        />

        <Box
          ml={10}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ActionIcon
            variant="transparent"
            radius="xss"
            sx={(theme) => ({
              svg: {
                path: {
                  fill: `${selectedMode === TableMode.THUMBNAIL ? theme.white : theme.colors[ColorScheme.customGray][5]} !important`,
                },
              },
            })}
            onClick={() => setSelectedMode(TableMode.THUMBNAIL)}
          >
            <ThumbnailIcon height={28} width={28} />
          </ActionIcon>
          <ActionIcon
            ml={10}
            variant="transparent"
            radius="xss"
            sx={(theme) => ({
              svg: {
                path: {
                  fill: `${selectedMode === TableMode.LIST ? theme.white : theme.colors[ColorScheme.customGray][5]} !important`,
                },
              },
            })}
            onClick={() => setSelectedMode(TableMode.LIST)}
          >
            <ListIcon height={28} width={28} />
          </ActionIcon>
        </Box>
      </Box>
      <Box h={listHeight} ref={scrollableRef} sx={{ width: isMinimized ? '100%' : 290, overflow: 'auto' }}>
        {sortedPages
          .filter((p) => (search ? p.name.toLowerCase().includes(search.toLowerCase()) : true))
          .map((page, i) => (
            <Box key={i} mt={i > 0 ? 21 : 0}>
              <PageTile
                ref={pageId === page.id ? targetRef : undefined}
                textColor="white"
                key={page.id}
                item={page}
                index={i}
                total={sortedPages.length}
                thumbnails={selectedMode === TableMode.THUMBNAIL}
                canEdit={layer?.hasAccess(TakeoffFeature.TOOLBAR)}
                canNavigate
                showChangeOrderButtons
                checked={selectedExportPages}
                onCheck={onCheck}
              />
            </Box>
          ))}
        {selectedExportPages.length > 0 && (
          <Box
            sx={(theme) => ({
              display: !isMinimized ? 'flex' : 'none',
              position: 'absolute',
              bottom: 0,
              left: -1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '80px',
              zIndex: 10,
              backgroundColor: theme.colors[ColorScheme.customGray][2],
            })}
          >
            <Text size="xs" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][4] })}>
              {selectedExportPages.length} plans selected
            </Text>
            <Box display="flex">
              {/* <Button
                size="xs"
                radius="xxs"
                w={120}
                h={40}
                sx={{
                  backgroundColor: '#000',
                }}
                loading={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  // TODO
                  // const res = await ProjectService.generateProjectReport(
                  //   project?.id || '',
                  //   project.pages.filter((p) => selectedExportPages.includes(p.id)).map((p) => p.id),
                  // )
                  // const pdf = res.data?.exportProject
                  const pdf: string = ''
                  if (!pdf) {
                    // toast error
                    setIsLoading(false)
                    return
                  }

                  const linkSource = `data:application/pdf;base64,${pdf}`
                  const downloadLink = document.createElement('a')
                  const projectName = project?.name || 'NA'
                  const fileName = `${projectName}-${dayjs().format('YYYY-MM-DD')}.pdf`
                  downloadLink.href = linkSource
                  downloadLink.download = fileName
                  downloadLink.click()
                  setIsLoading(false)
                  close()
                }}
              >
                <Text>{t('Project.GenerateReport')}</Text>
              </Button> */}

              <Button
                size="xs"
                radius="xxs"
                w={120}
                h={40}
                sx={{
                  backgroundColor: '#000',
                }}
                loading={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  const res = await ProjectService.generateProjectReport(project?.id || '', selectedExportPages, 'csv')
                  const csv = res.data

                  if (!csv) {
                    showNotification({
                      title: t('Project.CSVExportFailed'),
                      message: t('Project.CSVExportFailedMessage'),
                      color: 'red',
                    })

                    setIsLoading(false)
                    return
                  }

                  const linkSource = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
                  const downloadLink = document.createElement('a')
                  const projectName = project?.name || 'NA'
                  const fileName = `${projectName}-${dayjs().format('YYYY-MM-DD')}.csv`
                  downloadLink.href = linkSource
                  downloadLink.download = fileName
                  downloadLink.click()
                  setIsLoading(false)
                  close()
                }}
              >
                {t('Project.GenerateReportCSV')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
