export enum Actions {
  UNKNOWN = 'unknown',
  CONNECT_PAGE = 'connect-page',
  ON_PAGE_CONNECT = 'on-page-connect',
  ON_PAGE_UPDATED = 'on-page-updated',
  ON_LAYER_UPDATED = 'on-layer-updated',
  ON_GROUP_UPDATED = 'on-group-updated',
  ON_GROUP_DELETED = 'on-group-deleted',
}

export class WebSocketMessage {
  action: Actions
  message: any

  constructor(messageEvent: MessageEvent<any> | null) {
    if (!messageEvent) {
      this.action = Actions.UNKNOWN
      this.message = {}
      return
    }

    const { action, message } = JSON.parse(messageEvent.data)
    this.action = action
    this.message = message ? JSON.parse(message) : {}
  }

  get isOnPageConnect() {
    return this.action === Actions.ON_PAGE_CONNECT
  }

  get isOnGroupUpdated() {
    return this.action === Actions.ON_GROUP_UPDATED
  }

  get isPageUpdated() {
    return this.action === Actions.ON_PAGE_UPDATED
  }

  get isLayerUpdated() {
    return this.action === Actions.ON_LAYER_UPDATED
  }

  get isGroupDeleted() {
    return this.action === Actions.ON_GROUP_DELETED
  }
}
