/* eslint-disable @typescript-eslint/unbound-method */
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { GroupCanvas } from '../group'
import { LayerManager } from '../layers/layer-manager'
import { IPoint, ShapeTypes, PointerButton, IGroupCanvas, IShape } from '../types'
import { shapeBuilder } from '../shape/shape-builder'
import { nanoid } from 'nanoid'
import { Group } from 'api/dto/group'
import { PopoverTypes } from 'components/toolbar/types'
import { globalStore, takeoffStore } from 'store/store'
import { TakeoffFactory } from 'factory/takeoff-factory'
import { Axis, LayerScale } from 'utils/scale/scale'
import { CombinedShapeTypes, isCount, isMeasure, isScale, isShapeWithAnchors } from 'canvas/shape/types/utils'
import { globalQueryClient } from 'api/client'
import { Page } from 'api/dto'
import { Layer } from 'api/dto/layer'
import { LayerService } from 'api/services/layer-service'
import { GroupService } from 'api/services/group-service'
import { getShapeStroke } from 'utils/shape-stroke'

Konva.showWarnings = false
export interface RendererProps {
  layerManager: LayerManager
  transformer: Konva.Transformer
}

interface KonvaPoint {
  x: number
  y: number
}

export class Renderer {
  id: string = nanoid()
  #currentShape?: CombinedShapeTypes
  #currentGroup?: GroupCanvas

  #isZooming = false

  #lastCenter: KonvaPoint | undefined
  #lastDist: number | undefined

  get config() {
    return takeoffStore.getState().config
  }

  isCurrentLayer(layerId: string) {
    return this.config.layerId === layerId
  }

  get groups() {
    return takeoffStore.getState().groups
  }

  get isDrawing() {
    return takeoffStore.getState().isDrawing
  }

  get drawingType() {
    return takeoffStore.getState().drawingType
  }

  get store() {
    return takeoffStore.getState()
  }

  get selectedGroup() {
    return takeoffStore.getState().selectedGroup
  }

  get infos() {
    return {
      numberOfNodes: this.layerManager.shapeLayer.layer.children?.length || 0,
    }
  }

  constructor(
    readonly page: Page,
    readonly layer: Layer,
    readonly stage: Konva.Stage,
    readonly layerManager: LayerManager,
  ) {
    takeoffStore.getState().setScale(layer.scale)

    this.stage.on('click', this.#onStageClick)
    this.stage.on('tap', this.#onStageClick)
    this.stage.on('mousemove', this.#onStageHover)
    this.stage.on('touchmove', this.#onTouchMove)
    this.stage.on('touchend', this.#onTouchEnd)
    this.stage.on('wheel', this.#onZoom)
    this.stage.on('mousedown', this.#onMouseDown)

    this.loadPlan()
  }

  #debugShape: Konva.Text | undefined = undefined
  #debugText = ''

  #addDebugText(text: string) {
    if (this.#debugShape) {
      this.#debugText += text += '\n'
      this.#debugShape?.text(this.#debugText)

      this.layerManager.shapeLayer.layer.add(this.#debugShape)
    }
  }

  public loadPlan() {
    const img = new Image()

    img.onload = () => {
      const newPlan = new Konva.Image({
        id: 'plan',
        image: img,
      })

      newPlan.listening(false)
      newPlan.perfectDrawEnabled(false)

      this.layerManager.planLayer.layer.add(newPlan)

      const box = newPlan.getClientRect()
      // how much do we need to zoom for that
      const scale = Math.min(this.stage.width() / box.width, this.stage.height() / box.height)

      // calculate the center position
      const centerX = this.stage.width() / 2
      const centerY = this.stage.height() / 2

      // calculate the new position and scale
      const newX = centerX - (box.x + box.width / 2) * scale
      const newY = centerY - (box.y + box.height / 2) * scale

      // let's do it
      this.stage.to({
        x: newX,
        y: newY,
        scaleX: scale,
        scaleY: scale,
      })

      this.layerManager.planLayer.layer.draw()
    }

    img.crossOrigin = 'Anonymous'
    img.src = this.page.sourceImage || ''
  }

  public findShapeById(id: string): Konva.Node | undefined {
    return this.stage.findOne(`#${id}`)
  }

  /**
   * Add a new group to the canvas
   * @param shapeType
   * @param relatedGroup
   */
  public addNewGroup(shapeType: ShapeTypes, relatedGroup?: IGroupCanvas) {
    const length = takeoffStore.getState().groups.length

    let currentGroup: GroupCanvas | undefined

    if (!currentGroup) {
      if (relatedGroup) {
        const group = new Group({
          ...relatedGroup,
          id: undefined,
          pageId: this.config.pageId!,
          layerId: this.config.layerId!,
          relatedGroupId: relatedGroup.id,
          type: shapeType.toString(),
          order: length + 1,
        })
        currentGroup = TakeoffFactory.createCanvasGroup(group, this.layerManager.drawLayer.layer)
      } else {
        const group = new Group({
          pageId: this.config.pageId!,
          layerId: this.config.layerId!,
          type: shapeType.toString(),
          order: length + 1,
        })
        currentGroup = TakeoffFactory.createCanvasGroup(group, this.layerManager.drawLayer.layer)
      }
    }

    if (!takeoffStore.getState().findGroupById(currentGroup.id)) {
      takeoffStore.getState().addGroup(currentGroup, true)
    }

    this.#currentGroup = currentGroup
  }

  public assignSelectedGroup(group: GroupCanvas) {
    this.#currentGroup = group
  }

  /**
   * Add a new shape to the canvas
   * @returns
   */
  public addNewShape() {
    if (this.isDrawing) {
      return
    }

    if (!this.#currentGroup) {
      return
    }

    if (this.#currentGroup.type !== ShapeTypes.Count) {
      const stroke = getShapeStroke(this.#currentGroup.type)
      const newShape = shapeBuilder().group(this.#currentGroup).stroke(stroke).axis(takeoffStore.getState().scaleAxis).build()
      this.#currentShape = newShape
    }

    takeoffStore.getState().setDrawingType(this.#currentGroup.type)
  }

  public renderAndCalculateProject(groups?: Group[]): void {
    const currentGroups: GroupCanvas[] = []

    if (!groups) {
      return
    }

    const groupsToCreate = groups.filter((g) => !this.groups.find((group) => group.id === g.id))
    const groupsInCanvas = this.groups.filter((g) => groups.find((group) => group.id === g.id))

    const groupsToDelete = this.groups.filter((g) => !groups.find((group) => group.id === g.id))

    for (const group of groupsInCanvas) {
      const groupDto = groups.find((g) => g.id === group.id)
      if (groupDto) {
        group.merge(groupDto)
      }
      group.calculate()
      currentGroups.push(group)
    }

    for (const groupToCreate of groupsToCreate) {
      const newGroupCanvas = TakeoffFactory.createCanvasGroup(groupToCreate, this.layerManager.shapeLayer.layer)
      newGroupCanvas.calculate()
      currentGroups.push(newGroupCanvas)
    }

    for (const groupToDelete of groupsToDelete) {
      groupToDelete.removeShapes()
      groupToDelete.instance.destroy()
    }

    takeoffStore.getState().setGroups(currentGroups)
  }

  public addPointToCurrentDrawnType(point: IPoint) {
    if (!this.isDrawing) {
      return
    }

    if (!this.#currentGroup) {
      return
    }

    if (this.#currentShape && this.#currentGroup) {
      if (isShapeWithAnchors(this.#currentShape)) {
        this.#currentShape.addPoint(point)
        return
      }
    }

    if (this.#currentGroup && this.#currentGroup.type === ShapeTypes.Count) {
      shapeBuilder()
        .points([point])
        .group(this.#currentGroup)
        .index(this.#currentGroup.shapes.length + 1)
        .build()
    }
  }

  public moveDrawnShapeToLayer() {
    if (!this.isDrawing) {
      return
    }

    if (!this.#currentGroup) {
      return
    }

    if (this.#currentShape && !this.#currentShape.hasAtLeastTwoPoints() && !isCount(this.#currentShape)) {
      this.#currentGroup.removeShape(this.#currentGroup.shapes.length - 1)

      if (this.#currentGroup.shapes.length === 0) {
        takeoffStore.getState().deleteGroup(this.#currentGroup.id)
      }

      return
    }

    const group = this.#currentGroup
    const shapeLayer = this.layerManager.shapeLayer.layer

    if (this.#currentShape) {
      this.#currentShape.moveTo(shapeLayer)

      if (isShapeWithAnchors(this.#currentShape)) {
        this.#currentShape.endDraw(isMeasure(this.#currentShape) ? this.#currentShape.line : this.#currentShape.instance)
        this.#currentShape.anchors.forEach((anchor) => anchor.moveTo(shapeLayer))
      }
    }

    this.#currentGroup = undefined
    this.#currentShape = undefined

    return group
  }

  // TODO put 100% on css container
  public fitStageIntoParentContainer(): void {
    const container = this.stage.container()

    // now we need to fit stage into parent container
    const containerWidth = container.offsetWidth
    const containerHeight = container.offsetHeight

    this.stage.width(containerWidth)
    this.stage.height(containerHeight)
  }

  #onStageClick: Konva.KonvaEventListener<Konva.Stage, MouseEvent | TouchEvent> = (evt) => {
    evt.cancelBubble = true
    evt.evt.preventDefault()

    //this.#addDebugText('touch click')

    if (this.#isZooming) {
      return
    }

    const { popoverTypeOpened, togglePopover } = globalStore.getState()

    if (popoverTypeOpened !== PopoverTypes.None) {
      togglePopover(PopoverTypes.None)
    }

    if (!this.isDrawing) {
      const { selectedIds, selectedGroup, selectedShape, resetSelectedEntities: reset } = takeoffStore.getState()
      if (selectedIds || selectedGroup || selectedShape) {
        reset()
      }

      return
    }

    if (this.drawingType === ShapeTypes.Scale) {
      this.#onDrawScale(evt)
    } else {
      const pointer = evt.target.getRelativePointerPosition()
      if (!pointer) {
        return
      }

      const point = { ...pointer, id: nanoid() }
      this.addPointToCurrentDrawnType(point)

      if (this.#currentShape && isMeasure(this.#currentShape) && this.#currentShape.points.length === 2) {
        this.#currentShape.moveLabel(this.#currentShape.line, this.#currentShape.label)
        void this.finishDrawing()
      }
    }
  }

  #onStageHover: Konva.KonvaEventListener<Konva.Stage, MouseEvent> = (evt) => {
    evt.cancelBubble = true
    evt.target.preventDefault()
    const pointer = evt.target.getRelativePointerPosition()
    if (!pointer) {
      return
    }

    const point = { ...pointer, id: nanoid() }

    if (!this.isDrawing) {
      return
    }

    if (this.#currentShape && isShapeWithAnchors(this.#currentShape) && this.#currentShape.points.length >= 1) {
      if (isScale(this.#currentShape)) {
        const isXAxis = this.#currentShape.axis === Axis.Horizontal
        const p = isXAxis ? { ...point, y: this.#currentShape.points[0].y } : { ...point, x: this.#currentShape.points[0].x }
        this.#currentShape.followPointer(this.#currentShape.instance, p)
      } else if (!isCount(this.#currentShape)) {
        if (isMeasure(this.#currentShape)) {
          this.#currentShape?.followPointer(this.#currentShape.line, point)
        } else {
          this.#currentShape?.followPointer(this.#currentShape.instance, point)
        }
      }
    }
  }

  #onZoom: Konva.KonvaEventListener<Konva.Stage, MouseEvent> = (event) => {
    event.cancelBubble = true
    event.evt.preventDefault()

    if (!this.stage) {
      return
    }

    const stage = this.stage
    const oldScale = stage.scaleX()
    const { x: pointerX, y: pointerY } = stage.getPointerPosition() || { x: 0, y: 0 }
    const mousePointTo = {
      x: (pointerX - stage.x()) / oldScale,
      y: (pointerY - stage.y()) / oldScale,
    }

    const zoom = Number(localStorage.getItem('page-zoom')) || 1.2

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newScale = (event.evt as Record<string, any>).deltaY < 0 ? oldScale * zoom : oldScale / zoom
    stage.scale({ x: newScale, y: newScale })
    const newPos = {
      x: pointerX - mousePointTo.x * newScale,
      y: pointerY - mousePointTo.y * newScale,
    }
    stage.position(newPos)
    stage.batchDraw()
  }

  #getDistance(p1: KonvaPoint, p2: KonvaPoint) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  }

  #getCenter(p1: KonvaPoint, p2: KonvaPoint) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    }
  }

  #onTouchMove: Konva.KonvaEventListener<Konva.Stage, TouchEvent> = (evt) => {
    evt.cancelBubble = true
    evt.evt.preventDefault()
    this.#addDebugText('touch move')
    const touch1 = evt.evt.touches[0]
    const touch2 = evt.evt.touches[1]

    if (!this.stage) {
      return
    }

    this.#isZooming = true

    if (touch1 && touch2) {
      this.stage.draggable(false)
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (this.stage.isDragging()) {
        this.stage.stopDrag()
      }

      const p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      }
      const p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      }

      if (!this.#lastCenter) {
        this.#lastCenter = this.#getCenter(p1, p2)
        return
      }
      const newCenter = this.#getCenter(p1, p2)

      const dist = this.#getDistance(p1, p2)

      if (!this.#lastDist) {
        this.#lastDist = dist
      }

      // local coordinates of center point
      const pointTo = {
        x: (newCenter.x - this.stage.x()) / this.stage.scaleX(),
        y: (newCenter.y - this.stage.y()) / this.stage.scaleX(),
      }

      const scale = this.stage.scaleX() * (dist / this.#lastDist)

      this.stage.scaleX(scale)
      this.stage.scaleY(scale)

      // calculate new position of the stage
      const dx = newCenter.x - this.#lastCenter.x
      const dy = newCenter.y - this.#lastCenter.y

      const newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      }

      this.stage.position(newPos)

      this.#lastDist = dist
      this.#lastCenter = newCenter
    }
  }

  #onTouchEnd: Konva.KonvaEventListener<Konva.Stage, TouchEvent> = (evt) => {
    this.#addDebugText('touch end')
    evt.cancelBubble = true
    evt.evt.preventDefault()
    this.#lastDist = 0
    this.#lastCenter = undefined
    this.stage.draggable(true)
    this.#isZooming = false
  }

  #onMouseDown: Konva.KonvaEventListener<Konva.Stage, MouseEvent> = (evt) => {
    evt.cancelBubble = true
    evt.evt.preventDefault()
    if (evt.evt.buttons != PointerButton.Middle) {
      return
    }
  }

  #onDrawScale = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const scaleAxis = takeoffStore.getState().scaleAxis

    if (!this.#currentShape || !scaleAxis) {
      return
    }

    const pointer = evt.target.getRelativePointerPosition()

    if (!pointer) {
      return
    }

    const point = { ...pointer, id: nanoid() }

    if (this.#currentShape.points.length === 1) {
      const firstPoint = this.#currentShape.points[0]

      const x = scaleAxis === Axis.Vertical ? firstPoint.x : pointer.x
      const y = scaleAxis === Axis.Horizontal ? firstPoint.y : pointer.y

      const newPoint = { id: point.id, x, y }
      this.addPointToCurrentDrawnType(newPoint)
    } else {
      this.addPointToCurrentDrawnType(point)
    }

    if (this.#currentShape.points.length === 2) {
      const { scale, setScale } = takeoffStore.getState()
      const calculatedScale = scale.calculateManualScale(scaleAxis, this.#currentShape.points[0], this.#currentShape.points[1])

      if (calculatedScale) {
        void LayerService.updateLayer({ id: this.config.layerId, scale: calculatedScale })
        setScale(new LayerScale(calculatedScale))
      }

      takeoffStore.getState().recalculateGroups()

      void this.finishDrawing()
      void globalQueryClient.invalidateQueries({
        queryKey: ['projects', this.config.projectId],
      })
    }
  }

  resetDrawing = () => {
    this.#currentShape = undefined
    this.layerManager.drawLayer.layer.draw()
    takeoffStore.getState().setDrawingType(ShapeTypes.None)
    this.store.resetSelectedEntities()
    const container = this.stage.container()
    if (container) {
      container.style.cursor = 'default'
    }
  }

  finishDrawing = async (withoutSaving?: boolean) => {
    const { recalculateGroup, spreadNewGroupId, deleteGroup } = takeoffStore.getState()

    if (withoutSaving && this.#currentGroup) {
      deleteGroup(this.#currentGroup.id)
      this.#currentGroup = undefined
      this.resetDrawing()
      return
    }

    const group = this.moveDrawnShapeToLayer()

    if (!group) {
      this.resetDrawing()
      return
    }

    if (group.hasShapes()) {
      recalculateGroup(group.id)

      if (group.isSavedGroup) {
        // anchors
        await GroupService.updateGroup(group.id, group.toUpdateDto())
      } else {
        const id = await GroupService.createGroup(group.toCreateDto())
        if (id) {
          spreadNewGroupId(group.id, id)
        }
      }
    } else if (!group.hasShapes()) {
      deleteGroup(group.id)
    }

    this.resetDrawing()
  }

  reloadStage(groups: Group[]) {
    this.loadPlan()

    this.renderAndCalculateProject(groups)
  }

  destroy() {
    this.stage.destroy()
    this.layerManager.removeChildren()
    this.layerManager.removeLayers()
  }
}
