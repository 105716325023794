import { Box, Group } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { IOrganizationDto } from 'api/dto/organization'
import { useGetOrganizations } from 'api/query/organization'
import { Table } from 'components/table/table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function Organizations() {
  const { t } = useTranslation()
  const { data: organizations } = useGetOrganizations()

  const columns: ColumnDef<IOrganizationDto>[] = useMemo(
    () =>
      [
        {
          id: 'name',
          header: t('Organization.Name'),
          accessorKey: 'name',
          width: '300px',
        },
        {
          id: 'description',
          header: t('Organization.Description'),
          accessorKey: 'description',
        },
        {
          id: 'actions',
          header: t('Organization.Actions'),
          // delete
          accessor: () => <Group></Group>,
        },
      ] as ColumnDef<IOrganizationDto>[],
    [t],
  )

  return <Table<IOrganizationDto> columns={columns as never} data={organizations || []} />
}
