import { DragHandle } from './drag-handle'
import { Row } from '@tanstack/react-table'
import { createStyles } from '@mantine/emotion'

const useStaticTableRowStyles = createStyles((theme) => ({
  data: {
    background: theme.white,
  },
  row: {
    boxShadow: 'rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 30%) 0px 10px 10px -5px',
    outline: '#3e1eb3 solid 1px',
  },
}))

export function StaticTableRow<T>({ row }: { row: Row<T> | null }) {
  const { classes } = useStaticTableRowStyles()
  return (
    <tr className={classes.row}>
      {row?.getAllCells().map((cell, i) => {
        if (i === 0) {
          return (
            <td className={classes.data}>
              <DragHandle isDragging />
              <span>{cell.renderValue() as any}</span>
            </td>
          )
        }
        return <td className={classes.data}>{cell.renderValue() as any}</td>
      })}
    </tr>
  )
}
