import { Center, Group, Loader } from '@mantine/core'

export function Loading({ height = '100vh', withBackgroundColor = false }: { height?: string; withBackgroundColor?: boolean }) {
  return (
    <Group
      sx={(theme) => ({
        height,
        backgroundColor: withBackgroundColor ? theme.colors.dark[8] : undefined,
        justifyContent: 'center',
        flex: 1,
      })}
    >
      <Center>
        <Loader size="xl" />
      </Center>
    </Group>
  )
}
