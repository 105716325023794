import { TextInput, Group, Button, Text, Box, Anchor, Image } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Brand } from 'components/brand/brand'
import PasswordIcon from 'assets/icons/lock.svg?react'
import EmailIcon from 'assets/icons/envelope.svg?react'
import Plan from 'assets/img/plan.png'

interface FormValues {
  email: string
  password: string
}

export const Login = () => {
  const { login } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    await login(values.email, values.password)
    setIsLoading(false)
  }

  return (
    <>
      <Box display="flex" sx={{ flex: 1, flexFlow: 'column', padding: '20px 30px', zIndex: 100 }}>
        <Brand />
        <Box mt={80} mb={50} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Text fw={400} size="xl">
            {t('Authentication.PleaseLogin')}
          </Text>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box w="70%" maw={500}>
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Box mb={10}>
                <TextInput
                  size="lg"
                  sx={{ input: { borderRadius: '4px !important', fontSize: '16px !important' } }}
                  leftSection={<EmailIcon width={15} height={15} stroke="#cecece" />}
                  withAsterisk
                  placeholder={t('Authentication.Email') || ''}
                  {...form.getInputProps('email')}
                />
              </Box>

              <Box mb={30}>
                <TextInput
                  mb={5}
                  size="lg"
                  sx={{ input: { borderRadius: '4px !important', fontSize: '16px !important' } }}
                  leftSection={<PasswordIcon width={15} height={15} stroke="#cecece" />}
                  withAsterisk
                  type="password"
                  placeholder={t('Authentication.Password') || ''}
                  {...form.getInputProps('password')}
                />
                <Box mt={10} display="flex" sx={{ justifyContent: 'flex-end' }}>
                  <Anchor size="sm" td="underline" onClick={() => navigate('/session/forgot-password')}>
                    Forgot password?
                  </Anchor>
                </Box>
              </Box>

              <Group justify="center">
                <Button
                  w={234}
                  h={50}
                  radius="xxs"
                  type="submit"
                  loading={isLoading}
                  sx={{
                    fontSize: '18px',
                    lineHeight: '22px',
                    fontWeight: 400,
                  }}
                >
                  {t('Common.Login')}
                </Button>
              </Group>
            </form>
          </Box>
        </Box>
      </Box>
      <Box
        w={416}
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
          backgroundColor: 'black',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          color: 'white',
          paddingTop: '237px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-100px',
            left: '-200px',
            right: 0,
            bottom: 0,
            height: '100%',
            minWidth: '900px',
            maxWidth: '900px',
          }}
        >
          <Image src={Plan} alt="plan" height="100%" width="100%" />
        </Box>
        <Text sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>Don't have </Text>
        <Text mb={20} sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>
          an account yet?
        </Text>
        <Button
          mt={220}
          radius="xxs"
          variant="filled"
          onClick={() => navigate('/session/register')}
          sx={{
            fontSize: '18px',
            lineHeight: '22px',
            color: 'white',
            fontWeight: 400,
            borderRadius: '4px',
            width: '216px',
            height: '70px',
            borderColor: 'white',
            wordWrap: 'break-word',

            span: {
              whiteSpace: 'break-spaces',
              textAlign: 'center',
            },
          }}
        >
          Sign up now to access free takeoff!
        </Button>
      </Box>
    </>
  )
}
