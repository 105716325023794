import Konva from 'konva'
import { LayerManager } from '../layers/layer-manager'
import { BuildFn } from '../types'
import { Renderer } from './renderer'
import { Page } from 'api/dto'
import { Layer } from 'api/dto/layer'

Konva.pixelRatio = 1

interface RendererState {
  readonly container: HTMLDivElement
  readonly stageDraggable: boolean
  readonly page: Page
  readonly layer: Layer
}

export class RendererBuilder<TState extends Partial<RendererState>> {
  constructor(private readonly state: TState) {}

  page(page: Page): RendererBuilder<TState & Pick<RendererState, 'page'>> {
    return new RendererBuilder({
      ...this.state,
      page,
    })
  }

  layer(layer: Layer): RendererBuilder<TState & Pick<RendererState, 'layer'>> {
    return new RendererBuilder({
      ...this.state,
      layer,
    })
  }

  container(name: string): RendererBuilder<TState & Pick<RendererState, 'container'>> {
    const containerDiv: HTMLDivElement | null = document.getElementById(name) as HTMLDivElement | null

    if (containerDiv == null) {
      throw new Error('missing container')
    }

    return new RendererBuilder({
      ...this.state,
      container: containerDiv,
    })
  }

  draggable(stageIsDraggable: boolean): RendererBuilder<TState & Pick<RendererState, 'stageDraggable'>> {
    return new RendererBuilder({
      ...this.state,
      stageDraggable: stageIsDraggable,
    })
  }

  build = (() => {
    const { page, layer, container, stageDraggable } = this.state as RendererState

    const stage = new Konva.Stage({
      id: 'konva-stage',
      container,
      width: container.clientWidth,
      height: container.clientHeight,
      draggable: stageDraggable,
    })

    const layerManager = new LayerManager()

    stage.add(
      layerManager.planLayer.layer,
      layerManager.shapeLayer.layer,
      layerManager.drawLayer.layer,
      layerManager.collaborationLayer.layer,
    )

    return new Renderer(page, layer, stage, layerManager)
  }) as BuildFn<TState, RendererState, Renderer>
}

export const buildRenderer = (page: Page, layer: Layer) => {
  const builder = new RendererBuilder({})

  return builder.page(page).layer(layer).container('container').draggable(true).build()
}
