export function getInitials(sentence: string): string {
  // Split the sentence into words
  const words = sentence.split(' ')

  // Initialize an array to store the initials
  const initials: string[] = []

  // Iterate through the words
  for (const word of words) {
    // Get the first letter of each word and convert it to uppercase
    const initial = word.charAt(0).toUpperCase()

    // Push the initial to the initials array
    initials.push(initial)
  }

  if (initials.length === 1) {
    return words[0].substring(0, 2).toUpperCase()
  }

  // Join the initials into a single string
  const result = initials.join('')

  // If the result has more than 2 letters, return the first 2 letters
  return result.substring(0, 1) + result.substring(result.length - 1, result.length)
}
