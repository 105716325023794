/* eslint-disable @typescript-eslint/naming-convention */
import { Box, UnstyledButton, Text } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { Link, useLocation } from 'react-router-dom'
import { useHover } from '@mantine/hooks'
import { ReactNode, useMemo } from 'react'
import { ColorScheme } from 'theme/theme'

interface SidebarLinkProps {
  name: string
  href: string
  icon?: ReactNode
  toggle: () => void
}

const useSidebarLinkStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing.xs,
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    marginTop: theme.spacing.xs,
    color: theme.white,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
      color: theme.colors[ColorScheme.customGray][1],

      svg: {
        path: {
          fill: theme.colors[ColorScheme.customGray][1],
        },
      },
    },
  },

  box: {
    display: 'flex',
    alignItems: 'center',
  },
  linkText: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.white,
    color: theme.colors[ColorScheme.customGray][1],
    svg: {
      path: {
        fill: `${theme.colors[ColorScheme.customGray][1]} !important`,
      },
    },
  },
}))

export function SidebarLink({ href, name, icon, toggle }: SidebarLinkProps) {
  const location = useLocation()
  const { classes, cx } = useSidebarLinkStyles()

  const isActive = useMemo(() => location.pathname.includes(href), [location.pathname, href])

  const { ref } = useHover()
  return (
    <UnstyledButton
      ref={ref as any}
      className={cx(classes.link, isActive ? classes.active : '')}
      component={Link}
      to={href}
      onClick={() => toggle()}
    >
      <Box className={classes.linkText}>
        <Box ml={10}>{icon}</Box>

        <Text ml={17} size="sm" sx={{ display: 'flex', alignItems: 'center' }}>
          {name}
        </Text>
      </Box>
    </UnstyledButton>
  )
}
