import { useEffect, useState } from 'react'
import { useWebsocket } from './useWebsocket'
import { globalQueryClient } from 'api/client'
import { GroupCanvas } from 'canvas/group'
import { useStore } from 'zustand'
import { takeoffStore } from 'store/store'
import { TakeoffFactory } from 'factory/takeoff-factory'

export const usePageLayerGroupUpdated = () => {
  const [isReceivingMessage, setIsReceivingMessage] = useState(false)
  const { result } = useWebsocket()
  const { findGroupById, addGroup, updateGroup, recalculateGroup } = useStore(takeoffStore, (state) => ({
    findGroupById: state.findGroupById,
    addGroup: state.addGroup,
    updateGroup: state.updateGroup,
    recalculateGroup: state.recalculateGroup,
  }))
  const { renderer } = useStore(takeoffStore, (state) => ({
    renderer: state.renderer,
  }))

  useEffect(() => {
    if (!result) {
      return
    }

    setIsReceivingMessage(true)

    if (result.isOnGroupUpdated && renderer) {
      const group = result.message.group
      const id = group.id

      if (id) {
        let newGroup = false
        let currentGroup: GroupCanvas | undefined = findGroupById(id)
        if (!currentGroup) {
          newGroup = true
          currentGroup = TakeoffFactory.createCanvasGroup(group, renderer.layerManager.shapeLayer.layer)
        }

        if (!newGroup) {
          currentGroup.merge(group)
        } else {
          addGroup(currentGroup, false)
        }

        updateGroup({
          ...group,
          ...currentGroup,
        })
        recalculateGroup(currentGroup.id)
      }

      setIsReceivingMessage(false)
    }
  }, [result, isReceivingMessage, renderer, findGroupById, addGroup, updateGroup, recalculateGroup])
}
