import { Box, Text } from '@mantine/core'
import { useGetProject } from 'api/query/project'
import capitalize from 'lodash/capitalize'
import { useParams } from 'react-router-dom'

export function MyPermission() {
  const { projectId } = useParams()
  const { data: project, isSuccess } = useGetProject(projectId)

  if (!isSuccess) {
    return null
  }

  return (
    <Box
      mr={10}
      p={5}
      bg="custom-gray.4"
      sx={{
        borderRadius: 6,
      }}
    >
      <Text size="10px">Access: {capitalize(project.access)}</Text>
    </Box>
  )
}
