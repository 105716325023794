import { Button, Group, Modal, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'api/dto'
import { showNotification } from '@mantine/notifications'
import { validatePassword } from 'utils/password'

interface FormProps {
  password: string
  confirmPassword: string
}

interface ForceUserPasswordProps {
  opened: boolean
  toggle: () => void
  user?: User
}

export function ForcePassword({ opened, toggle, user }: ForceUserPasswordProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const form = useForm<FormProps>({
    validate: {
      password: (value) =>
        validatePassword(value) ? null : 'The password must contain one uppercase, one lowercase, a number and at least 8 characters',
      // eslint-disable-next-line quotes
      confirmPassword: (value, values) => (value === values?.password ? null : "Password doesn't match"),
    },
    validateInputOnChange: true,
  })

  const formSubmit = useCallback(
    (values: FormProps) => {
      if (!user?.id) {
        return
      }

      setIsSubmitting(true)
      // TODO
      // await AppSync.client
      //   .mutation<unknown, AdminForcePassword>(forcePasswordMutation, { email: user.email, password: values.password })
      //   .toPromise()
      showNotification({
        title: t('User.UserPasswordUpdated'),
        message: t('User.UserPasswordUpdatedSuccessfully'),
        color: 'green',
      })

      toggle()
      setIsSubmitting(false)
      form.reset()
    },
    [toggle, form, t, user],
  )

  useEffect(() => {
    if (!opened) {
      form.reset()
    }
    // we dont want to trigger a setValues every time the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('User.UpdateUserPassword')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Group mb={10}>
          <TextInput sx={{ flex: 1 }} withAsterisk type="password" label={t('User.Password')} {...form.getInputProps('password')} />
        </Group>

        <Group mb={10}>
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            type="password"
            label={t('User.ConfirmPassword')}
            {...form.getInputProps('confirmPassword')}
          />
        </Group>
        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" loading={isSubmitting} disabled={isSubmitting} type="submit">
            {t('Common.Update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
