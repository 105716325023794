import { Button, Group, Modal, Text, Box, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showNotification } from '@mantine/notifications'
import { useCreateLayer } from 'api/query/layer'
import { useParams } from 'react-router-dom'

interface CreateLayerProps {
  opened: boolean
  toggle: () => void
}

interface FormProps {
  name: string
  type: 'electrical' | 'structure' | 'plumbing' | 'hvac' | 'other'
}

export function CreateLayer({ opened, toggle }: CreateLayerProps) {
  const { t } = useTranslation()
  const { projectId, pageId } = useParams()
  const form = useForm<FormProps>({})
  const { mutateAsync: createLayer } = useCreateLayer(projectId!, pageId!)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const formSubmit = useCallback(
    async (values: FormProps) => {
      setIsSubmitting(true)

      await createLayer(values)

      showNotification({
        title: t('Layer.LayerAdded'),
        message: t('Layer.LayerAddedSuccessfully'),
        color: 'green',
      })

      toggle()
      setIsSubmitting(false)
      form.reset()
    },
    [createLayer, t, toggle, form],
  )

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Layer.AddLayer')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Box display="flex" mt={10} mb={10} sx={{ flexFlow: 'row', marginBottom: '30px' }}>
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            label={t('Layer.Name')}
            placeholder={t('Layer.Name') || ''}
            type="text"
            {...form.getInputProps('name')}
          />
        </Box>

        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" loading={isSubmitting} disabled={isSubmitting} type="submit">
            {t('Common.Create')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
