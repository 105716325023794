/* eslint-disable @typescript-eslint/naming-convention */
import { ActionIcon, Box, Menu, Text } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { Project, ProjectView } from 'api/dto'
import { ProjectProgressBar } from 'components/progressbar/progress-bar'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconSettings, IconDatabaseExport } from '@tabler/icons-react'
import { ColorScheme } from 'theme/theme'
import { getInitials } from 'utils/initials'
import UploadPlanIcon from 'assets/icons/upload.svg?react'
import EditIcon from 'assets/icons/edit.svg?react'
import ShareIcon from 'assets/icons/share.svg?react'
import ArchiveIcon from 'assets/icons/trash.svg?react'
import { formatMoment } from 'utils/date'
import { useNavigate } from 'react-router-dom'
import { Actions, TileProps } from './tile'
import { backupProject } from 'api/query/project'
import { showNotification } from '@mantine/notifications'
import dayjs from 'dayjs'
import { GlobalFeature } from 'api/dto/feature'

const useProjectTileStyles = createStyles((theme) => ({
  container: {
    width: '293px',
  },
  tile: {
    position: 'relative',
    backgroundColor: theme.colors[ColorScheme.customGray][1],
    width: '293px',
    height: '10.625rem',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background-color 0.5s ease',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: theme.colors[ColorScheme.customGray][2],
    },
  },
  projectName: {
    fontSize: '1rem',
  },
  projectTotalPages: {
    color: theme.colors[ColorScheme.customGray][5],
  },
}))

export function ProjectTile({
  item,
  openAddFileModal,
  openShareModal,
  openEditModal,
  viewType,
}: TileProps<Project> & Actions & { viewType: ProjectView }) {
  const { t } = useTranslation()
  const { classes } = useProjectTileStyles()
  const navigate = useNavigate()
  const initials = useMemo(() => getInitials(item.name), [item])

  let url = `/app/projects/${item.id}`

  switch (viewType) {
    case ProjectView.ORGANIZATION:
      url = `/app/organization-projects/${item.id}`
      break
    case ProjectView.SHARED:
      url = `/app/shared-projects/${item.id}`
      break
  }

  return (
    <Box className={classes.container}>
      <Box
        className={classes.tile}
        onClick={() => {
          navigate(url)
        }}
      >
        <Menu shadow="md" width={270}>
          <Menu.Target>
            <ActionIcon
              size="sm"
              color="gray"
              variant="filled"
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <IconSettings />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
            <Menu.Label>
              <Text size="lg" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][2] })}>
                {item.name}
              </Text>

              <Text size="xs" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][2] })}>
                {item.numberOfPages} pages
              </Text>

              <Text size="xs" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][2] })}>
                Last modified {formatMoment(item.lastModifiedAt)}
              </Text>
            </Menu.Label>
            <Menu.Item
              leftSection={<EditIcon width={17} height={17} />}
              onClick={() => {
                navigate(url)
              }}
            >
              {t('Project.Menu.OpenProject')}
            </Menu.Item>

            <Menu.Item
              disabled={!item.hasAccess(GlobalFeature.EDIT_PROJECT)}
              leftSection={<EditIcon fill={!item.hasAccess(GlobalFeature.EDIT_PROJECT) ? '#adb5bd' : undefined} width={17} height={17} />}
              onClick={() => openEditModal(item)}
            >
              {t('Project.Menu.EditProject')}
            </Menu.Item>
            <Menu.Item
              disabled={!item.hasAccess(GlobalFeature.SHARE)}
              leftSection={<ShareIcon fill={!item.hasAccess(GlobalFeature.EDIT_PROJECT) ? '#adb5bd' : undefined} width={17} height={17} />}
              onClick={() => openShareModal(item)}
            >
              {t('Project.Menu.Share')}
            </Menu.Item>

            <Menu.Item
              disabled={!item.hasAccess(GlobalFeature.EDIT_PROJECT)}
              leftSection={
                <UploadPlanIcon fill={!item.hasAccess(GlobalFeature.EDIT_PROJECT) ? '#adb5bd' : undefined} width={17} height={17} />
              }
              onClick={() => openAddFileModal(item)}
            >
              {t('Project.Menu.UploadPlans')}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              disabled={!item.hasAccess(GlobalFeature.SHARE)}
              leftSection={
                <IconDatabaseExport fill={!item.hasAccess(GlobalFeature.EDIT_PROJECT) ? '#adb5bd' : undefined} width={17} height={17} />
              }
              onClick={async () => {
                showNotification({
                  title: t('Project.BackupCreating'),
                  message: t('Project.BackupCreatingMessage'),
                  color: 'info',
                })

                const backup = await backupProject(item.id)

                if (backup.data) {
                  showNotification({
                    title: t('Project.BackupCreated'),
                    message: t('Project.BackupCreatedSuccessfully'),
                    color: 'green',
                  })

                  const link = document.createElement('a')
                  link.href = URL.createObjectURL(new Blob([JSON.stringify(backup.data)], { type: 'application/json' }))
                  link.download = `project-backup-${item.name}-${dayjs().format('YYYY-MM-DD-hh-mm')}.tt`
                  link.click()
                } else {
                  showNotification({
                    title: t('Project.BackupFailed'),
                    message: t('Project.BackupFailedMessage'),
                    color: 'red',
                  })
                }
              }}
            >
              {t('Project.Menu.Backup')}
            </Menu.Item>
            {/*TODO*/}
            <Menu.Item disabled leftSection={<ArchiveIcon stroke={'#adb5bd'} fill={'#adb5bd'} width={17} height={17} />}>
              {t('Project.Menu.Archive')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text size="xl" color="white">
          {initials}
        </Text>
      </Box>
      <Box mt={7}>
        <Text className={classes.projectName}>{item.name}</Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text className={classes.projectTotalPages} size="xs" mr={20} w={100}>
          {item.numberOfPagesWithTakeoff} pages with takeoff
        </Text>
        <ProjectProgressBar filledPages={item.numberOfPagesWithTakeoff} totalPages={item.numberOfPages} />
      </Box>
    </Box>
  )
}
