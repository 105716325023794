import { calculateArea, calculateAreaWithHeight, calculatePerimeter, calculateVolume, calculateVolumeWithThickness } from 'utils/math/math'
import { ScaleFactor } from 'utils/scale/scale'
import { Calculation, IGroupCanvas, IPoint, ShapeTypes } from '../types'

export const NUMBER_OF_DECIMAL = 2

export function calculate(group: Partial<IGroupCanvas>, points: IPoint[], scale: ScaleFactor): Calculation {
  const { type, depth, thickness } = group
  let volume: string | undefined = undefined
  let area: string | undefined = undefined
  let perimeter: string | undefined = undefined
  let count: string | undefined = undefined

  // change unit when groups going to have units
  switch (Number(type as unknown as string | number)) {
    case ShapeTypes.Area:
      if (depth) {
        volume = calculateVolume(calculateArea(points, scale), depth).toFixed(NUMBER_OF_DECIMAL)
        area = calculateArea(points, scale).toFixed(NUMBER_OF_DECIMAL)
      } else {
        area = calculateArea(points, scale).toFixed(NUMBER_OF_DECIMAL)
      }
      perimeter = calculatePerimeter(points, scale, true).toFixed(NUMBER_OF_DECIMAL)
      break
    case ShapeTypes.Measure:
      perimeter = calculatePerimeter(points, scale, false).toFixed(NUMBER_OF_DECIMAL)
      break
    case ShapeTypes.Line: {
      const calculated = calculatePerimeter(points, scale, false)
      perimeter = calculated.toFixed(NUMBER_OF_DECIMAL)
      if (depth) {
        area = calculateAreaWithHeight(calculated, depth).toFixed(NUMBER_OF_DECIMAL)
      }

      if (depth && thickness) {
        const areaWithHeight = calculateAreaWithHeight(calculated, depth)
        volume = calculateVolumeWithThickness(areaWithHeight, thickness).toFixed(NUMBER_OF_DECIMAL)
      }

      break
    }
    case ShapeTypes.Count:
      count = points.length.toString()
      break
    default:
      break
  }

  return {
    volume,
    area,
    perimeter,
    count,
  }
}
