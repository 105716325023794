import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { ProjectDto, Project } from 'api/dto'
import axios from 'config/axios'
import environment from 'config/environment'
import { useCallback } from 'react'

enum FilterType {
  MY_PROJECTS = 'my-projects',
  ORGANIZATION = 'organization',
  SHARED = 'shared',
  RECENT = 'recent',
  RECENT_PAGES = 'recent-pages',
}

export const useListMyProjects = () => {
  return useQuery<ProjectDto[], Error, Project[]>({
    queryKey: ['projects', 'list', 'my-projects'],
    queryFn: async () => {
      const { data } = await axios.get<ProjectDto[]>(environment.restApi.resourceUrl.projects, {
        params: { ['filter-type']: FilterType.MY_PROJECTS },
      })
      return data
    },
    select: useCallback((response) => response.map((p) => new Project(p)), []),
  })
}

export const useListRecentProjects = () => {
  return useQuery<ProjectDto[], Error, Project[]>({
    queryKey: ['projects', 'list', 'recent'],
    queryFn: async () => {
      const { data } = await axios.get<ProjectDto[]>(environment.restApi.resourceUrl.projects, {
        params: { ['filter-type']: FilterType.RECENT },
      })
      return data
    },
    select: useCallback((response) => response.map((p) => new Project(p)), []),
  })
}

export const useListSharedProjects = () => {
  return useQuery<ProjectDto[], Error, Project[]>({
    queryKey: ['projects', 'list', 'shared'],
    queryFn: async () => {
      const { data } = await axios.get<ProjectDto[]>(environment.restApi.resourceUrl.projects, {
        params: { ['filter-type']: FilterType.SHARED },
      })
      return data
    },
    select: useCallback((response) => response.map((p) => new Project(p)), []),
  })
}

export const useListOrganizationProjects = () => {
  return useQuery({
    queryKey: ['projects', 'list', 'organization'],
    queryFn: async () => {
      const { data } = await axios.get<ProjectDto[]>(environment.restApi.resourceUrl.projects, {
        params: { ['filter-type']: FilterType.ORGANIZATION },
      })
      return data
    },
    select: useCallback((response) => response.map((p) => new Project(p)), []),
  })
}

const getProject = async (projectId?: string) => {
  const { data } = await axios.get<ProjectDto>(environment.restApi.resourceUrl.projects + '/' + projectId)
  return data
}

export const getProjectQuery = (projectId?: string) => ({
  queryKey: ['projects', projectId],
  queryFn: async () => getProject(projectId),
})

export const useGetProject = (projectId?: string) => {
  return useQuery({
    ...getProjectQuery(projectId),
    select: useCallback((response) => new Project(response), []),
    enabled: !!projectId,
  })
}

export const useGetProjectSignedUrl = () => {
  return useMutation({
    mutationFn: ({ projectId, fileName }: { projectId: string; fileName: string }) =>
      axios.post(environment.restApi.resourceUrl.projects + '/' + projectId + '/upload', { fileName }),
  })
}

export const useCreateProject = () => {
  return useMutation({
    mutationFn: (project: ProjectDto) => axios.post(environment.restApi.resourceUrl.projects, project),
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', 'list'], refetchType: 'active' })
    },
  })
}

export const useUpdateProject = () => {
  return useMutation({
    mutationFn: (project: ProjectDto) => {
      return axios.put(environment.restApi.resourceUrl.projects + '/' + project.id, project)
    },
    onSuccess: async (_, { id }) => {
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', 'list'], refetchType: 'active' })
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', id], refetchType: 'active' })
    },
  })
}

export const useDeleteProject = () => {
  return useMutation({
    mutationFn: (projectId: string) => {
      return axios.delete(environment.restApi.resourceUrl.projects + '/' + projectId)
    },
  })
}

export const backupProject = async (projectId: string) => {
  return axios.get(environment.restApi.resourceUrl.projects + '/' + projectId + '/backup')
}

export const useRestoreProject = () => {
  return useMutation({
    mutationFn: (project: any) => {
      return axios.post(environment.restApi.resourceUrl.projects + '/restore', project)
    },
  })
}
