import { GroupCanvas } from 'canvas/group'
import { Scale } from 'canvas/shape/types/scale'
import { getGroupType } from 'canvas/shape/types/utils'
import { IPoint, ShapeTypes } from 'canvas/types'
import { Unit } from 'convert-units'
import { takeoffStore } from 'store/store'
import { convertToImperialCalculations } from 'utils/measure'
import { Axis } from 'utils/scale/scale'

export interface Shape {
  id: string
  groupId: string
  points: IPoint[]
  index?: number
  axis?: Axis
}

export interface GroupDto {
  id?: string
  pageId: string
  layerId: string
  name?: string
  type: string
  unit?: string
  depth?: string
  thickness?: string
  color?: string
  shapes?: Shape[]
  deleted?: boolean
  relatedGroupId?: string
  productId?: string
  order: number
}

export class Group {
  id?: string
  name?: string
  pageId: string
  layerId: string
  type: string
  unit?: string
  depth?: string
  thickness?: string
  color?: string
  shapes?: Shape[]
  deleted?: boolean
  relatedGroupId?: string
  productId?: string
  order: number

  constructor(groupDto: Partial<GroupDto> & Pick<GroupDto, 'pageId' | 'layerId' | 'type' | 'order'>) {
    this.id = groupDto.id
    this.name = groupDto.name
    this.type = groupDto.type
    this.pageId = groupDto.pageId
    this.layerId = groupDto.layerId
    this.unit = groupDto.unit
    this.depth = groupDto.depth
    this.thickness = groupDto.thickness
    this.color = groupDto.color
    this.shapes = groupDto.shapes
    this.deleted = groupDto.deleted
    this.relatedGroupId = groupDto.relatedGroupId
    this.productId = groupDto.productId
    this.order = groupDto.order
  }

  get isCalculatedGroup() {
    const currentType = ShapeTypes[getGroupType(this.type)]

    return currentType != ShapeTypes.None && currentType != ShapeTypes.Measure && currentType != ShapeTypes.Scale
  }

  toDto(): GroupDto {
    const { pageId, layerId } = takeoffStore.getState().config || {}

    return {
      id: this.id,
      pageId: pageId!,
      layerId: layerId!,
      name: this.name,
      type: this.type,
      unit: this.unit,
      depth: this.depth,
      thickness: this.thickness,
      color: this.color,
      shapes: this.shapes,
      deleted: this.deleted,
      relatedGroupId: this.relatedGroupId,
      productId: this.productId,
      order: this.order,
    }
  }
}

export class SummaryTakeoffGroup {
  id: string
  type: ShapeTypes
  name: string
  color: string
  area?: string
  count?: string
  perimeter?: string
  volume?: string
  depth?: string
  thickness?: string
  factor?: number
  productId?: string
  order: number
  isVisible: boolean
  shapes: { id: string; groupId: string; points: IPoint[]; axis?: Axis }[]

  constructor(group: GroupCanvas) {
    this.id = group.id
    this.type = group.type
    this.name = group.name
    this.color = group.color
    this.depth = group.depth
    this.thickness = group.thickness
    this.factor = group.factor
    this.productId = group.productId
    this.order = group.order
    this.isVisible = group.isVisible
    this.shapes = group.shapes.map((s) => ({ id: s.id, groupId: s.groupId, points: s.points, axis: (s as Scale).axis }))

    if (group.calculations) {
      const calculations = convertToImperialCalculations(group.calculations, group.unit as Unit)
      this.area = calculations.area
      this.count = calculations.count
      this.perimeter = calculations.perimeter
      this.volume = calculations.volume
    }
  }

  toDto(): GroupDto {
    const { pageId, layerId } = takeoffStore.getState().config || {}

    return {
      id: this.id,
      pageId: pageId!,
      layerId: layerId!,
      name: this.name,
      type: this.type.toString(),
      unit: this.type === ShapeTypes.Area ? 'ft2' : 'ft',
      depth: this.depth,
      thickness: this.thickness,
      color: this.color,
      shapes: this.shapes,
      deleted: false,
      relatedGroupId: undefined,
      productId: this.productId,
      order: this.order,
    }
  }
}
