import { Button, Group, Modal, Text, Box, Checkbox, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectDto, System } from 'api/dto'
import { showNotification } from '@mantine/notifications'
import { useAuth } from 'hooks/useAuth'
import { useUpdateProject } from 'api/query/project'

interface FormProps {
  name: string
  system?: System
  organizationId?: string
}

interface EditPageProps {
  open: boolean
  project?: ProjectDto
  toggle: () => void
}

export function EditProject({ open, project, toggle }: EditPageProps) {
  const { user } = useAuth()
  const { mutateAsync: updateProject } = useUpdateProject()
  const { t } = useTranslation()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm<FormProps>({
    initialValues: {
      name: project?.name || '',
      system: project?.system,
      organizationId: project?.organizationId,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      if (!project) {
        return
      }

      setIsSubmitting(true)

      try {
        await updateProject({ id: project.id, ...values } as ProjectDto)

        showNotification({
          title: t('Project.ProjectUpdated'),
          message: t('Project.ProjectUpdatedSuccessfully'),
          color: 'green',
        })

        toggle()
      } catch (error) {
        // eslint-disable-next-line no-console
      }

      setIsSubmitting(false)
    },
    [project, updateProject, t, toggle],
  )

  useEffect(() => {
    if (open) {
      form.setValues({
        name: project?.name || '',
        system: project?.system,
        organizationId: project?.organizationId,
      })
    } else {
      form.reset()
    }
    // we dont want to trigger a setValues every time the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, open])

  return (
    <Modal
      opened={open}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Project.EditProject')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Box display="flex" mt={10} sx={{ flexFlow: 'column', marginBottom: '30px' }}>
          <TextInput
            withAsterisk
            mb={18}
            label={t('Page.Name')}
            placeholder={t('Page.EnterPageName') || ''}
            type="text"
            {...form.getInputProps('name')}
          />

          <Select
            size="sm"
            label={t('Project.ProjectUnitSystem')}
            withAsterisk
            value={form.values.system || ''}
            onChange={(e) => form.setFieldValue('system', e as System)}
            data={[{ value: System.IMPERIAL, label: t('Unit.Imperial') }]}
          />
        </Box>

        {user?.currentOrgId && project?.organizationId == null && (project?.isOwner || user.isOrgAdmin()) && (
          <Box mb={20}>
            <Checkbox
              radius="4px"
              label={t('Project.PublishInOrg')}
              checked={!!form.values.organizationId}
              onChange={(e) => form.setFieldValue('organizationId', e.target.checked ? user.currentOrgId : undefined)}
            />
          </Box>
        )}

        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" type="submit" loading={isSubmitting}>
            {t('Common.Update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
