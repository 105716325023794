import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ReadyState, default as useReactWebsocket } from 'react-use-websocket'
import { useAuth } from '../useAuth'
import environment from 'config/environment'
import { WebSocketMessage } from './message'

const websocketContext = createContext<ReturnType<typeof useWebSocketProvider>>({} as any)

export function ProvideWebsocket({ children }: { children: ReactNode }): JSX.Element {
  const websocket = useWebSocketProvider()
  return <websocketContext.Provider value={websocket}>{children}</websocketContext.Provider>
}

export const useWebsocket = () => useContext(websocketContext)

const useWebSocketProvider = () => {
  const { token } = useAuth()
  const [result, setResult] = useState<WebSocketMessage>()

  const {
    sendMessage: sM,
    lastMessage,
    readyState,
  } = useReactWebsocket(`${environment.restApi.websocketUrl}?idToken=${token}`, {
    shouldReconnect: (closeEvent) => true,
  })

  // Check if WebSocket connection is open and ready for sending messages
  const canSendMessages = readyState === ReadyState.OPEN

  useEffect(() => {
    setResult(new WebSocketMessage(lastMessage))
  }, [lastMessage])

  return {
    send: sM,
    isInReadyState: canSendMessages,
    result,
    reset: () => setResult(undefined),
  }
}
