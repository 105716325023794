import { ShapeTypes } from 'canvas/types'

export function getShapeStroke(type: ShapeTypes) {
  let stroke = 5

  switch (type) {
    case ShapeTypes.Line:
      stroke = 10
      break
    case ShapeTypes.Count:
      stroke = 3
      break
    case ShapeTypes.Area:
      stroke = 5
      break
    case ShapeTypes.Measure:
      stroke = 10
      break
    default:
      break
  }
  return stroke
}
