import { Box, Menu, Text } from '@mantine/core'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar } from './avatar'
import { useGetMeUser } from 'api/query/user'

export function AvatarWithUserMenu() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: user } = useGetMeUser()
  const { user: currentUser, logout } = useAuth()

  return (
    <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <Menu withArrow position="bottom">
        <Menu.Target>
          <Avatar backgroundColor="custom-gray.2" color="white" />
        </Menu.Target>

        <Menu.Dropdown>
          {user && (
            <>
              <Menu.Label>
                <Box>{t('Common.Profile')}</Box>
                <Text size="sm" c="black" mt={10}>
                  {user?.fullName}
                </Text>
                <Text size="sm" c="custom-gray.1" mt={5}>
                  {user?.email}
                </Text>
              </Menu.Label>
              <Menu.Divider />
            </>
          )}

          {currentUser?.currentOrgId && currentUser.isOrgAdmin() && (
            <>
              <Menu.Label>{t('Common.Organization')}</Menu.Label>
              <Menu.Item onClick={() => navigate('/app/settings/organization')}>{t('Organization.Settings')}</Menu.Item>
              <Menu.Divider />
            </>
          )}

          <Menu.Label>{t('Common.UserSection')}</Menu.Label>
          <Menu.Item onClick={() => navigate('/app/settings/user')}>{t('User.Settings')}</Menu.Item>
          <Menu.Item onClick={() => void logout()}>{t('User.Logout')}</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  )
}
