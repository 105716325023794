/* eslint-disable @typescript-eslint/naming-convention */
import { ColorScheme } from 'theme/theme'
import { createStyles } from '@mantine/emotion'

export const useToolbarStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    transition: 'all 0.5s ease',
    height: '100%',
  },
  toolbar: {
    padding: '20px 0',
    minWidth: '55px',
    maxWidth: '55px',
    backgroundColor: theme.colors[ColorScheme.customGray][2],

    display: 'flex',
    flexFlow: 'column',
  },
  icon: {
    backgroundColor: theme.colors[ColorScheme.customGray][2],
    svg: {
      path: {
        fill: 'white',
      },
    },

    ':disabled': {
      svg: {
        opacity: 0.5,
      },

      backgroundColor: 'unset',
      border: 'none',

      ':hover': {
        backgroundColor: 'unset',
        border: 'none',
      },
    },

    ':hover': {
      backgroundColor: 'white',
      svg: {
        path: {
          fill: 'black',
        },
      },
    },
  },
  stop: {
    ':disabled': {
      svg: {
        opacity: 0.5,
      },

      backgroundColor: 'unset',
      border: 'none',
    },

    ':hover': {
      backgroundColor: 'white',
    },
  },
  navigation: {
    transition: 'width 0.3s',
    paddingTop: 15,
    backgroundColor: theme.colors[ColorScheme.customGray][6],
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',

    height: '100%',
  },
}))
