import axios from 'config/axios'
import environment from 'config/environment'

export class ProjectService {
  static generateProjectReport(projectId: string, pages: string[] | undefined, type: 'pdf' | 'csv' = 'pdf') {
    return axios.post(environment.restApi.resourceUrl.projects + `/${projectId}/export`, {
      type,
      pageIds: pages,
    })
  }
}
