import { useListGroups } from 'api/query/group'
import { useGetLayer } from 'api/query/layer'
import { useGetPage } from 'api/query/page'
import { buildRenderer } from 'canvas/renderer/renderer-builder'
import { TakeoffSidebar } from 'components/sidebar/takeoff-sidebar'
import { useAuth } from 'hooks/useAuth'
import { useKeyPress } from 'hooks/useKeyPress'
import { usePageLayerConnect } from 'hooks/websocket/usePageLayerConnect'
import { useReceiveMessage } from 'hooks/websocket/useReceiveMessage'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { takeoffStore } from 'store/store'
import { useStore } from 'zustand'

export function RendererComponent() {
  const { projectId, pageId, layerId } = useParams()
  const { user } = useAuth()
  const { sendPageLayerConnect, isInReadyState } = usePageLayerConnect()
  const { isFetching: isLoadingPage, data: page, isSuccess: isSuccessPage } = useGetPage(projectId!, pageId!)
  const { isFetching: isLoadingLayer, data: layer, isSuccess: isSuccessLayer } = useGetLayer(projectId!, pageId!, layerId!)
  const { data: groups, isFetching: isLoadingGroups } = useListGroups(projectId!, pageId!, layerId!)
  const resetSelectedEntities = useStore(takeoffStore, (state) => state.resetSelectedEntities)
  const { setRenderer, renderer, resetScaleBoxState, isDrawing, setLock } = useStore(takeoffStore, (state) => ({
    isDrawing: state.isDrawing,
    renderer: state.renderer,
    resetScaleBoxState: state.resetScaleBoxState,
    setRenderer: state.setRenderer,
    setLock: state.setScaleLockedState,
  }))
  const escPressed = useKeyPress('Escape')
  const [canRenderLayer, setCanRenderLayer] = useState(true)

  // handle page updates for groups/scale
  useReceiveMessage()

  // Connect to the page
  useEffect(() => {
    if (pageId && projectId && layerId && isInReadyState && user) {
      sendPageLayerConnect(user.id, projectId, pageId, layerId)
    }
  }, [pageId, user, layerId, sendPageLayerConnect, isInReadyState, projectId])

  const reset = useCallback(() => {
    resetScaleBoxState()
    resetSelectedEntities()
    setRenderer(undefined)
    setCanRenderLayer(true)
  }, [resetSelectedEntities, setRenderer, resetScaleBoxState])

  useEffect(() => {
    if (!isSuccessLayer || !isSuccessPage || isLoadingPage || isLoadingLayer || isLoadingGroups) {
      return
    }

    const listener = () => renderer?.fitStageIntoParentContainer()
    window.addEventListener('resize', listener)

    if (!renderer || renderer.config.layerId !== layerId) {
      reset()
      setRenderer(buildRenderer(page, layer))
    }

    setCanRenderLayer(true)

    return () => {
      window.removeEventListener('resize', () => listener)
    }
  }, [renderer, setRenderer, isLoadingPage, isLoadingLayer, isLoadingGroups, layerId, reset, isSuccessLayer, isSuccessPage, page, layer])

  useEffect(() => {
    if (isDrawing) {
      return
    }

    if (!layerId || !canRenderLayer) {
      return
    }

    if (isLoadingGroups || isLoadingLayer || isLoadingPage) {
      return
    }

    if (!renderer) {
      return
    }

    if (!groups || groups.length === 0) {
      setLock(false)
      setCanRenderLayer(false)
      return
    }

    renderer.renderAndCalculateProject(groups)

    setCanRenderLayer(false)
  }, [isLoadingGroups, isLoadingLayer, isLoadingPage, renderer, layerId, isDrawing, groups, canRenderLayer, setLock])

  useEffect(() => {
    if (escPressed) {
      resetSelectedEntities()
    }
  }, [escPressed, resetSelectedEntities])

  return (
    <>
      <div
        id="container"
        style={{
          width: '100%',
        }}
      />
      <TakeoffSidebar />
    </>
  )
}
