import { Button, Group, Modal, Text, Box, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Role } from 'api/dto/role'
import { showNotification } from '@mantine/notifications'
import { useAddUserInOrganization } from 'api/query/organization'

interface FormProps {
  firstName: string
  lastName: string
  email: string
  role: Role
}

export function AddUser() {
  const { t } = useTranslation()
  const [opened, showAddUserModal] = useState(false)
  const { mutateAsync: addUserInOrg } = useAddUserInOrganization()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const form = useForm<FormProps>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: Role.USER,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      setIsSubmitting(true)
      // todo fix this route
      await addUserInOrg({
        role: values.role,
        user: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        },
      })
      showNotification({
        title: t('User.Added'),
        message: t('User.AddedSuccessfully'),
        color: 'green',
      })
      showAddUserModal(false)
      setIsSubmitting(false)
      form.reset()
    },
    [addUserInOrg, form, t],
  )

  return (
    <>
      <Button radius="xxs" size="md" onClick={() => showAddUserModal(true)}>
        <Text size="sm" fw={400} color="white">
          {t('User.AddUser')}
        </Text>
      </Button>
      <Modal
        opened={opened}
        size="lg"
        radius="xxs"
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => showAddUserModal(false)}
        title={
          <Text fz={20} lh="30px" fw={400}>
            {t('User.AddUser')}
          </Text>
        }
      >
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <Box display="flex" mt={10} mb={10} sx={{ flexFlow: 'row', marginBottom: '30px' }}>
            <TextInput
              sx={{ flex: 1 }}
              withAsterisk
              label={t('User.FirstName')}
              placeholder={t('User.FirstName') || ''}
              type="text"
              {...form.getInputProps('firstName')}
            />

            <TextInput
              withAsterisk
              sx={{ marginLeft: '10px', flex: 1 }}
              label={t('User.LastName')}
              placeholder={t('User.LastName') || ''}
              type="text"
              {...form.getInputProps('lastName')}
              error={undefined}
            />
          </Box>
          <Box mb={10} display="flex">
            <TextInput
              sx={{ flex: 1 }}
              withAsterisk
              label={t('User.Email')}
              placeholder={t('User.Email') || ''}
              type="text"
              {...form.getInputProps('email')}
              error={undefined}
            />
          </Box>
          <Box mb={20} display="flex">
            <Select
              sx={{ flex: 1 }}
              label={t('User.Role')}
              withAsterisk
              value={form.values.role}
              onChange={(e) => {
                form.setFieldValue('role', e as Role)
              }}
              data={[
                { value: Role.USER, label: t('User.UserRole.User') },
                { value: Role.ADMIN, label: t('User.UserRole.Admin') },
              ]}
            />
          </Box>

          <Group justify="right" mt="md">
            <Button radius="xxs" size="sm" loading={isSubmitting} disabled={isSubmitting} type="submit">
              {t('User.AddUserToOrganization')}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}
