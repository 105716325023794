import { useMutation, useQuery } from '@tanstack/react-query'
import { LayerDto, Layer } from 'api/dto/layer'
import axios from 'config/axios'
import environment from 'config/environment'
import { listGroupsQuery } from './group'
import { globalQueryClient } from 'api/client'
import { useCallback } from 'react'

export const listLayers = async (projectId: string, pageId: string) => {
  const { data } = await axios.get<LayerDto[]>(environment.restApi.resourceUrl.projects + '/' + projectId + '/pages/' + pageId + '/layers')
  return data
}

const listLayersQuery = (projectId: string, pageId: string) => ({
  queryKey: ['projects', projectId, 'pages', pageId, 'layers'],
  queryFn: () => listLayers(projectId, pageId),
})

export const layersLoader = async ({ params }: { params: Record<string, any> }) => {
  const query = listLayersQuery(params.projectId, params.pageId)
  return globalQueryClient.ensureQueryData(query)
}

export const useListLayers = (projectId: string, pageId: string) => {
  return useQuery({
    ...listLayersQuery(projectId, pageId),
    select: useCallback((response) => response.map((layer: LayerDto) => new Layer(layer)), []),
  })
}

export const getLayerQuery = (projectId: string, pageId: string, layerId: string) => ({
  queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId],
  queryFn: async () => {
    const { data } = await axios.get<Layer>(
      environment.restApi.resourceUrl.projects + '/' + projectId + '/pages/' + pageId + '/layers/' + layerId,
    )
    return data
  },
})

// we're loading groups here too
export const layerAndGroupsLoader = async ({ params }: { params: Record<string, any> }) => {
  const layerQuery = getLayerQuery(params.projectId, params.pageId, params.layerId)
  await globalQueryClient.ensureQueryData(layerQuery)

  const groupsQuery = listGroupsQuery(params.projectId, params.pageId, params.layerId)
  return globalQueryClient.ensureQueryData(groupsQuery)
}

export const useGetLayer = (projectId: string, pageId: string, layerId: string) => {
  return useQuery({
    ...getLayerQuery(projectId, pageId, layerId),
    select: useCallback((response) => {
      return new Layer(response)
    }, []),
    enabled: !!layerId,
  })
}

export const useCreateLayer = (projectId: string, pageId: string) => {
  return useMutation({
    mutationFn: (layer: { name: string }) => {
      return axios.post(environment.restApi.resourceUrl.projects + '/' + projectId + '/pages/' + pageId + '/layers', layer)
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'pages', pageId],
      })
    },
  })
}
