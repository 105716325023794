import Konva from 'konva'
import { takeoffStore } from 'store/store'
import { lighten } from 'utils/color'
import { Axis } from 'utils/scale/scale'
import { IPoint } from '../../types'
import { DELIMITER } from 'store/group-shape.store'

class CachedAnchor {
  #anchor: Konva.Circle
  constructor() {
    this.#anchor = new Konva.Circle({ radius: 5, draggable: true, visible: true })
    this.#anchor.cache()
  }

  clone() {
    return this.#anchor.clone()
  }
}

const cachedAnchor = new CachedAnchor()

interface AnchorProps {
  point: IPoint
  shapeId: string
  groupId: string
  movePoint: (p: IPoint) => void
  color?: string
  axis?: Axis

  onSave?: () => void
}

export class Anchor {
  #point: IPoint
  instance: Konva.Circle
  color: string
  #move: (p: IPoint) => void
  #groupId: string
  #shapeId: string
  #drawingSub: () => void
  #unsubscribe: () => void
  #axis?: Axis
  #onSave?: () => void

  get point() {
    return this.#point
  }

  setGroupId(id: string) {
    this.#groupId = id
  }

  isSelected = (ids: string[] | undefined) => {
    ;(ids || []).forEach((id) => {
      if (id.includes(this.point.id)) return true
    })

    return false
  }

  constructor(props: AnchorProps) {
    const { point, groupId, movePoint, onSave, color = '#ffcc5c', shapeId, axis } = props
    this.color = color
    this.instance = cachedAnchor.clone()
    this.#move = movePoint
    this.#onSave = onSave
    this.#shapeId = shapeId
    this.#groupId = groupId
    this.#axis = axis

    this.#point = point

    this.instance.setPosition({ ...point })
    this.instance.stroke(color)
    this.instance.fill(lighten(color))

    this.instance.on('dragmove', this.#onDragMove)
    this.instance.on('dragend', this.#onDragEnd)

    this.instance.on('mouseenter', (e) => {
      if (takeoffStore.getState().isDrawing) {
        return
      }

      e.cancelBubble = true

      document.body.style.cursor = 'pointer'
    })
    this.instance.on('mouseleave', (e) => {
      if (takeoffStore.getState().isDrawing) {
        return
      }
      e.cancelBubble = true

      document.body.style.cursor = 'default'

      if (this.isSelected(takeoffStore.getState().selectedIds)) {
        return
      }

      this.instance.stroke(this.color)
    })
    this.instance.on('mouseover', (e) => {
      if (takeoffStore.getState().isDrawing) {
        return
      }
      e.cancelBubble = true

      this.instance.stroke('#ff6f69')
    })
    this.instance.on('click', this.#onMouseClick)

    this.#unsubscribe = takeoffStore.subscribe((state) => {
      if (this.isSelected(state.selectedIds)) {
        this.instance.stroke('red')
      } else {
        this.instance.stroke(this.color)
      }
    })

    this.#drawingSub = takeoffStore.subscribe((state) => {
      if (state.isDrawing) {
        this.instance.listening(false)
      } else {
        this.instance.listening(true)
      }
    })

    if (takeoffStore.getState().isDrawing) {
      this.instance.listening(false)
    }
  }

  #onMouseClick = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.evt.preventDefault()
    evt.cancelBubble = true

    if (takeoffStore.getState().isDrawing) {
      return
    }

    takeoffStore.getState().select(`${this.#groupId}${DELIMITER}${this.#shapeId}${DELIMITER}${this.#point.id}`)
  }

  #onDragMove = (evt: Konva.KonvaEventObject<DragEvent>) => {
    evt.evt.preventDefault()
    evt.cancelBubble = true

    if (takeoffStore.getState().isDrawing) {
      return
    }

    if (!this.#axis) {
      const newPoint = { id: this.#point.id, x: evt.target.x(), y: evt.target.y() }
      this.#move(newPoint)

      return
    }

    const x = this.#axis && this.#axis === Axis.Horizontal ? evt.target.x() : this.#point.x
    const y = this.#axis && this.#axis === Axis.Vertical ? evt.target.y() : this.#point.y

    if (this.#axis && this.#axis === Axis.Horizontal) {
      this.instance.y(y)
    } else {
      this.instance.x(x)
    }

    const newPoint = { id: this.#point.id, x, y }
    this.#move(newPoint)
  }

  #onDragEnd = () => {
    this.#onSave?.()
    this.#save()
  }

  #save = () => {
    const { recalculateGroup, saveGroup } = takeoffStore.getState()
    recalculateGroup(this.#groupId)
    saveGroup(this.#groupId)
  }

  move(point: IPoint) {
    this.instance.setPosition({ ...point })
  }

  moveTo(layer: Konva.Layer) {
    layer.add(this.instance)
  }

  remove() {
    this.instance.remove()
    this.instance.destroy()
    this.#unsubscribe()
    this.#drawingSub()
  }
}
