import axios, { AxiosRequestConfig } from 'axios'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { errorInterceptor, requestInterceptor, responseInterceptor } from './interceptors'
import environment from './environment'

let axiosRequestId: number | undefined

const createdAxios = axios.create({
  baseURL: environment.restApi.baseUrl,
})

export function setRequestInterceptor(logoutCallback: any): void {
  // callback that is called every time we make a request
  // validates the session and assign the bearer token if the session is valid
  // else throw and cancel the call made
  axiosRequestId = createdAxios.interceptors.request.use(async (config) => {
    let newConfig
    try {
      newConfig = await refresh(config)
    } catch (error) {
      await logoutCallback()
      return Promise.reject(error)
    }
    return newConfig
  })
}

async function refresh(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const newConfig = { ...config }
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
  const currentSession: CognitoUserSession | null = await Auth.currentSession()
  if (!currentSession) {
    throw new Error('No session')
  }
  // no need to refresh if session is still valid
  if (currentSession.isValid()) {
    newConfig.headers = {
      Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
    }
    return newConfig
  }
  // attempt a manuel refresh even though currentSession is supposed to refresh token
  const refreshSession = new Promise<AxiosRequestConfig>((resolve, rej) =>
    cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session: CognitoUserSession) => {
      // do something with the new session
      if (err) {
        rej(err)
        throw new Error(err as never)
      }

      newConfig.headers = {
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      }

      return resolve(config)
    }),
  )
  return refreshSession
}

export function resetRequestInterceptor(): void {
  if (axiosRequestId !== 0 && !axiosRequestId) {
    return
  }
  createdAxios.interceptors.request.eject(axiosRequestId)
}

createdAxios.interceptors.request.use(requestInterceptor)
createdAxios.interceptors.response.use(responseInterceptor, errorInterceptor)

export default createdAxios
