import { Text, Box } from '@mantine/core'
import { User } from 'api/dto'
import { forwardRef } from 'react'
import { ColorScheme } from 'theme/theme'
import { Avatar } from 'components/avatar/avatar'
import { capitalize } from 'utils/string'
import { useGetMeUser } from 'api/query/user'

export function UserLink() {
  const { data: user, isLoading } = useGetMeUser()

  if (!user || isLoading) {
    return null
  }

  return <UserLinkWrapper user={user} />
}

export const UserLinkWrapper = forwardRef<HTMLDivElement, { user: User }>(({ user, ...others }, ref) => {
  const { fullName } = user
  return (
    <Box
      p="md"
      {...others}
      sx={(theme) => ({
        svg: {
          border: 'none',
          path: {
            fill: theme.colors[ColorScheme.customGray][1],
            stroke: theme.colors[ColorScheme.customGray][0],
          },
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }} ref={ref}>
        {fullName && <Avatar clickable={false} backgroundColor="custom-gray.0" color="black" />}

        <Box ml={10}>
          {fullName && (
            <Text size="sm" fw={500} sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][0] })}>
              {capitalize(fullName)}
            </Text>
          )}
          {/*TODO change when theres going to be something other than free plan*/}
          <Text color="dimmed" size="xs">
            Beta
          </Text>
        </Box>
      </Box>
    </Box>
  )
})
