import { Box, Tabs } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { PageTitle } from 'components/title/page-title'
import { Users } from './admin/users'
import { Organizations } from './admin/organizations'
import { createStyles } from '@mantine/emotion'

const useOrganizationSettingsStyles = createStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export const Admin = () => {
  const { t } = useTranslation()
  const { classes } = useOrganizationSettingsStyles()

  return (
    <>
      <Box className={classes.title} mb={30}>
        <PageTitle title={t('Pages.Title.Admin.Title')} />
      </Box>
      <Tabs defaultValue="users">
        <Tabs.List mb={30}>
          <Tabs.Tab value="users">{t('Pages.Title.Admin.Users')}</Tabs.Tab>
          <Tabs.Tab value="organizations">{t('Pages.Title.Admin.Organizations')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="organizations" sx={{ position: 'relative' }}>
          <Organizations />
        </Tabs.Panel>
        <Tabs.Panel value="users" sx={{ position: 'relative' }}>
          <Users />
        </Tabs.Panel>
      </Tabs>
    </>
  )
}
