import { useListSharedProjects } from 'api/query/project'
import { Projects } from './projects'
import { Loading } from 'components/loading/loading'
import { ProjectView } from 'api/dto'

export function SharedProjects() {
  const { isLoading, data: sharedProjects, isSuccess } = useListSharedProjects()

  if (isLoading) {
    return <Loading height="100%" />
  }

  if (!isSuccess) {
    return null
  }

  return <Projects view={ProjectView.SHARED} projects={sharedProjects} isLoading={isLoading} />
}
