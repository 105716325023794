import { Calculation, IGroupCanvas, IPoint, IShape, ShapeTypes } from 'canvas/types'
import Konva from 'konva'
import { nanoid } from 'nanoid'
import { globalStore, takeoffStore } from 'store/store'
import { createStore } from 'zustand/vanilla'
import { lighten } from 'utils/color'
import debounce from 'lodash/debounce'
import { CombinedShapeTypes, isCount, isMeasure } from './utils'
import { Anchor } from './anchor'
import { Animation } from 'konva/lib/Animation'
import { Shape } from 'api/dto'
import { DELIMITER } from 'store/group-shape.store'

interface ShapeStore {
  points: IPoint[]
  setPoints: (points: IPoint[]) => void
}

export abstract class BaseShape<T extends Konva.Shape | Konva.Group> {
  id: string
  color: string
  radius?: number
  stroke?: { width?: number | undefined; color: string } | undefined
  groupId: string
  type: ShapeTypes
  calculation?: Calculation | undefined

  animation?: Animation

  instance: T

  private recalculate: (groupId: string) => void
  private saveGroup: (groupId: string) => void

  private getState: () => ShapeStore

  get points(): IPoint[] {
    return this.getState().points
  }

  set points(points: IPoint[]) {
    this.getState().setPoints(points)
  }

  constructor(shape: IShape, instance: T) {
    const { id, color, points: currentPoints, stroke, groupId, type } = shape

    // for points
    const store = createStore<ShapeStore>((set) => ({
      points: currentPoints || [],
      setPoints: (points) => set(() => ({ points })),
    }))

    this.id = id || nanoid()
    this.color = color!
    this.stroke = stroke
    this.groupId = groupId
    this.type = type
    this.instance = instance

    const { getState, subscribe } = store

    const { saveGroup, recalculateGroup } = takeoffStore.getState()

    this.getState = getState

    this.saveGroup = saveGroup
    this.recalculate = recalculateGroup

    subscribe(() => {
      debounce(() => recalculateGroup(this.groupId), 50)
    })
  }

  addToLayer(instance: Konva.Shape, layer: Konva.Layer) {
    layer.add(instance)
  }

  onMouseClick = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const { isDrawing, select } = takeoffStore.getState()
    if (isDrawing) {
      return
    }

    evt.cancelBubble = true
    select(`${this.groupId}${DELIMITER}${this.id}`)
  }

  onMouseMoveEnd = (evt: Konva.KonvaEventObject<DragEvent>) => {
    const { isDrawing } = takeoffStore.getState()
    if (isDrawing) {
      return
    }

    evt.cancelBubble = true
    this.save()
  }

  hasAtLeastTwoPoints = () => {
    return this.points.length > 1
  }

  isSelected = (ids: string[] | undefined) => {
    return ids?.includes(this.id)
  }

  moveLabel = (instance: Konva.Line, label: Konva.Label) => {
    const box = instance.getSelfRect()
    const x = box.x + box.width / 2
    const y = box.y + box.height / 2

    label.x(x)
    label.y(y)
  }

  moveTo = (layer: Konva.Layer) => {
    this.instance.moveTo(layer)
  }

  updateColor = (type: ShapeTypes, color: string) => {
    switch (type) {
      case ShapeTypes.Count:
      case ShapeTypes.Area: {
        if (this.instance instanceof Konva.Shape) {
          this.instance.fill(lighten(color || '#cecece'))
          setTimeout(() => (this.instance as Konva.Shape).stroke(color), 200)
        } else {
          this.instance.children?.forEach((s) => {
            if (s instanceof Konva.Shape && !(s instanceof Konva.Text)) {
              s.fill(color || '#cecece')
              setTimeout(() => s.stroke(color), 200)
            }
          })
        }

        break
      }
      case ShapeTypes.Scale:
      case ShapeTypes.Measure:
      case ShapeTypes.Line: {
        if (this.instance instanceof Konva.Shape) {
          setTimeout(() => (this.instance as Konva.Shape).stroke(color), 200)
        }
        break
      }

      default:
        break
    }
  }

  save = () => {
    this.recalculate(this.groupId)
    const { userId } = globalStore.getState()
    if (userId) {
      this.saveGroup(this.groupId)
    }
  }

  followPointer = (instance: Konva.Line, point: IPoint) => {
    const points = this.points.map((p) => [p.x, p.y]).reduce((a, b) => [...a].concat(b))
    instance.points([...points, point.x, point.y])
  }

  changePoints = (shape: CombinedShapeTypes, newPoints: IPoint[]) => {
    this.points = newPoints

    const reducedPoints = newPoints.map((p) => [p.x, p.y]).reduce((a, b) => [...a].concat(b))

    if (isCount(shape)) {
      shape.instance.position({ x: newPoints[0].x, y: newPoints[0].y })
    } else if (isMeasure(shape)) {
      shape.line.points(reducedPoints)
      this.moveAnchors(shape.anchors, newPoints)
    } else {
      shape.instance.points(reducedPoints)
      this.moveAnchors(shape.anchors, newPoints)
    }
  }

  pulsate = (): void => {
    const animation = new Animation((frame) => {
      if (frame) {
        switch (this.type) {
          case ShapeTypes.Count: {
            const children = (this.instance as Konva.Group).getChildren()
            const circle = children[0] as Konva.Circle
            const size = (this.radius || 1) + 20 * Math.sin((frame.time * 2 * Math.PI) / 1000)
            circle.radius(Math.max(this.radius || 1, size))
            break
          }

          default: {
            const size = (this.stroke?.width || 1) + 20 * Math.sin((frame.time * 2 * Math.PI) / 1000)
            const line = this.instance as Konva.Line
            line.strokeWidth(size)
            break
          }
        }
      }
    }, this.instance.getLayer())

    animation.start()

    setTimeout(() => animation.stop(), 3000)
  }

  moveAnchors(anchors: Anchor[], newPoints: IPoint[]) {
    anchors.forEach((a) => {
      const currentId = a.point.id
      const foundPoint = newPoints.find((p) => p.id === currentId)
      if (foundPoint) {
        a.move(foundPoint)
      }
    })
  }

  endDraw = (instance: Konva.Line) => {
    const points = this.points.map((p) => [p.x, p.y]).reduce((a, b) => [...a].concat(b))
    instance.points(points)
  }

  isEqual = (shape: Shape) => {
    const { points } = shape
    if (points.length !== this.points.length) {
      return false
    }

    return points.every((point) => {
      const foundPoint = this.points.find((p) => p.id === point.id)
      if (!foundPoint) {
        return false
      }

      return foundPoint.x === point.x && foundPoint.y === point.y
    })
  }
}
