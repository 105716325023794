import { useEffect } from 'react'
import { useWebsocket } from './useWebsocket'
import { globalQueryClient } from 'api/client'
import { takeoffStore } from 'store/store'
import { LayerScale } from 'utils/scale/scale'

export const usePageLayerUpdated = () => {
  const { result } = useWebsocket()

  useEffect(() => {
    if (!result) {
      return
    }

    if (result.isLayerUpdated) {
      takeoffStore.getState().setScale(new LayerScale(result.message.layer.scale))

      void globalQueryClient.invalidateQueries({
        queryKey: ['projects', result.message.projectId, 'pages', result.message.pageId, 'layers', result.message.layerId],
      })
    }
  }, [result])
}
