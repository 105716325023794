import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { UserDto } from 'api/dto'
import { CreateOrganization, OrganizationDto, UpdateOrganization } from 'api/dto/organization'
import { Role } from 'api/dto/role'
import axios from 'config/axios'
import environment from 'config/environment'

const getMeOrganization = async () => {
  const { data } = await axios.get<OrganizationDto>(environment.restApi.resourceUrl.organizations + '/me')
  return data
}

export const useGetOrganization = () => {
  return useQuery({
    queryKey: ['organization', 'me'],
    queryFn: () => getMeOrganization(),
  })
}

export const useGetOrganizations = () => {
  return useQuery({
    queryKey: ['organizations', 'list'],
    queryFn: () => axios.get<OrganizationDto[]>(environment.restApi.resourceUrl.organizations),
    select: (response) => response.data.map((o) => new OrganizationDto(o)),
  })
}

export const useCreateOrganization = () => {
  return useMutation({
    mutationFn: (organization: CreateOrganization) => {
      return axios.post('/organizations', organization)
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['organizations', 'list'] })
      await globalQueryClient.invalidateQueries({ queryKey: ['user', 'me'] })
    },
  })
}

export const useUpdateOrganization = () => {
  return useMutation({
    mutationFn: (organization: UpdateOrganization) => {
      return axios.put(environment.restApi.resourceUrl.organizations + '/me', organization)
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['organizations', 'list'] })
      await globalQueryClient.invalidateQueries({ queryKey: ['organization', 'me'] })
    },
  })
}

export const useRemoveUserInOrganization = () => {
  return useMutation({
    mutationFn: (userId: string) => {
      return axios.delete(environment.restApi.resourceUrl.organizations + '/me/users/' + userId)
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list', 'organization'] })
    },
  })
}

export const useAddUserInOrganization = () => {
  return useMutation({
    mutationFn: ({ user, role }: { user: Partial<UserDto>; role: Role }) => {
      return axios.post(environment.restApi.resourceUrl.organizations + '/me/users', { user, role })
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list', 'organization'] })
    },
  })
}

export const useUpdateUserRoleInOrganization = () => {
  return useMutation({
    mutationFn: ({ userId, role }: { userId: string; role: Role }) => {
      return axios.put(environment.restApi.resourceUrl.organizations + '/me/users/' + userId, { role })
    },
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list', 'organization'] })
    },
  })
}
