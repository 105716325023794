import { useEffect, useMemo, useState } from 'react'
import { Box, Table as MantineTable, Text, TextInput, Select, Button, ButtonProps } from '@mantine/core'
import {
  useReactTable,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
  TableState,
} from '@tanstack/react-table'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useTableStyles } from './table.styles'
import { useDebouncedState } from '@mantine/hooks'
import { IconArrowUp, IconArrowDown, IconPencil, IconSearch, IconTableExport } from '@tabler/icons-react'
import { exportToCsv } from 'utils/export'

export type ColumnWithFilter<T> = ColumnDef<T> & { filter?: (row: any, columnId: string, value: any) => boolean }
export type Data = { id: string } & Record<string, any>

export interface ITableProps<T extends object> {
  columns: ColumnWithFilter<T>[]
  data: T[]
  setFilteredRows?: (rows: T[]) => void
  defaultSort?: { id: string; desc: boolean }
  hiddenColumns?: Record<string, boolean>
  actionButtons?: (rows: Row<T>[]) => ReactNode[]
  onRowActionClick?: (row: T) => void
  searchable?: boolean
  hidePagination?: boolean

  multiple?: boolean
  selected?: string | string[]

  exportTable?: {
    filename: string
    customParser?: (row: any) => any
  }
}

interface GlobalFilterProps<T extends object> {
  filteredRows: Row<T>[]
  globalFilter: string | number | readonly string[] | undefined
  setGlobalFilter: (value: string) => void
}

// Define a default UI for filtering
function GlobalFilter<T extends object>(props: GlobalFilterProps<T>) {
  const { t } = useTranslation()
  const { classes } = useTableStyles({ hasFilters: false })
  const { globalFilter, setGlobalFilter, filteredRows: preGlobalFilteredRows } = props
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const [debounced, setDebouncedVal] = useDebouncedState('', 200, undefined)

  useEffect(() => {
    setGlobalFilter(debounced)
  }, [setGlobalFilter, debounced])

  return (
    <Box className={classes.search}>
      <Box className={classes.searchInput}>
        <IconSearch color="#cecece" />
        <TextInput
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            setDebouncedVal(e.target.value)
          }}
          placeholder={t('Common.Search')}
        />
      </Box>
      <Box className={classes.result}>
        <Text c="gray" fz="0.8rem">
          {count}
        </Text>
        <Text c="gray" ml={3} fz="0.8rem">
          {t('Common.Results')}
        </Text>
      </Box>
    </Box>
  )
}

export function Table<T extends object>(props: ITableProps<T>) {
  const { t } = useTranslation()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [rowSelection, setRowSelection] = useState({})

  const { classes, cx } = useTableStyles({
    hasFilters: Object.keys(filters).length > 0,
  })
  const { columns, data, actionButtons, defaultSort, hiddenColumns, onRowActionClick, searchable = true, multiple, exportTable } = props
  const currentData = useMemo(() => data || [], [data])

  const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
    const search = filterValue.toLowerCase()

    const col = columns.find((c) => c.id === columnId)

    if (col && col.filter) {
      return col.filter(row, columnId, filterValue)
    }

    let value = row.getValue(columnId)
    if (typeof value === 'number') value = String(value)

    return (value as string)?.toLowerCase().includes(search)
  }

  const table = useReactTable<T>({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: multiple,
    columns,
    data: currentData,
    autoResetPageIndex: true,
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: globalFilterFn,
    initialState: {
      pagination: { pageSize: 10 },
      columnVisibility: hiddenColumns,
      sorting: defaultSort ? [defaultSort] : [],
    },
  })

  const {
    getRowModel,
    getHeaderGroups,
    getFilteredRowModel: filteredRows,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getState,
    setPageSize,
  } = table

  const columnWithSize = useMemo(
    () =>
      columns
        .filter((c) => !!c.id)
        .map((c) => ({ [c.id!]: c }))
        .reduce((prev, val) => Object.assign(prev, val), {}),
    [columns],
  )

  const ExportButton = ({ ...props }: ButtonProps) => {
    return (
      <Button
        color="custom-gray.0"
        leftSection={<IconTableExport />}
        disabled={filteredRows().rows.length === 0}
        onClick={() =>
          exportToCsv(
            filteredRows().rows.map((row) => {
              let currentRow: any = { ...row.original }

              if (exportTable?.customParser) {
                currentRow = exportTable.customParser(currentRow)
              }

              return currentRow
            }),
            `${exportTable?.filename}.csv`,
          )
        }
        {...props}
      >
        {t('Common.Export')}
      </Button>
    )
  }

  return (
    <>
      {searchable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {exportTable && <ExportButton h={44} mr={10} />}
          <GlobalFilter filteredRows={filteredRows().rows} globalFilter={getState().globalFilter} setGlobalFilter={setGlobalFilter} />
          {actionButtons && (
            <Box
              ml={10}
              display="flex"
              sx={{
                alignItems: 'center',
              }}
            >
              {actionButtons(filteredRows().rows)}
            </Box>
          )}
        </Box>
      )}
      <Box data-testid="test-table" className={classes.container}>
        <MantineTable sx={{ borderSpacing: 0 }} highlightOnHover striped>
          <MantineTable.Thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr className={classes.header} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <MantineTable.Th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        minWidth: `${columnWithSize[header.id].minSize}px`,
                        maxWidth: `${columnWithSize[header.id].maxSize}px`,
                      },
                    }}
                  >
                    <Box display="flex" sx={{ flexDirection: 'column' }}>
                      <Box
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: 'none',
                              }
                            : {},
                          className: classes.headerText,
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <IconArrowUp style={{ marginLeft: '.375rem' }} />,
                          desc: <IconArrowDown style={{ marginLeft: '.375rem' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    </Box>
                  </MantineTable.Th>
                ))}
                {onRowActionClick && <th></th>}
              </tr>
            ))}
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {getRowModel().rows.map((row) => {
              return (
                <MantineTable.Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <MantineTable.Td
                        key={cell.id}
                        className={classes.text}
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </MantineTable.Td>
                    )
                  })}
                  {onRowActionClick && (
                    <MantineTable.Td
                      style={{
                        verticalAlign: 'middle',
                        cursor: 'pointer',
                      }}
                      onClick={() => onRowActionClick(row.original)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IconPencil />
                      </Box>
                    </MantineTable.Td>
                  )}
                </MantineTable.Tr>
              )
            })}
          </MantineTable.Tbody>
        </MantineTable>
      </Box>
      {!props.hidePagination && (
        <Box className={classes.pagination}>
          <Box className={cx(classes.footerBox, 'start')}>
            <Box className={classes.footerBox}>
              <Text className={classes.footerText}>{`${getRowPosition(true, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('Common.To')}
              </Text>
              <Text ml={3} className={classes.footerText}>{`${getRowPosition(false, getState(), filteredRows().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('Common.Of')}
              </Text>
              <Text ml={3} className={classes.footerText}>
                {filteredRows().rows.length}
              </Text>
            </Box>
            <Box>
              <Select
                size="sm"
                value={getState().pagination.pageSize.toString()}
                data={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                ]}
                onChange={(e) => setPageSize(Number(e))}
              />
            </Box>
          </Box>
          <Box className={classes.footerBox}>
            <Text
              className={cx(classes.footerText, classes.footerTextButton, getCanPreviousPage() ? 'active' : 'inactive')}
              sx={getCanPreviousPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanPreviousPage()) return
                previousPage()
              }}
            >
              {t('Common.Previous')}
            </Text>
            <Text
              ml={21}
              className={cx(classes.footerText, classes.footerTextButton, getCanNextPage() ? 'active' : 'inactive')}
              sx={getCanNextPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanNextPage()) return
                nextPage()
              }}
            >
              {t('Common.Next')}
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

function getRowPosition(firstRow: boolean, tableState: TableState, totalRowCount: number) {
  const {
    pagination: { pageIndex, pageSize },
  } = tableState

  const index = +pageIndex + 1

  if (index === 1) {
    if (totalRowCount === 0) {
      return 0
    }

    return firstRow ? 1 : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
  }

  return firstRow ? pageIndex * pageSize : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
}
