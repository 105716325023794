import { Box, Button, ColorInput, Group, Modal, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { GroupDto, SummaryTakeoffGroup } from 'api/dto'
import { getGroupType } from 'canvas/shape/types/utils'
import { ShapeTypes } from 'canvas/types'
import { useTranslation } from 'react-i18next'
import { convertManualRepresentation, convertToFactor, ManualRepresentation } from 'utils/measure'
import { MeasureField } from './group/measure-field'
import { showNotification } from '@mantine/notifications'
import { useUpdateGroup } from 'api/query/group'
import { useParams } from 'react-router-dom'
import { takeoffStore } from 'store/store'
import { useStore } from 'zustand'

interface IGroupSettingsModalProps {
  opened: boolean
  toggle: () => void
  group?: SummaryTakeoffGroup
  deleteGroup: () => void
  add: (group: SummaryTakeoffGroup) => void
}

interface FormProps {
  name?: string
  color?: string
  factor?: number
}

export function EditGroup({ opened, toggle, group, deleteGroup, add }: IGroupSettingsModalProps) {
  const { pageId, projectId, layerId } = useParams()
  const { t } = useTranslation()
  const { updateGroup, recalculateGroup } = useStore(takeoffStore, (state) => ({
    updateGroup: state.updateGroup,
    recalculateGroup: state.recalculateGroup,
  }))
  const { mutateAsync: updateTakeoffGroup } = useUpdateGroup()
  const parsedDepth = group?.depth ? JSON.parse(group?.depth) : undefined
  const parsedThickness = group?.thickness ? JSON.parse(group?.thickness) : undefined
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmittingDelete, setIsSubmittingDelete] = useState(false)
  const [depth, setDepth] = useState<ManualRepresentation>({})

  const [thickness, setThickness] = useState<ManualRepresentation>({})

  const groupType = getGroupType(group?.type)
  const form = useForm<FormProps>({
    initialValues: {
      name: group?.name,
      color: group?.color,
      factor: group?.factor || 0,
    },
  })

  useEffect(() => {
    if (opened) {
      form.setValues({
        name: group?.name || '',
        color: group?.color || '',
        factor: group?.factor || 0,
      })

      setDepth({
        first: parsedDepth?.first?.split(' ')[0],
        second: parsedDepth?.second?.split(' ')[0],
        fraction: {
          num: parsedDepth?.fraction?.num || 0,
          den: parsedDepth?.fraction?.den || 0,
        },
      })
      setThickness({
        first: parsedThickness?.first?.split(' ')[0],
        second: parsedThickness?.second?.split(' ')[0],
        fraction: {
          num: parsedThickness?.fraction?.num || 0,
          den: parsedThickness?.fraction?.den || 0,
        },
      })
    } else {
      form.reset()
      setDepth({})
      setThickness({})
    }
    // we dont want to trigger a setValues everytime the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, opened])

  const formSubmit = useCallback(
    async (id: string, values: FormProps) => {
      setIsSubmitting(true)
      const currentGroup = { id, ...values }

      const repDepth = convertManualRepresentation(depth)
      const repThickness = convertManualRepresentation(thickness)

      const depthFactor = convertToFactor(repDepth)
      const thicknessFactor = convertToFactor(repThickness)

      if (group) {
        updateGroup({
          ...group,
          ...currentGroup,
          depth: depthFactor > 0 ? repDepth : undefined,
          thickness: thicknessFactor > 0 ? repThickness : undefined,
        })
        recalculateGroup(currentGroup.id)

        await updateTakeoffGroup({
          projectId: projectId!,
          layerId: layerId!,
          pageId: pageId!,
          group: {
            id,
            ...values,
            depth: depthFactor > 0 ? repDepth : undefined,
            thickness: thicknessFactor > 0 ? repThickness : undefined,
          } as GroupDto,
        })
      }

      showNotification({
        title: t('Group.GroupUpdated'),
        message: t('Group.GroupUpdatedSuccessfully'),
        color: 'green',
      })

      toggle()
      setIsSubmitting(false)
    },
    [depth, thickness, group, t, toggle, updateGroup, recalculateGroup, updateTakeoffGroup, projectId, layerId, pageId],
  )

  if (!group) {
    return null
  }

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          Group Settings
        </Text>
      }
    >
      <form
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        onSubmit={form.onSubmit((values) => formSubmit(group.id, values))}
      >
        <TextInput
          mt={16}
          sx={{ flex: 1 }}
          label="Name"
          placeholder="Enter a group name"
          type="text"
          {...form.getInputProps('name')}
          error={undefined}
        />

        <ColorInput mt={16} size="sm" radius="xs" label="Color" placeholder="Enter a color" {...form.getInputProps('color')} />

        {groupType !== ShapeTypes.Count && (
          <>
            <MeasureField
              name={groupType === ShapeTypes.Area ? t('Group.Depth') : t('Group.WallHeight')}
              type={groupType as ShapeTypes}
              value={depth}
              setValue={setDepth}
            />

            {groupType !== ShapeTypes.Area && (
              <MeasureField name={t('Group.WallThickness')} type={groupType as ShapeTypes} value={thickness} setValue={setThickness} />
            )}
          </>
        )}

        <Box display="flex" mt="40px" sx={{ justifyContent: 'space-between' }}>
          <Button
            disabled={isSubmitting || isSubmittingDelete}
            loading={isSubmittingDelete}
            size="xs"
            radius="xxs"
            variant="outline"
            color="red"
            onClick={() => {
              setIsSubmittingDelete(true)
              deleteGroup()
              setIsSubmittingDelete(false)
            }}
          >
            {t('Common.Delete')}
          </Button>
          <Group>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              size="xs"
              radius="xxs"
              variant="outline"
              color="blue"
              onClick={() => add(group)}
            >
              {t('Group.AddTakeoff')}
            </Button>
            <Button
              disabled={isSubmitting || isSubmittingDelete || !form.isValid()}
              loading={isSubmitting}
              size="xs"
              radius="xxs"
              type="submit"
              color="black"
            >
              {t('Common.Apply')}
            </Button>
          </Group>
        </Box>
      </form>
    </Modal>
  )
}
