/* eslint-disable @typescript-eslint/naming-convention */
import { Box, SimpleGrid, Tabs, Text } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { Project, ProjectView } from 'api/dto'
import { useGetMeUser } from 'api/query/user'
import { Loading } from 'components/loading/loading'
import { AddFile } from 'components/modals/add-file.modal'
import { AddProject } from 'components/modals/add-project.modal'
import { EditProject } from 'components/modals/edit-project.modal'
import { ShareProjectModal } from 'components/modals/share-project.modal'
import { ProjectTile } from 'components/tile/project-tile'
import { PageTitle } from 'components/title/page-title'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useProjectsStyles = createStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export function Projects({ view, isLoading, projects }: { view: ProjectView; isLoading: boolean; projects: Project[] }) {
  const { data: user } = useGetMeUser()
  const { classes } = useProjectsStyles()
  const { t } = useTranslation()
  const [project, setProject] = useState<Project | undefined>()
  const [addFileModalOpened, setAddFileModalOpened] = useState(false)
  const [shareProjectModalOpened, setShareProjectModalOpened] = useState(false)
  const [editProjectModalOpened, setEditProjectModalOpened] = useState(false)

  const openEditModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setEditProjectModalOpened(true)
        return project
      })
    },
    [setProject, setEditProjectModalOpened],
  )

  const openShareModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setShareProjectModalOpened(true)
        return project
      })
    },
    [setProject, setShareProjectModalOpened],
  )

  const openAddFileModal = useCallback(
    (project: Project) => {
      setProject(() => {
        setAddFileModalOpened(true)
        return project
      })
    },
    [setProject, setAddFileModalOpened],
  )

  if (isLoading) {
    return <Loading height="100%" />
  }

  return (
    <Box>
      <Box className={classes.title}>
        <PageTitle
          title={
            view === ProjectView.MY_PROJECTS
              ? t('Pages.Title.MyProjects')
              : view === ProjectView.ORGANIZATION
                ? t('Pages.Title.OrganizationProjects')
                : t('Pages.Title.SharedProjects')
          }
        />
        {view != ProjectView.SHARED && (
          <AddProject view={view} organizationId={view === ProjectView.ORGANIZATION ? user?.currentOrgId : undefined} />
        )}
      </Box>
      <Box mt={40}>
        <Tabs defaultValue="all">
          <Tabs.List>
            <Tabs.Tab value="all">
              <Text size="md">
                {t('Tabs.Projects')}
                {` (${projects.length})`}
              </Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="all">
            <SimpleGrid
              sx={{
                marginTop: '40px',
              }}
              cols={{
                base: 1,
                xs: 2,
                sm: 3,
                md: 3,
                xl: 4,
              }}
              spacing="sm"
            >
              {projects.map((p, i) => (
                <Box mr={10}>
                  <ProjectTile
                    index={i}
                    total={projects.length}
                    item={p}
                    openAddFileModal={openAddFileModal}
                    openShareModal={openShareModal}
                    openEditModal={openEditModal}
                    viewType={view}
                  />
                </Box>
              ))}
            </SimpleGrid>
          </Tabs.Panel>
        </Tabs>
        <AddFile projectId={project?.id} opened={addFileModalOpened} toggle={() => setAddFileModalOpened(false)} />
        <ShareProjectModal project={project} open={shareProjectModalOpened} toggle={() => setShareProjectModalOpened(false)} />
        <EditProject project={project} open={editProjectModalOpened} toggle={() => setEditProjectModalOpened(false)} />
      </Box>
    </Box>
  )
}
