import { StateCreator } from 'zustand/vanilla'
import { GroupCanvas } from 'canvas/group'
import { IGroupCanvas, ShapeTypes } from 'canvas/types'
import { isMeasure, isScale, isShapeWithAnchors } from 'canvas/shape/types/utils'
import { Axis } from 'utils/scale/scale'
import { Scale } from 'canvas/shape/types/scale'
import { GroupService } from 'api/services/group-service'
import { isValidUUID } from 'utils/uuid'

export interface GroupSlice {
  groups: GroupCanvas[]
  selectedGroup?: GroupCanvas
  setSelectedGroup: (group: GroupCanvas | undefined) => void
  addGroup: (group: GroupCanvas, withSelect?: boolean) => void
  getGroup: (groupId: string) => GroupCanvas | undefined
  setGroups: (groups: GroupCanvas[]) => void
  recalculateGroup: (groupId: string) => void
  updateGroup: (group: Omit<IGroupCanvas, 'type'>) => void
  updateGroupVisibility: (groupId: string, visibility: boolean) => void
  saveGroup: (groupId: string) => void
  spreadNewGroupId: (oldGroupId: string, groupId: string) => void

  findGroupById: (groupId: string) => GroupCanvas | undefined
  findScaleGroup: (axis: Axis) => GroupCanvas | undefined

  deleteGroup: (groupId: string, withoutDelete?: boolean) => void
  deleteShape: (groupId: string, shapeId: string) => void
  deletePoint: (groupId: string, shapeId: string, pointId: string) => void
}

export const createGroupSlice: StateCreator<GroupSlice, [], [], GroupSlice> = (set, get) => ({
  groups: [],
  addGroup: (group: GroupCanvas, withSelect = false) =>
    set((state) => ({ groups: [...state.groups, group], selectedGroup: withSelect ? group : get().selectedGroup })),
  setSelectedGroup: (group: GroupCanvas | undefined) => set(() => ({ selectedGroup: group })),
  setGroups: (groups: GroupCanvas[]) => set(() => ({ groups })),
  getGroup: (groupId: string) => get().groups.find((g) => g.id === groupId),
  findScaleGroup: (axis: Axis) => {
    const groups = get().groups.filter((g) => Number(g.type) == ShapeTypes.Scale)
    let foundGroup: GroupCanvas | undefined
    groups.forEach((g) => {
      g.shapes.forEach((s) => {
        if ((s as Scale).axis === axis) {
          if (!foundGroup) {
            foundGroup = g
          }
        }
      })
    })

    return foundGroup
  },
  findGroupById: (groupId: string) => {
    const groups = get().groups
    return groups.find((g) => g.id === groupId)
  },
  recalculateGroup: (groupId: string) =>
    set((state) => {
      const groups = [...state.groups]
      const index = groups.findIndex((g) => g.id === groupId)

      if (index === -1) {
        return state
      }

      const newGroup = groups[index]
      newGroup.calculate()

      return {
        groups,
      }
    }),
  updateGroup: (group: Omit<IGroupCanvas, 'type'>) =>
    set((state) => {
      const groups = [...state.groups]
      const index = groups.findIndex((g) => g.id === group.id)

      if (index === -1) {
        return state
      }

      const currentGroup = groups[index]
      if (group.color) {
        currentGroup.updateColor(group.color)
      }
      if (group.name) {
        currentGroup.name = group.name
      }
      currentGroup.thickness = group.thickness
      currentGroup.factor = group.factor
      currentGroup.depth = group.depth
      currentGroup.productId = group.productId

      groups[index] = currentGroup

      return {
        groups,
      }
    }),

  updateGroupVisibility: (groupId: string, visibility: boolean) =>
    set((state) => {
      const groups = [...state.groups]
      const index = groups.findIndex((g) => g.id === groupId)

      if (index === -1) {
        return state
      }

      const currentGroup = groups[index]

      currentGroup.isVisible = visibility

      groups[index] = currentGroup

      return {
        groups,
      }
    }),
  saveGroup: (groupId: string) => {
    const groups = get().groups
    const index = groups.findIndex((g) => g.id === groupId)

    if (index === -1) {
      return
    }

    const newGroup = groups[index]

    if (isValidUUID(newGroup.id)) {
      void GroupService.updateGroup(newGroup.id, newGroup.toUpdateDto())
    }
  },
  spreadNewGroupId: (oldGroupId: string, groupId: string) =>
    set((state) => {
      const groups = [...state.groups]

      const indexOld = groups.findIndex((g) => g.id === oldGroupId)

      if (indexOld === -1) {
        return state
      }

      const group = groups[indexOld]

      group.id = groupId

      group.shapes.forEach((s) => {
        if (isShapeWithAnchors(s)) {
          s.groupId = groupId
          s.anchors.forEach((a) => a.setGroupId(groupId))
        }
      })

      groups[indexOld] = group

      return {
        groups,
      }
    }),
  deleteGroup: (groupId: string, withoutDelete?: boolean) =>
    set((state) => {
      const groups = [...state.groups]

      const index = groups.findIndex((i) => i.id === groupId)

      if (index === -1) {
        return state
      }

      const group = groups[index]
      group.removeShapes()
      groups.splice(index, 1)

      if (group.isSavedGroup && !withoutDelete) {
        void GroupService.deleteGroup(groupId)
      }

      return {
        groups,
      }
    }),
  deleteShape: (groupId: string, shapeId: string) =>
    set((state) => {
      const groups = [...state.groups]

      const groupIndex = groups.findIndex((i) => i.id === groupId)

      if (groupIndex === -1) {
        return state
      }

      const group = groups[groupIndex]

      const shapeIndex = group.shapes.findIndex((s) => s.id === shapeId)

      if (shapeIndex === -1) {
        return state
      }

      group.removeShape(shapeIndex, true)

      return {
        groups,
      }
    }),
  deletePoint: (groupId: string, shapeId: string, pointId: string) =>
    set((state) => {
      const groups = [...state.groups]

      const groupIndex = groups.findIndex((i) => i.id === groupId)

      if (groupIndex === -1) {
        return state
      }

      const group = groups[groupIndex]

      const shapeIndex = group.shapes.findIndex((s) => s.id === shapeId)

      if (shapeIndex === -1) {
        return state
      }

      const shape = group.shapes[shapeIndex]

      const pointIndex = shape.points.findIndex((i) => i.id === pointId)

      if (pointIndex === -1) {
        return state
      }

      if (!isMeasure(shape) && !isScale(shape)) {
        shape.deletePoint(pointId)
      }

      return {
        groups,
      }
    }),
})
