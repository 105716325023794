/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useMemo, useEffect } from 'react'
import { ActionIcon, Alert, AppShell, Box, Select, Text, TextInput } from '@mantine/core'
import { createStyles, getStylesRef } from '@mantine/emotion'
import { GroupCanvas, GroupCanvas as TakeoffGroup } from 'canvas/group'
import { Page, Project, SummaryTakeoffGroup } from 'api/dto'
import { getGroupType } from 'canvas/shape/types/utils'
import { ShapeTypes } from 'canvas/types'
import { useDrawing } from 'hooks/useDrawing'
import { useTranslation } from 'react-i18next'
import { IconEye, IconEyeOff, IconPencil, IconInfoCircle } from '@tabler/icons-react'
import { EditGroup } from 'components/modals/edit-group.modal'
import { Minimize } from './minimize'
import { takeoffStore } from 'store/store'
import { useKeyPress } from 'hooks/useKeyPress'
import PencilIcon from 'assets/icons/pencil.svg?react'
import { useHover } from '@mantine/hooks'
import { DragAndDropTable } from 'components/table/dnd-table'
import { useStore } from 'zustand'
import { getShapeIcon } from 'utils/icon'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPage, useUpdatePage } from 'api/query/page'
import { Loading } from 'components/loading/loading'
import { ColumnDef } from '@tanstack/react-table'
import { useGetLayer } from 'api/query/layer'
import { CreateLayer } from 'components/modals/create-layer.modal'
import { useUpdateGroupsOrder } from 'api/query/group'
import { showNotification } from '@mantine/notifications'
import { useGetProject } from 'api/query/project'
import { GlobalFeature, TakeoffFeature } from 'api/dto/feature'
import { Layer } from 'api/dto/layer'

export function TakeoffSidebar() {
  const { t } = useTranslation()
  const { projectId, pageId, layerId } = useParams()
  const { data: project, isLoading: isLoadingProject } = useGetProject(projectId)
  const { data: page, isLoading: isLoadingPage } = useGetPage(projectId!, pageId!)
  const { data: layer, isLoading: isLoadingLayer } = useGetLayer(projectId!, pageId!, layerId!)
  const { groups, deleteGroup, select, renderer } = useStore(takeoffStore, (state) => ({
    groups: state.groups,
    deleteGroup: state.deleteGroup,
    select: state.select,
    renderer: state.renderer,
  }))
  const { onStart, onStop } = useDrawing()

  const escPressed = useKeyPress('Escape')
  const enterPressed = useKeyPress('Enter')
  const { hovered, ref } = useHover()

  const [name, setName] = useState(page?.name || '')
  const [selectedGroup, setSelectedGroup] = useState<(SummaryTakeoffGroup & { pageId: string }) | undefined>()
  const [editModalOpened, setEditModalOpened] = useState(false)
  const [createLayerModalOpened, setCreateLayerModalOpened] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [width, setWidth] = useState(30)
  const isMinimized = width === 30
  const { mutateAsync: updatePage } = useUpdatePage()

  const hasCreatedLayer = useMemo(() => (page?.layers?.length ?? 0) > 1, [page?.layers])

  const layers = useMemo(() => {
    const pageLayers = page?.layers.map((l) => ({ label: l.name, value: l.layerId })) || []
    if (project?.hasAccess(GlobalFeature.CREATE_LAYER)) {
      pageLayers.push({ label: t('Layer.AddLayer'), value: 'new' })
    }

    return pageLayers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, page?.layers, t])

  const navigate = useNavigate()

  const onDelete = () => {
    if (!selectedGroup) {
      return
    }

    deleteGroup(selectedGroup.id)
    setSelectedGroup(undefined)
    setEditModalOpened(false)
  }

  const onAdd = (g: SummaryTakeoffGroup) => {
    if (groups?.map((c) => c.id).includes(g.id)) {
      onStop()
      select(g.id)
      onStart(g.type, g)
    } else {
      onStart(g.type, g)
    }

    setEditModalOpened(false)
  }

  useEffect(() => {
    if (!isEditing) {
      return
    }

    if (escPressed || enterPressed) {
      setIsEditing(false)
      void updatePage({ projectId: projectId!, page: { ...page!, name } })
    }
  }, [isEditing, updatePage, page, projectId, name, escPressed, enterPressed])

  if (!page || !layer) {
    return null
  }

  return (
    <AppShell.Aside
      w={{ base: width }}
      sx={{
        transition: 'width .3s',
      }}
    >
      {(isLoadingProject || isLoadingPage || isLoadingLayer) && <Loading />}
      {!isLoadingProject && !isLoadingPage && !isLoadingLayer && (
        <Box sx={{ height: '100%', position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: '50%', left: 0, zIndex: 1 }}>
            <Minimize
              direction={width === 30 ? 'left' : 'right'}
              onClick={() => {
                setWidth(width === 30 ? 500 : 30)
                setTimeout(() => renderer?.fitStageIntoParentContainer(), 500)
              }}
            />
          </Box>
          {!isMinimized && (
            <AppShell.Section
              p={30}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% - 50px)',
              }}
            >
              <Box
                mb={16}
                display="flex"
                sx={{
                  flexDirection: 'column',
                }}
              >
                {!isEditing && (
                  <Box display="flex" sx={{ alignItems: 'center' }}>
                    <Text
                      ref={ref}
                      fz={25}
                      fw={600}
                      sx={
                        project?.hasAccess(GlobalFeature.EDIT_PAGE)
                          ? {
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              textDecorationStyle: 'dotted',
                            }
                          : undefined
                      }
                      onClick={() => project?.hasAccess(GlobalFeature.EDIT_PAGE) && setIsEditing(true)}
                    >
                      {page.name}
                    </Text>
                    {hovered && project?.hasAccess(GlobalFeature.EDIT_PAGE) && (
                      <PencilIcon width={15} height={15} style={{ marginLeft: '10px' }} />
                    )}
                  </Box>
                )}
                {isEditing && (
                  <Box>
                    <TextInput
                      size="sm"
                      variant="outline"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                      onBlur={async (e) => {
                        setIsEditing(false)
                        await updatePage({ projectId: projectId!, page: { ...page, name } })
                      }}
                    >
                      {page.name}
                    </TextInput>
                  </Box>
                )}
                <Select
                  onChange={(e) => {
                    if (!e) {
                      return
                    }

                    if (e === 'new') {
                      setCreateLayerModalOpened(true)
                      return
                    }

                    navigate(`../${e}`, { relative: 'path' })
                  }}
                  value={layerId}
                  mt={10}
                  maw={300}
                  data={layers}
                />
              </Box>

              {project?.hasOnlyEditorAccess() && !hasCreatedLayer ? (
                <Box>
                  <Alert color="blue">
                    <Text size="sm">{t('Page.LayerInfo')}</Text>
                  </Alert>
                </Box>
              ) : (
                <>
                  {!layer.hasGroupsWithCalculations && (
                    <Box>
                      <Alert icon={<IconInfoCircle size="1rem" />} color="yellow">
                        <Text size="sm">{t('Page.NoTakeoff')}</Text>
                        <Text size="sm">{t('Page.AddScaleFirst')}</Text>
                      </Alert>
                    </Box>
                  )}

                  {layer.hasGroupsWithCalculations && (
                    <Box
                      sx={{
                        overflowY: 'scroll',
                        flex: 1,
                      }}
                    >
                      <PageTakeoff
                        key={page.id}
                        layer={layer}
                        editGroup={(grp) => {
                          setSelectedGroup(grp as any)
                          setEditModalOpened(true)
                        }}
                        groups={groups}
                      />
                    </Box>
                  )}
                </>
              )}
            </AppShell.Section>
          )}
        </Box>
      )}
      {project?.hasEditorAccess() && (
        <EditGroup
          opened={editModalOpened}
          toggle={() => setEditModalOpened((val) => !val)}
          group={selectedGroup}
          deleteGroup={onDelete}
          add={onAdd}
        />
      )}

      <CreateLayer opened={createLayerModalOpened} toggle={() => setCreateLayerModalOpened(false)} />
    </AppShell.Aside>
  )
}

const usePageItemStyle = createStyles((theme, _) => ({
  container: {
    padding: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '.625rem',

    borderRadius: '.625rem',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':last-of-type': {
      margin: 0,
    },
  },
  cell: {
    fontSize: '14px !important',
    letterSpacing: 0.5,
    wordBreak: 'break-word',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  pageActive: {
    ref: getStylesRef('pageActive'),
    backgroundColor: theme.colors.dark[6],
    color: theme.white,

    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':hover': {
      color: theme.colors.dark[8],
    },
  },
  group: {
    cursor: 'pointer',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  groupActive: {
    backgroundColor: theme.colors.gray[3],
  },
}))

interface PageProps {
  layer: Layer
  editGroup: (grp: SummaryTakeoffGroup) => void
  groups?: TakeoffGroup[]
}

function PageTakeoff({ layer, groups, editGroup }: PageProps) {
  const { projectId, pageId, layerId } = useParams()
  const { classes } = usePageItemStyle()
  const { t } = useTranslation()

  const { mutateAsync: updateGroupsOrder } = useUpdateGroupsOrder()

  const { setGroups, pulsate, select, selectedGroup, changeVisibility } = useStore(takeoffStore, (state) => ({
    setGroups: state.setGroups,
    pulsate: state.pulsate,
    changeVisibility: state.changeVisibility,
    select: state.select,
    selectedGroup: state.selectedGroup,
  }))

  const updateGroupsOrderOnDrop = async (groups: GroupCanvas[]) => {
    try {
      const newGroups: GroupCanvas[] = groups.map((g, i) => {
        if (g.order == -1) {
          return g
        }

        g.order = i
        return g
      })

      setGroups(newGroups)

      await updateGroupsOrder({
        projectId: projectId!,
        pageId: pageId!,
        layerId: layerId!,
        groupIds: groups.map((g) => g.id),
      })

      showNotification({
        message: t('Takeoff.GroupsOrderUpdated'),
        color: 'green',
      })
    } catch (error) {
      showNotification({
        title: t('Takeoff.GroupsOrderUpdateError'),
        message: t('Takeoff.GroupsOrderUpdateErrorMessage'),
        color: 'red',
      })
    }
  }

  const columns = useMemo(
    () =>
      [
        {
          header: t('Takeoff.Name'),
          accessorKey: 'name',
          cell: (summary) => {
            const g = summary.row.original
            return (
              <Box ml={10} display="flex" sx={{ alignItems: 'center', svg: { fill: g.color } }}>
                {getShapeIcon(g.type, g.color)}
                <Text ml={7} fz={12} fw={400} sx={{ color: g.color }}>
                  {g.name}
                </Text>
              </Box>
            )
          },
        },
        {
          header: t('Takeoff.Linear'),
          accessorKey: 'perimeter',
          cell: ({ getValue }) => getValue() || '-',
          width: '15%',
        },
        {
          header: t('Takeoff.Area'),
          accessorKey: 'area',
          cell: ({ getValue }) => getValue() || '-',
          width: '15%',
        },
        {
          header: t('Takeoff.Volume'),
          accessorKey: 'volume',
          cell: ({ getValue }) => getValue() || '-',
          width: '15%',
        },
        {
          header: t('Takeoff.Count'),
          accessorKey: 'count',
          cell: ({ getValue }) => getValue() || '-',
          width: '10%',
        },
        {
          header: t('Takeoff.Actions'),
          accessorKey: 'actions',
          width: '10%',
          cell: ({ row: { original: g } }) => (
            <Box className={classes.actions}>
              <ActionIcon
                onClick={(e: any) => {
                  e.preventDefault()
                  e.stopPropagation()

                  editGroup(g)
                }}
                disabled={!layer.hasAccess(TakeoffFeature.SIDEBAR_EDIT)}
                variant="transparent"
                color="dark"
                size="sm"
                radius="xxs"
              >
                <IconPencil />
              </ActionIcon>
              <ActionIcon
                onClick={(e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  changeVisibility(g.id)
                }}
                ml={4}
                variant="transparent"
                color="dark"
                size="sm"
                radius="xxs"
              >
                {g.isVisible ? <IconEye /> : <IconEyeOff />}
              </ActionIcon>
            </Box>
          ),
        },
      ] as ColumnDef<SummaryTakeoffGroup>[],
    [t, classes, editGroup, changeVisibility],
  )

  const filteredGroups = useMemo(
    () => groups?.filter((g) => getGroupType(g.type) !== ShapeTypes.Scale && getGroupType(g.type) !== ShapeTypes.Measure) || [],
    [groups],
  )

  const convertedGroups = useMemo(() => {
    const sortedGroups = filteredGroups.sort((a, b) => a.order - b.order)
    return sortedGroups.map((g) => new SummaryTakeoffGroup(g))
  }, [filteredGroups])

  return (
    <DragAndDropTable
      columns={columns}
      data={convertedGroups || []}
      untaintedData={filteredGroups || []}
      selectedRowId={selectedGroup?.id}
      onRowClick={(row) => {
        select(row.id)
        pulsate(row.id)
      }}
      setData={updateGroupsOrderOnDrop}
    />
  )
}
