import { useMutation, useQuery } from '@tanstack/react-query'
import { IPermission, Permission, PermissionDto } from 'api/dto/permission'
import environment from 'config/environment'
import axios from 'config/axios'
import { globalQueryClient } from 'api/client'
import { useCallback } from 'react'

export const useListPermission = (projectId?: string) => {
  return useQuery({
    queryKey: ['projects', projectId, 'permissions'],
    queryFn: async () => {
      const { data } = await axios.get<PermissionDto[]>(environment.restApi.resourceUrl.projects + `/${projectId}/permissions`)
      return data
    },
    select: useCallback((response) => response.map((u) => new Permission(u)), []),
    enabled: !!projectId,
  })
}

export const useCreatePermission = () => {
  return useMutation({
    mutationFn: (permission: IPermission) => {
      return axios.post(environment.restApi.resourceUrl.projects + `/${permission.projectId}/permissions`, permission)
    },
    onSuccess: async (_, { projectId }) => {
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', projectId, 'permissions'] })
    },
  })
}

export const useUpdatePermission = () => {
  return useMutation({
    mutationFn: (permission: IPermission) => {
      const { fullName, ...rest } = permission
      return axios.put(environment.restApi.resourceUrl.projects + `/${permission.projectId}/permissions/${permission.id}`, rest)
    },
    onMutate: (permission) => {
      const newData = globalQueryClient.setQueryData<PermissionDto[]>(['projects', permission.projectId, 'permissions'], (oldData) => {
        if (!oldData) return oldData
        return oldData.map((p) => (p.id === permission.id ? permission : p))
      })

      return { newData }
    },
    onSuccess: async (_, { projectId }) => {
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', projectId, 'permissions'] })
    },
  })
}

export const useDeletePermission = () => {
  return useMutation({
    mutationFn: (permission: Permission) => {
      return axios.delete(environment.restApi.resourceUrl.projects + `/${permission.projectId}/permissions/${permission.id}`, {
        data: { userId: permission.userId, email: permission.email },
      })
    },
    onSuccess: async (_, { projectId }) => {
      await globalQueryClient.invalidateQueries({ queryKey: ['projects', projectId, 'permissions'] })
    },
  })
}
