import { Button, Center, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'

export function Oops() {
  const navigate = useNavigate()
  return (
    <Center display="flex" sx={{ flexDirection: 'column' }}>
      <Text size="lg">Oops! Something went wrong. Please try again later.</Text>
      <Button mt={20} onClick={() => navigate('/dashboard')}>
        <IconArrowLeft /> Go back to Dashboard
      </Button>
    </Center>
  )
}
