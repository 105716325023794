import { ActionIcon } from '@mantine/core'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'

interface MinimizeProps {
  direction: 'left' | 'right'
  onClick: () => void
}

export function Minimize({ direction, onClick }: MinimizeProps) {
  return (
    <ActionIcon radius="xs" variant="filled" onClick={onClick}>
      {direction === 'left' ? <IconChevronLeft size={20} /> : <IconChevronRight size={20} />}
    </ActionIcon>
  )
}
