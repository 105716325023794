import { globalQueryClient } from 'api/client'
import { TakeoffFeature } from 'api/dto/feature'
import { Layer, LayerDto } from 'api/dto/layer'
import { takeoffStore } from 'store/store'

export function hasAccess(feature: TakeoffFeature) {
  const { projectId, pageId, layerId } = takeoffStore.getState().config
  const layerDto = globalQueryClient.getQueryData<LayerDto>(['projects', projectId, 'pages', pageId, 'layers', layerId])

  const layer = layerDto ? new Layer(layerDto) : null

  return layer?.hasAccess(feature)
}
