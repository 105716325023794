/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useSearchParams } from 'react-router-dom'

const MAX_POLLING = 100
const MIN_POLLED_ON_ADD = 5
const DEFAULT_INTERVAL = 2500

type Params<T> = UndefinedInitialDataOptions<T, Error, any, string[]> & {
  hasToFetchAfterAdd?: boolean
  successCondition: (data: any) => boolean
}

export const usePollingQuery = <T>(params: Params<T>) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { hasToFetchAfterAdd, successCondition, ...rest } = params
  const [numberOfTimePolled, setNumberOfTimePolled] = useState(0)
  const [getInitialData, setGetInitialData] = useState(true)
  const [startedPolling, setStartPolling] = useState(false)
  const [canceledPolling, setCancelPolling] = useState(false)

  const result = useQuery({
    ...rest,
    enabled: startedPolling,
  })

  const { refetch, isLoading, isFetching } = result

  useEffect(() => {
    if (getInitialData) {
      void refetch()
      setGetInitialData(false)
    }
  }, [getInitialData, refetch])

  useEffect(() => {
    if (searchParams.get('polling') === 'true' && !startedPolling) {
      setStartPolling(true)
      setCancelPolling(false)
      setNumberOfTimePolled(0)
    }
  }, [searchParams, startedPolling, setStartPolling, setCancelPolling, setNumberOfTimePolled])

  useEffect(() => {
    let id: NodeJS.Timeout | undefined = undefined

    if (!startedPolling && !canceledPolling) {
      return
    }

    if (isLoading || isFetching) {
      return
    }

    if (startedPolling && !canceledPolling) {
      id = setTimeout(async () => {
        const res = await refetch()

        if (numberOfTimePolled < MIN_POLLED_ON_ADD) {
          setNumberOfTimePolled((old) => ++old)
          return
        }

        if (successCondition(res.data)) {
          setCancelPolling(true)
        }

        setNumberOfTimePolled((old) => ++old)
      }, DEFAULT_INTERVAL)
    }

    if (canceledPolling || numberOfTimePolled >= MAX_POLLING) {
      setNumberOfTimePolled(0)
      setStartPolling(false)
      setCancelPolling(false)
      searchParams.delete('polling')
      setSearchParams(searchParams)
      return () => clearTimeout(id)
    }
  }, [startedPolling, numberOfTimePolled, canceledPolling, refetch, successCondition, isLoading, isFetching, setSearchParams, searchParams])

  return { ...result, numberOfTimePolled, startedPolling, canceledPolling, startPolling: () => setStartPolling(true) }
}
