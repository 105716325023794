import { Box, Button, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export function PaymentSuccess() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Box sx={{ width: '100%', marginTop: 30 }}>
      <Box mb={50} sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Text mr={20} ta="end" fw={700} sx={{ width: '70%' }} size="xl">
          You successfully subscribe to TouchTakeoff
        </Text>
      </Box>

      <Box>
        <Text mb={20} size="xl"></Text>
        <Text ta="center">Please login with your account credentials</Text>
      </Box>

      <Box
        mt={40}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button onClick={() => navigate('/login')}>Login now</Button>
      </Box>
    </Box>
  )
}
