import { ModalTypes } from 'components/modals/types'
import { StateCreator } from 'zustand'

export interface ModalSlice {
  modalTypeOpened: ModalTypes
  toggleModal: (modalType: ModalTypes) => void
}

export const createModalSlice: StateCreator<ModalSlice, [], [], ModalSlice> = (set) => ({
  modalTypeOpened: ModalTypes.None,
  toggleModal: (type) => set(() => ({ modalTypeOpened: type })),
})
