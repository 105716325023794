import { AppShell, Box } from '@mantine/core'
import { Outlet } from 'react-router-dom'

export const AuthLayout = () => {
  return (
    <AppShell
      h="100vh"
      styles={(theme) => ({
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexFlow: 'column',
          padding: 0,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.white,
          borderRadius: theme.radius.md,
          display: 'flex',

          width: '70%',
          height: '720px',
          maxWidth: '1200px',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        })}
      >
        <Outlet />
      </Box>
    </AppShell>
  )
}
