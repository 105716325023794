import { NUMBER_OF_DECIMAL } from 'canvas/shape/shape-utils'
import { Calculation, CalculationValue } from 'canvas/types'
import converter, { Unit } from 'convert-units'

export type MeasureType = 'volume' | 'area' | 'length'

export interface ManualRepresentation {
  first?: number | string
  second?: number | string
  fraction?: { num?: number; den?: number }
}

export function convertCalculationToUnit(value: string, unit: Unit, measureType: MeasureType) {
  switch (measureType) {
    case 'length':
      return Math.abs(converter(Number(value)).from('in').to(unit))
    case 'area':
      return Math.abs(converter(Number(value)).from('in2').to(unit))
    case 'volume':
      return Math.abs(converter(Number(value)).from('in3').to(unit))

    default:
      break
  }

  return 0
}

export function convertCalculation(value: string, unit: Unit, measureType: MeasureType) {
  switch (measureType) {
    case 'length':
      return `${Math.abs(converter(Number(value)).from('in').to(unit)).toFixed(NUMBER_OF_DECIMAL)} ${unit}`
    case 'area':
      return `${Math.abs(converter(Number(value)).from('in2').to(unit)).toFixed(NUMBER_OF_DECIMAL)} ${unit}`
    case 'volume':
      return `${Math.abs(converter(Number(value)).from('in3').to(unit)).toFixed(NUMBER_OF_DECIMAL)} ${unit}`

    default:
      break
  }

  return 'N/A'
}

export function convertToImperialCalculationValues(calculations: Calculation, groupUnit?: Unit): CalculationValue {
  const convertedCalculations: CalculationValue = {
    area: undefined,
    perimeter: undefined,
    count: undefined,
    volume: undefined,
  }
  if (calculations.area) Object.assign(convertedCalculations, { area: convertCalculationToUnit(calculations.area, 'ft2', 'area') })
  if (calculations.perimeter) {
    Object.assign(convertedCalculations, { perimeter: convertCalculationToUnit(calculations.perimeter, 'ft', 'length') })
  }
  if (calculations.count) Object.assign(convertedCalculations, { count: calculations.count })
  if (calculations.volume) Object.assign(convertedCalculations, { volume: convertCalculationToUnit(calculations.volume, 'yd3', 'volume') })
  return convertedCalculations
}

export function convertToImperialCalculations(calculations: Calculation, groupUnit?: Unit): Calculation {
  const convertedCalculations = {}
  if (calculations.area) Object.assign(convertedCalculations, { area: convertCalculation(calculations.area, 'ft2', 'area') })
  if (calculations.perimeter) {
    Object.assign(convertedCalculations, { perimeter: convertCalculation(calculations.perimeter, 'ft', 'length') })
  }
  if (calculations.count) Object.assign(convertedCalculations, { count: calculations.count })
  if (calculations.volume) Object.assign(convertedCalculations, { volume: convertCalculation(calculations.volume, 'yd3', 'volume') })

  return convertedCalculations
}

export function convertManualRepresentation(value: ManualRepresentation) {
  const rep = {
    first: value.first ? `${value.first} ft` : undefined,
    second: value.second ? `${value.second} in` : undefined,
    fraction: value.fraction && isFinite((value.fraction.num || 0) / (value.fraction.den || 0)) ? value.fraction : undefined,
  }

  return JSON.stringify(rep)
}

export function convertToFactor(representation: string) {
  const parsed = JSON.parse(representation)

  // METRIC
  const first = parsed.first
    ? converter(Number(parsed.first?.split(' ')[0]))
        .from('ft')
        .to('in')
    : 0
  const second = parsed.second ? Number(parsed.second?.split(' ')[0]) : 0
  let fraction = parsed.fraction ? Number(parsed.fraction.num) / Number(parsed.fraction.den) : 0

  if (!isFinite(fraction)) {
    fraction = 0
  }

  return first + second + fraction
}
