import './index.css'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import './config/i18n'
import { createRoot } from 'react-dom/client'
import { App } from './app'
import { MantineProvider } from '@mantine/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { Notifications } from '@mantine/notifications'
import { theme } from 'theme/theme'
import environment from 'config/environment'
import * as Sentry from '@sentry/react'
import * as Types from '@sentry/types'
import { isMobile } from 'react-device-detect'
import { Mobile } from 'pages/mobile'
import { globalQueryClient } from 'api/client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'

if (!environment.isLocal) {
  const integrations: Types.Integration[] = [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
    }),
  ]

  if (environment.isProd) {
    integrations.push(
      Sentry.replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    )
  }

  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations,
    debug: !environment.isProd,
    environment: environment.envName,
    tracesSampleRate: environment.isProd ? 0.4 : 0.7,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
    // eslint-disable-next-line max-len
    replaysSessionSampleRate: environment.isProd ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: ['localhost', /^https:\/\/api\.touchtakeoff\.online/],
  })
}

const container = document.getElementById('app')
const root = createRoot(container!)

if (isMobile) {
  root.render(
    <MantineProvider theme={theme} stylesTransform={emotionTransform}>
      <MantineEmotionProvider>
        <Mobile />
      </MantineEmotionProvider>
    </MantineProvider>,
  )
} else {
  root.render(
    <QueryClientProvider client={globalQueryClient}>
      <MantineProvider theme={theme} stylesTransform={emotionTransform}>
        <MantineEmotionProvider>
          <App />
          <Notifications />
        </MantineEmotionProvider>
      </MantineProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  )
}
