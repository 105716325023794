import { Button, Box, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserDto } from '../api/dto'
import { PageTitle } from 'components/title/page-title'
import { showNotification } from '@mantine/notifications'
import { useGetMeUser, useUpdateMeUser } from 'api/query/user'

interface FormValues {
  firstName?: string
  lastName?: string
}

export function UserSettings() {
  const { data: user, refetch } = useGetMeUser()
  const { mutateAsync } = useUpdateMeUser()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<FormValues>({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  })

  async function onSubmit(values: FormValues) {
    if (!user) {
      return
    }

    setIsLoading(true)
    await mutateAsync({ ...values, id: user.id } as UserDto)
    showNotification({
      title: t('User.UserUpdated'),
      message: t('User.UserUpdatedSuccessfully'),
      color: 'green',
    })

    await refetch()
    setIsLoading(false)
  }

  return (
    <>
      <Box mb={30}>
        <PageTitle title={t('User.Settings')} />
      </Box>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box display="flex" mb={30}>
          <TextInput
            withAsterisk
            label={t('User.FirstName')}
            placeholder={t('User.FirstName') || ''}
            {...form.getInputProps('firstName')}
          />
          <TextInput
            withAsterisk
            ml={20}
            label={t('User.LastName')}
            placeholder={t('User.LastName') || ''}
            {...form.getInputProps('lastName')}
          />
        </Box>

        <Button size="sm" radius="xxs" type="submit" loading={isLoading}>
          {t('Common.Update')}
        </Button>
      </form>
    </>
  )
}
