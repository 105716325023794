import { IPoint, IShape } from 'canvas/types'
import Konva from 'konva'
import { BaseShape } from './abstract-shape'
import { Anchor } from './anchor'
import { takeoffStore } from 'store/store'
import { hasAccess } from 'utils/features'
import { TakeoffFeature } from 'api/dto/feature'

export class Line extends BaseShape<Konva.Line> implements IShape {
  anchorGroup: Konva.Group
  anchors: Anchor[] = []

  #unsubscribe: () => void

  constructor(shape: IShape) {
    const currentPoints = shape.points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b), [])
    const currentInstance = new Konva.Line({
      points: currentPoints,
      stroke: shape.color,
      strokeWidth: shape.stroke?.width || 2,
    })

    super(shape, currentInstance)

    this.anchorGroup = new Konva.Group()

    this.instance.perfectDrawEnabled(false)

    this.instance.on('click', this.onMouseClick)
    this.instance.on('tap', this.onMouseClick)

    this.points.forEach((point) => {
      if (hasAccess(TakeoffFeature.TOOLBAR)) {
        this.#addAnchor(point)
      }
    })

    this.#unsubscribe = takeoffStore.subscribe((state) => {
      if (this.isSelected(state.selectedIds)) {
        this.instance.stroke('red')
      } else {
        this.instance.stroke(this.color)
      }
    })
  }

  addPoint(point: IPoint, index?: number) {
    const layer = this.instance.getLayer()
    if (layer == null) {
      return
    }

    if (index !== undefined) {
      this.points.splice(index, 0, point)
    } else {
      this.points.push(point)
    }

    const points = this.points || []

    this.instance.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.#addAnchor(point, index ?? this.points.length - 1)
  }

  deletePoint(pointId: string) {
    const points = [...this.points]
    const index = this.points.findIndex((p) => p.id === pointId)

    if (index === -1) {
      return
    }

    points.splice(index, 1)
    this.instance.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.points = points

    this.#removeAnchor(pointId)

    this.save()
  }

  movePoint(point: IPoint) {
    const points = [...this.points]
    const pointIndex = points.findIndex((p) => p.id === point.id)
    if (pointIndex === -1) {
      return
    }

    points[pointIndex] = point

    this.instance.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.points = points
  }

  remove(): void {
    this.instance.remove()
    this.instance.destroy()

    this.anchors.forEach((s) => s.remove())
    this.anchorGroup.remove()
    this.anchorGroup.destroy()

    this.#unsubscribe()
  }

  #addAnchor(point: IPoint, index?: number) {
    const anchor = new Anchor({
      shapeId: this.id,
      groupId: this.groupId,
      point,
      movePoint: (p: IPoint) => this.movePoint(p),
    })

    this.anchorGroup.add(anchor.instance)

    if (index) {
      this.anchors.splice(index, 0, anchor)
    } else {
      this.anchors.push(anchor)
    }
  }

  #removeAnchor(pointId: string) {
    const removedIndex = this.anchors.findIndex((t) => t.point.id === pointId)
    const toRemove = this.anchors[removedIndex]
    if (toRemove) {
      toRemove.remove()
      this.anchors.splice(removedIndex, 1)
    }
  }
}
