import { Box, Button, Text, Menu } from '@mantine/core'
import { SidebarLink } from 'components/nav/sidebar-link'
import { UserLink } from 'components/nav/user-link'
import { ColorScheme } from 'theme/theme'
import DashboardIcon from 'assets/icons/dashboard.svg?react'
import ProjectIcon from 'assets/icons/projects.svg?react'
import UsersIcon from 'assets/icons/users-alt.svg?react'
import OrganizationIcon from 'assets/icons/users-alt.svg?react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useGetMeUser, useSwitchOrganization } from 'api/query/user'
import { useStore } from 'zustand'
import { globalStore } from 'store/store'
import { ModalTypes } from 'components/modals/types'
import { createStyles } from '@mantine/emotion'
import { AppShell } from '@mantine/core'

interface SidebarProps {
  opened: boolean
  toggle: () => void
}

const useSidebarStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors[ColorScheme.customGray][2],

    svg: {
      width: '.875rem',
      height: '.875rem',
      path: {
        fill: theme.white,
      },
    },
  },
}))

export function Sidebar({ toggle }: SidebarProps) {
  const { user: currentUser, refreshSession } = useAuth()
  const { data: user, refetch } = useGetMeUser()
  const { toggleModal } = useStore(globalStore, (state) => ({ toggleModal: state.toggleModal }))
  const { mutateAsync: switchOrganization } = useSwitchOrganization()
  const { classes } = useSidebarStyles()
  const { t } = useTranslation()

  return (
    <AppShell.Navbar className={classes.container} pl="md" pt="md" pb="md">
      <AppShell.Section>
        <UserLink />
      </AppShell.Section>
      <AppShell.Section grow>
        <Box py="md" pl="sm">
          <SidebarLink toggle={toggle} name={t('Pages.Dashboard')} href="/app/dashboard" icon={<DashboardIcon />} />
          <SidebarLink toggle={toggle} name={t('Pages.Projects')} href="/app/projects" icon={<ProjectIcon />} />
          {currentUser?.currentOrgId && (
            <SidebarLink toggle={toggle} name={t('Pages.OrganizationProjects')} href="/app/organization-projects" icon={<ProjectIcon />} />
          )}
          <SidebarLink toggle={toggle} name={t('Pages.SharedProjects')} href="/app/shared-projects" icon={<ProjectIcon />} />
          {currentUser?.isAdmin && <SidebarLink toggle={toggle} name={t('Pages.Admin')} href="/app/admin" icon={<UsersIcon />} />}
        </Box>
      </AppShell.Section>
      {user?.currentOrgId && (
        <AppShell.Section>
          <Box
            w="100%"
            display="flex"
            sx={{
              flexDirection: 'column',
              marginLeft: '20px',
            }}
          >
            <Text mb={10} size="xs" color="white">
              {t('Organization.CurrentOrganization')}
            </Text>
            <Text size="sm" fw={600} color="white">
              {user.currentOrgName}
            </Text>
          </Box>
        </AppShell.Section>
      )}
      {!currentUser?.organizationId && (
        <AppShell.Section mt={20}>
          <Box w="100%">
            <Button
              ml={10}
              size="md"
              radius="xxs"
              w={244}
              leftSection={<OrganizationIcon width={24} height={24} stroke="white" />}
              onClick={() => toggleModal(ModalTypes.CreateOrganization)}
              sx={(theme) => ({
                backgroundColor: theme.colors[ColorScheme.customGray][7],
              })}
            >
              <Text size="sm">{t('Organization.Create')}</Text>
            </Button>
          </Box>
        </AppShell.Section>
      )}
      {user?.hasOrganizationsToSwitch() && (
        <AppShell.Section>
          <Box
            w="100%"
            display="flex"
            sx={{
              marginTop: '20px',
              flexDirection: 'column',
              marginLeft: '32px',
            }}
          >
            <Menu>
              <Menu.Target>
                <Button size="xs" radius="xxs" w={200} variant="outline" color="gray">
                  {t('Common.SwitchOrganization')}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                {user?.organizations
                  ?.filter((o) => o.id !== (user?.currentOrgId || ''))
                  ?.map((o) => (
                    <Menu.Item
                      onClick={async () => {
                        await switchOrganization(o.id)
                        await refreshSession()
                        await refetch()
                      }}
                    >
                      {o.name}
                    </Menu.Item>
                  ))}
              </Menu.Dropdown>
            </Menu>
          </Box>
        </AppShell.Section>
      )}
    </AppShell.Navbar>
  )
}
