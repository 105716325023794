import { Button, Group, Modal, TextInput, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PageDto, UpdatePageInfos } from 'api/dto'
import { useParams } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { useUpdatePage } from 'api/query/page'

interface FormProps {
  name: string
  description?: string
}

interface EditPageProps {
  opened: boolean
  page?: PageDto
  toggle: () => void
}

export function EditPage({ opened, page, toggle }: EditPageProps) {
  const { projectId } = useParams()
  const { mutateAsync: updatePage } = useUpdatePage()
  const { t } = useTranslation()
  const form = useForm<FormProps>({
    initialValues: {
      name: page?.name || '',
      description: page?.description,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      if (!page || !projectId) {
        return
      }

      await updatePage({ projectId, page: { id: page.id, ...values } as PageDto })
      toggle()

      showNotification({
        title: t('Page.PageUpdated'),
        message: t('Page.PageUpdatedSuccessfully'),
        color: 'green',
      })
    },
    [page, projectId, toggle, t, updatePage],
  )

  useEffect(() => {
    if (opened) {
      form.setValues({
        name: page?.name || '',
        description: page?.description,
      })
    } else {
      form.reset()
    }
    // we dont want to trigger a setValues everytime the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, opened])

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('Page.EditPage')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <TextInput
          withAsterisk
          mt={16}
          label={t('Page.Name')}
          placeholder={t('Page.EnterPageName') || ''}
          type="text"
          {...form.getInputProps('name')}
          error={undefined}
        />

        <Textarea
          mt={16}
          label={t('Page.Description')}
          placeholder={t('Page.EnterPageDescription') || ''}
          {...form.getInputProps('description')}
          error={undefined}
        />

        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" type="submit">
            {t('Common.Update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
