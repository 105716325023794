import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box } from '@mantine/core'
import { DragHandle } from './drag-handle'
import { Row, flexRender } from '@tanstack/react-table'
import { createStyles } from '@mantine/emotion'

const useDraggableRow = createStyles(() => ({
  draggingRow: {
    background: 'rgba(127, 207, 250, 0.3)',
  },
  tableData: {
    background: 'white',
    // eslint-disable-next-line @typescript-eslint/naming-convention
  },
}))

interface DraggableTableRowProps<T> {
  row: Row<T>
  onRowClick: () => void
  selected: boolean
}

export function DraggableTableRow<T extends { id: string }>({ row, onRowClick, selected }: DraggableTableRowProps<T>) {
  const { classes } = useDraggableRow()
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }

  return (
    <tr
      className={isDragging ? classes.draggingRow : ''}
      ref={setNodeRef}
      style={{ ...style, position: 'relative', cursor: 'pointer' }}
      onClick={onRowClick}
    >
      {isDragging ? (
        <td colSpan={row.getAllCells().length}>&nbsp;</td>
      ) : (
        row.getVisibleCells().map((cell, i) => {
          if (i === 0) {
            return (
              <td className={classes.tableData} style={{ backgroundColor: selected ? '#E3E3E3' : 'white' }}>
                <Box display="flex" sx={{ alignItems: 'center', height: '100%' }}>
                  <DragHandle {...attributes} {...listeners} />
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Box>
              </td>
            )
          }

          return (
            <td key={cell.id} style={{ verticalAlign: 'middle', backgroundColor: selected ? '#E3E3E3' : 'white', fontSize: '10px' }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        })
      )}
    </tr>
  )
}
