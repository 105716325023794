import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import transEn from './trans/en/trans.json'

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum TranslationNamespace {
  translation = 'translation',
  core = 'core',
}

void i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN,
    resources: {
      en: {
        translation: transEn,
      },
    },
  })

export default i18next
