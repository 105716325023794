import { Text } from '@mantine/core'
import { ProjectView } from 'api/dto'
import { useGetMeUser } from 'api/query/user'
import { AddProject } from 'components/modals/add-project.modal'
import { PageTitle } from 'components/title/page-title'
import { useTranslation } from 'react-i18next'
import { ColorScheme } from 'theme/theme'
import { capitalize } from 'utils/string'

export default function Welcome() {
  const { t } = useTranslation()
  const { data: user } = useGetMeUser()
  return (
    <>
      <PageTitle title={`Welcome, ${capitalize(user?.fullName || '')}`} />
      <Text mb={20} size="sm" sx={(theme) => ({ color: theme.colors[ColorScheme.customGray][7] })}>
        You are one click away from creating your first project !
      </Text>
      <AddProject view={ProjectView.MY_PROJECTS} withTile />
    </>
  )
}
