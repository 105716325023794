/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useMemo, useState } from 'react'
import { Box, Table as MantineTable, Text } from '@mantine/core'
import { useReactTable, ColumnDef, getCoreRowModel, getSortedRowModel, flexRender } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { DraggableTableRow } from './draggable-row'
import { StaticTableRow } from './static-table-row'

export interface Data extends Record<string, any> {
  id: string
}

export interface ITableProps<T extends Data> {
  columns: ColumnDef<T>[]
  data: T[]
  untaintedData: any[]
  setData: (data: any[]) => void
  exportTable?: boolean
  setFilteredRows?: (rows: any[]) => void
  defaultSort?: { id: string; desc: boolean }
  hiddenColumns?: string[]
  actionButtons?: ReactNode[]
  selectedRowId?: string
  onRowClick?: (row: any) => void
  withTiles?: boolean
}

export function DragAndDropTable<T extends Data>(props: ITableProps<T>) {
  const [activeId, setActiveId] = useState<string | null>()
  const { columns, data, setData, actionButtons, setFilteredRows, defaultSort, hiddenColumns, untaintedData, onRowClick, selectedRowId } =
    props
  const currentData = useMemo(() => data || [], [data])

  const { getRowModel: getRows, getHeaderGroups } = useReactTable<T>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns,
    data: currentData,
    autoResetPageIndex: true,
    initialState: {
      pagination: { pageSize: 10 },
      sorting: defaultSort ? [defaultSort] : [],
    },
  })

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = untaintedData.findIndex((r) => r.id === active.id)
      const newIndex = untaintedData.findIndex((r) => r.id === over.id)
      const moved = arrayMove(untaintedData, oldIndex, newIndex)
      setData(moved)
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = getRows().rows.find(({ original }) => original.id === activeId)
    if (!row) {
      return null
    }

    return row
  }, [activeId, getRows])

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      {actionButtons && <div className="table-buttons">{actionButtons}</div>}

      <MantineTable highlightOnHover verticalSpacing="md" fz="md">
        <thead>
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                  }}
                  style={{
                    fontSize: '11px',
                  }}
                >
                  <Box display="flex" sx={{ flexDirection: 'column' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        userSelect: 'none',
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <IconArrowUp style={{ marginLeft: '.375rem' }} />,
                        desc: <IconArrowDown style={{ marginLeft: '.375rem' }} />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Box>
                  </Box>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <SortableContext items={data} strategy={verticalListSortingStrategy}>
            {getRows().rows.map((row) => {
              return (
                <DraggableTableRow
                  key={row.original.id}
                  selected={row.original.id === selectedRowId}
                  row={row}
                  onRowClick={() => onRowClick && onRowClick(row.original)}
                />
              )
            })}
          </SortableContext>
        </tbody>
      </MantineTable>
      <DragOverlay>
        {activeId && (
          <table style={{ width: '100%' }}>
            <tbody>
              <StaticTableRow row={selectedRow} />
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  )
}
