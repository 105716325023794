import { useStore } from 'zustand'
import { NewGroup } from './new-group.modal'
import { ModalTypes } from './types'
import { ZoomModal } from './zoom.modal'
import { globalStore } from 'store/store'
import { CreateOrganizationModal } from './create-organization.modal'

export const Modals = () => {
  const { modalTypeOpened } = useStore(globalStore, (state) => ({ modalTypeOpened: state.modalTypeOpened }))
  return (
    <>
      <ZoomModal opened={modalTypeOpened === ModalTypes.Zoom} />
      <NewGroup opened={modalTypeOpened === ModalTypes.NewGroup} />
      <CreateOrganizationModal opened={modalTypeOpened === ModalTypes.CreateOrganization} />
    </>
  )
}
