import { Text } from '@mantine/core'

interface PageTitleProps {
  title: string
}

export function PageTitle({ title }: PageTitleProps) {
  return (
    <Text lh="48px" lts="1px" size="xl">
      {title}
    </Text>
  )
}
