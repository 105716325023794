export function generateRandomColor(): string {
  return (
    '#' +
    Math.floor(Math.random() * (1 << (3 * 8)))
      .toString(16)
      .padStart(6, '0')
  )
}

export function lighten(color: string) {
  return `${color}bf` // 75% opacity
}
