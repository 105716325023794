import { IShape } from 'canvas/types'
import Konva from 'konva'
import { takeoffStore } from 'store/store'
import { BaseShape } from './abstract-shape'
import { hasAccess } from 'utils/features'
import { TakeoffFeature } from 'api/dto/feature'

/**
 * global state needed for shapes
 * this.isDrawing
 */

export class Count extends BaseShape<Konva.Group> implements IShape {
  circle: Konva.Circle
  // text: Konva.Text
  #unsubscribe: () => void

  constructor(
    shape: IShape,
    public readonly index: number,
  ) {
    const instance = new Konva.Group({
      draggable: true,
      x: shape.points[0].x || 0,
      y: shape.points[0].y || 0,
    })

    super(shape, instance)

    const point = this.points.length > 0 ? this.points[0] : { x: 0, y: 0 }
    const isDoubleDigits = index.toString().length > 1
    this.radius = shape.radius || 13
    this.circle = new Konva.Circle({ ...this, fill: this.color, stroke: this.color, radius: shape.radius || 13 })
    this.instance.add(this.circle)

    this.circle.perfectDrawEnabled(false)

    if (hasAccess(TakeoffFeature.TOOLBAR)) {
      this.instance.on('click', this.onMouseClick)
      this.instance.on('tap', this.onMouseClick)

      this.instance.on('dragend', this.#onDragEnd)

      this.instance.on('mouseenter', (e) => {
        if (takeoffStore.getState().isDrawing) {
          return
        }

        e.cancelBubble = true

        document.body.style.cursor = 'pointer'
      })
      this.instance.on('mouseleave', (e) => {
        if (takeoffStore.getState().isDrawing) {
          return
        }
        e.cancelBubble = true

        document.body.style.cursor = 'default'
        if (this.isSelected(takeoffStore.getState().selectedIds)) {
          return
        }
        this.circle.stroke(this.color)
      })
      this.instance.on('mouseover', (e) => {
        if (takeoffStore.getState().isDrawing) {
          return
        }
        e.cancelBubble = true

        this.circle.stroke('#ff6f69')
      })
    }

    this.#unsubscribe = takeoffStore.subscribe((state) => {
      if (this.isSelected(state.selectedIds)) {
        this.circle.stroke('red')
      } else {
        this.circle.stroke(this.color)
      }
    })
  }

  deletePoint(pointId: string) {
    const index = this.points.findIndex((p) => p.id === pointId)

    if (index === -1) {
      return
    }

    this.points.splice(index, 1)
  }

  remove(): void {
    this.instance.remove()
    this.instance.destroy()

    this.circle.remove()
    this.circle.destroy()

    // this.text.remove()
    // this.text.destroy()

    this.#unsubscribe()
  }

  #onDragEnd = (evt: Konva.KonvaEventObject<DragEvent>) => {
    evt.evt.preventDefault()
    evt.cancelBubble = true

    if (takeoffStore.getState().isDrawing) {
      return
    }

    const layer = evt.currentTarget.getLayer() // we need top container instead of the "relative position of the group"

    if (layer) {
      const pointer = layer.getRelativePointerPosition()
      if (!pointer) {
        return
      }
      this.points = [{ id: this.points[0].id, ...pointer }]
      this.save()
    }
  }
}
