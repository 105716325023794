import { ActionIcon, AppShell, Box, Burger } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { Outlet } from 'react-router-dom'
import { Sidebar } from 'components/sidebar/sidebar'
import { Brand } from 'components/brand/brand'
import { ColorScheme } from 'theme/theme'
import { AvatarWithUserMenu } from 'components/avatar/avatar-with-user-menu'
import HelpIcon from 'assets/icons/interrogation.svg?react'
import { Modals } from 'components/modals/modals'
import { useDisclosure } from '@mantine/hooks'

const useAppLayoutStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    backgroundColor: theme.colors[ColorScheme.customGray][0],

    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 25px !important',
  },
  box: {
    position: 'relative',
    padding: '46px 42px',
    height: '100%',
    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}))

export const AppLayout = () => {
  const { classes } = useAppLayoutStyles()
  const [opened, { toggle, close }] = useDisclosure()

  return (
    <AppShell
      navbar={{
        breakpoint: 'sm',
        width: 300,
        collapsed: {
          mobile: !opened,
        },
      }}
      header={{
        height: 90,
      }}
    >
      <AppShell.Header className={classes.header} p="xs">
        <Box display="flex" h="100%" style={{ alignItems: 'center' }}>
          <Burger hiddenFrom="sm" opened={opened} onClick={toggle} size="sm" color="gray.6" mr="xl" />
          <Brand />
        </Box>
        <Box
          display="flex"
          sx={{
            alignItems: 'center',
          }}
        >
          <Box mr={10}>
            <ActionIcon
              variant="transparent"
              onClick={() => window.open('https://share.hsforms.com/11LYsyjrnT3mNIev5wvYkMQ529ew', '_blank')}
            >
              <HelpIcon width={20} height={20} />
            </ActionIcon>
          </Box>
          <AvatarWithUserMenu />
        </Box>
      </AppShell.Header>
      <Sidebar opened={opened} toggle={close} />
      <AppShell.Main className={classes.root}>
        <Box className={classes.box}>
          <Outlet />
        </Box>
        <Modals />
      </AppShell.Main>
    </AppShell>
  )
}
