import { showNotification } from '@mantine/notifications'
import { globalQueryClient } from 'api/client'
import { Group, GroupDto } from 'api/dto'
import axios from 'config/axios'
import { takeoffStore } from 'store/store'

export class GroupService {
  private static get takeoffConfig() {
    return takeoffStore.getState().config
  }

  static async createGroup(group: Partial<GroupDto>) {
    const { projectId, layerId, pageId } = this.takeoffConfig || {}

    try {
      const { data: newGroup } = await axios.post<GroupDto>(`/projects/${projectId}/pages/${pageId}/layers/${layerId}/groups`, group)

      globalQueryClient.setQueryData<GroupDto[]>(['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups'], (oldGroups) => {
        return (oldGroups || []).concat(newGroup)
      })

      return newGroup.id
    } catch (error: any) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })

      return null
    }
  }

  static async updateGroup(id: string, group: Partial<GroupDto>) {
    const { projectId, layerId, pageId } = this.takeoffConfig || {}

    try {
      const { data: updatedGroup } = await axios.put<Group>(`/projects/${projectId}/pages/${pageId}/layers/${layerId}/groups/${id}`, group)

      globalQueryClient.setQueryData<Group[]>(['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups'], (oldGroups) => {
        if (!oldGroups) return oldGroups
        return oldGroups.map((g) => (g.id === id ? updatedGroup : g))
      })
    } catch (error: any) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    }
  }

  static async deleteGroup(groupId: string) {
    const { projectId, layerId, pageId } = this.takeoffConfig || {}

    try {
      await axios.delete(`/projects/${projectId}/pages/${pageId}/layers/${layerId}/groups/${groupId}`)

      globalQueryClient.setQueryData<GroupDto[]>(['projects', projectId, 'pages', pageId, 'layers', layerId, 'groups'], (oldGroups) => {
        if (!oldGroups) return oldGroups
        return oldGroups.filter((g) => g.id !== groupId)
      })
    } catch (error: any) {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
    }
  }
}
