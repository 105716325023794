import { Navigate, Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation } from 'react-router-dom'
import 'regenerator-runtime/runtime'
import { ProvideAuth, useAuth } from './hooks/useAuth'
import { AppLayout } from './layout/app.layout'
import { Pages } from './pages/pages'
import { UserSettings } from './pages/user-settings'
import { OrganizationSettings } from './pages/organization-settings'
import { PaymentSuccess } from 'pages/payment'
import { AuthLayout } from 'layout/auth.layout'
import { Login } from 'pages/session/login'
import { Register } from 'pages/session/register'
import { ForgotPassword } from 'pages/session/forgot-password'
import { ResetPassword } from 'pages/session/reset-password'
import { Dashboard } from 'pages/dashboard'
import { Admin } from 'pages/admin'
import { ChangePassword } from 'pages/session/change-password'
import { MyProjects } from 'pages/projects/my-projects'
import { OrganizationProjects } from 'pages/projects/organization-projects'
import { SharedProjects } from 'pages/projects/shared-projects'
import { Oops } from 'pages/oops'
import { TakeoffLayout } from 'layout/takeoff.layout'
import { RendererComponent } from 'components/renderer/renderer'
import { IndexLayout } from 'layout/index.layout'
import { ProjectLoader } from 'components/project/project-loader'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProvideAuth />}>
      <Route element={<IndexLayout />}>
        <Route path="session" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route index element={<Navigate to="/session/login" />} />
        </Route>
        <Route path="registration" element={<AuthLayout />}>
          <Route path="success" element={<PaymentSuccess />} />
          <Route index element={<Navigate to="/registration/success" />} />
        </Route>
        <Route
          path="app"
          element={
            <RequireAuth>
              <AppLayout />
            </RequireAuth>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="projects" element={<MyProjects />} />
          <Route path="organization-projects" element={<OrganizationProjects />} />
          <Route path="shared-projects" element={<SharedProjects />} />
          <Route path="projects/:projectId" element={<Pages />} />
          <Route path="organization-projects/:projectId" element={<Pages />} />
          <Route path="shared-projects/:projectId" element={<Pages />} />
          <Route path="settings">
            <Route path="user" element={<UserSettings />} />
            <Route path="organization" element={<OrganizationSettings />} />
            <Route index element={<Navigate to="/settings/user" />} />
          </Route>
          <Route path="admin" element={<Admin />} />
          <Route path="oops" element={<Oops />} />
          <Route index element={<Navigate to="/app/dashboard" replace />} />
        </Route>

        <Route
          path="board"
          element={
            <RequireAuth>
              <ProjectLoader />
            </RequireAuth>
          }
        >
          <Route path=":projectId">
            <Route path="pages">
              <Route path=":pageId" element={<TakeoffLayout />}>
                <Route path="layers">
                  <Route path=":layerId" element={<RendererComponent />} />
                  <Route index element={<Navigate to="/app/dashboard" replace />} />
                </Route>
                <Route index element={<Navigate to="/app/dashboard" replace />} />
              </Route>
              <Route index element={<Navigate to="/app/dashboard" replace />} />
            </Route>
            <Route index element={<Navigate to="/app/dashboard" replace />} />
          </Route>
          <Route index element={<Navigate to="/app/dashboard" replace />} />
        </Route>

        <Route path="*" element={<Navigate to="/app/dashboard" replace />} />
      </Route>
      <Route path="*" element={<Navigate to="/app/dashboard" replace />} />
    </Route>,
  ),
)

export const App = () => {
  return <RouterProvider router={router} />
}

function RequireAuth({ children }: { children: JSX.Element; needAdminRights?: boolean }) {
  const { isAuthenticated, appIsHydrated } = useAuth()
  const location = useLocation()

  if (!isAuthenticated && appIsHydrated) {
    return <Navigate to="/session/login" state={{ from: location }} replace />
  }

  return children
}
