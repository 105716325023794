import { GroupDto, System } from 'api/dto'
import { CombinedShapeTypes, isCount, isMeasure } from 'canvas/shape/types/utils'
import Konva from 'konva'
import { nanoid } from 'nanoid'
import { takeoffStore } from 'store/store'
import { generateRandomColor } from 'utils/color'
import { getGroupName } from 'utils/icon'
import { convertCalculation } from 'utils/measure'
import { calculate } from '../shape/shape-utils'
import { Calculation, IGroupCanvas, ShapeTypes } from '../types'
import { TakeoffFactory } from 'factory/takeoff-factory'
import { Scale } from 'canvas/shape/types/scale'
import { isValidUUID } from 'utils/uuid'

export class GroupCanvas implements IGroupCanvas {
  id: string
  type: ShapeTypes
  shapes: CombinedShapeTypes[]
  color: string
  unit?: string
  order: number

  name: string
  depth?: string
  thickness?: string

  factor?: number

  relatedGroupId?: string
  productId?: string

  instance: Konva.Group

  calculations: Calculation | undefined = undefined

  factory = new TakeoffFactory()

  isVisible = true

  constructor(group: Partial<IGroupCanvas> & Pick<IGroupCanvas, 'type' | 'order'>) {
    const { color, id, type, name, depth, factor, thickness, productId, order } = group

    // todo get page system
    const system = System.IMPERIAL

    this.id = id || nanoid()

    this.name = name || getGroupName(type)
    this.shapes = []
    this.type = type
    this.color = color ?? generateRandomColor()
    this.depth = depth
    this.thickness = thickness
    this.factor = factor
    this.productId = productId
    this.order = order
    // default unit

    const defaultUnit = system === System.IMPERIAL ? (type === ShapeTypes.Area ? 'ft2' : 'ft') : type === ShapeTypes.Area ? 'm2' : 'm'

    this.unit = defaultUnit
    this.relatedGroupId = group.relatedGroupId

    this.instance = new Konva.Group()
  }

  get isSavedGroup() {
    return isValidUUID(this.id)
  }

  hasShapes = () => {
    return this.shapes.length > 0
  }

  addShape = (newShape: CombinedShapeTypes) => {
    this.shapes.push(newShape)

    this.instance.add(newShape.instance)
    return newShape
  }

  addShapeGroup = (group: Konva.Group) => {
    this.instance.add(group)
    return group
  }

  removeShape = (index: number, withSave?: boolean) => {
    const { recalculateGroup, saveGroup } = takeoffStore.getState()

    this.shapes[index].remove()
    this.shapes.splice(index, 1)

    recalculateGroup(this.id)

    if (withSave) {
      saveGroup(this.id)
    }
  }

  removeShapes = () => {
    this.shapes.forEach((shape) => {
      shape.remove()
    })
    this.shapes = []
  }

  moveTo = (layer: Konva.Layer) => {
    this.instance.moveTo(layer)
  }

  updateColor = (color: string) => {
    this.color = color
    this.shapes.forEach((s) => {
      s.updateColor(this.type, color)
    })
  }

  changeVisibility = () => {
    this.isVisible = !this.isVisible
    this.shapes.forEach((s) => {
      s.instance.visible(this.isVisible)
      if (!isCount(s)) {
        s.anchors.forEach((a) => a.instance.visible(this.isVisible))
      }
    })
  }

  calculate = (): Calculation => {
    if (this.type === ShapeTypes.Scale) {
      return {}
    }

    const pageScale = takeoffStore.getState().scale
    if (!pageScale.hasCalculatedScale()) {
      return {}
    }

    const scale = {
      x: pageScale.calculated.xCalculated || 0,
      y: pageScale.calculated.yCalculated || 0,
    }

    const calculations = this.shapes.map((s) => calculate(this, s.points, scale))

    let hasArea = this.type !== ShapeTypes.Count && this.type !== ShapeTypes.Measure
    let hasVolume = !!this.depth
    const hasLength = this.type !== ShapeTypes.Count

    if (this.type === ShapeTypes.Line) {
      if (!this.depth) {
        hasArea = false
      }

      if (!this.thickness) {
        hasVolume = false
      }
    }

    const baseCount = calculations.filter((a) => a.count != null).map((a) => a.count!)
    const shapeCount = this.shapes.length.toString()
    const newCalculations = {
      volume: hasVolume
        ? calculations
            .filter((a) => a.volume != null)
            .map((a) => a.volume!)
            .reduce((acc, c) => (Number.parseFloat(acc) + Number.parseFloat(c)).toString(), '0')
        : undefined,
      area: hasArea
        ? calculations
            .filter((a) => a.area != null)
            .map((a) => a.area!)
            .reduce((acc, c) => (Number.parseFloat(acc) + Number.parseFloat(c)).toString(), '0')
        : undefined,
      perimeter: hasLength
        ? calculations
            .filter((a) => a.perimeter != null)
            .map((a) => a.perimeter!)
            .reduce((acc, c) => (Number.parseFloat(acc) + Number.parseFloat(c)).toString(), '0')
        : undefined,
      count: hasLength
        ? shapeCount
        : baseCount.length > 1
          ? baseCount.reduce((acc, c) => (Number.parseFloat(acc) + Number.parseFloat(c)).toString(), '0')
          : baseCount.length === 0
            ? '0'
            : baseCount.reduce((acc, c) => (Number.parseFloat(acc) + Number.parseFloat(c)).toString(), '0'),
    }

    this.calculations = newCalculations

    if (this.shapes[0] && isMeasure(this.shapes[0])) {
      // TODO metric
      const calcs = convertCalculation(
        this.calculations.perimeter?.toString() || '0',
        // todo get page system
        //page?.system === System.IMPERIAL ? 'ft' : 'm'
        'ft',
        'length',
      )
      this.shapes[0].changeDistance(calcs)
    }

    return newCalculations
  }

  merge = (group: GroupDto) => {
    // compare this group with the dto and merge if theres a difference
    if (!this.isEqual(group)) {
      this.name = group.name || this.name
      this.unit = group.unit
      this.depth = group.depth
      this.thickness = group.thickness
      this.color = group.color || this.color
      this.order = group.order
      this.productId = group.productId
      this.relatedGroupId = group.relatedGroupId
    }

    if (!group.shapes) {
      return
    }

    const shapes = Object.fromEntries(group.shapes.map((e) => [e.id, e]))

    this.shapes.forEach((s) => {
      const foundShape = shapes[s.id]
      if (foundShape) {
        if (!s.isEqual(foundShape)) {
          s.changePoints(s, foundShape.points)
        }
      } else {
        this.removeShape(this.shapes.indexOf(s))
      }
    })

    group.shapes.forEach((s) => {
      const foundShape = this.shapes.find((currShape) => currShape.id === s.id)
      if (!foundShape) {
        TakeoffFactory.createCanvasShape(s, this, this.shapes.length + 1)
      }
    })
  }

  toCreateDto = () => {
    const { name, type, unit, depth, thickness, color, order, productId, relatedGroupId } = this

    return {
      name,
      type: type.toString(),
      unit: unit || 'ft',
      depth,
      thickness,
      color,
      order,
      productId,
      relatedGroupId,
      shapes: this.shapes.map((s) => ({ id: s.id, groupId: s.groupId, points: s.points, axis: (s as Scale).axis })),
    }
  }

  toUpdateDto = () => {
    const { name, unit, depth, thickness, color, order, productId, relatedGroupId } = this

    return {
      name,
      unit: unit || 'ft',
      depth,
      thickness,
      color,
      order,
      productId,
      relatedGroupId,
      shapes: this.shapes.map((s) => ({ id: s.id, groupId: s.groupId, points: s.points, axis: (s as Scale).axis })),
    }
  }

  isEqual = (group: GroupDto) => {
    const { name, type, unit, depth, thickness, color, order, productId, relatedGroupId } = this

    return (
      name === group.name &&
      type === Number.parseInt(group.type) &&
      unit === group.unit &&
      depth === group.depth &&
      thickness === group.thickness &&
      color === group.color &&
      order === group.order &&
      productId === group.productId &&
      relatedGroupId === group.relatedGroupId
    )
  }
}
