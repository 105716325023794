import { Button, ColorInput, Group, Modal, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { globalStore, takeoffStore } from 'store/store'
import { ModalTypes } from './types'
import { MeasureField } from './group/measure-field'
import { ShapeTypes } from 'canvas/types'
import { useTranslation } from 'react-i18next'
import { convertManualRepresentation, convertToFactor, ManualRepresentation } from 'utils/measure'
import { getGroupType } from 'canvas/shape/types/utils'
import { showNotification } from '@mantine/notifications'
import { useStore } from 'zustand'

interface FormProps {
  name: string
  color: string
  factor: number
  productId?: string
}

export function NewGroup({ opened }: { opened: boolean }) {
  const { t } = useTranslation()
  const { toggleModal } = useStore(globalStore, (state) => ({ toggleModal: state.toggleModal }))
  const { renderer } = useStore(takeoffStore, (state) => ({ renderer: state.renderer }))
  const {
    selectedGroup: group,
    groups,
    updateGroup,
  } = useStore(takeoffStore, (state) => ({
    selectedGroup: state.selectedGroup,
    groups: state.groups,
    updateGroup: state.updateGroup,
  }))
  const groupType = getGroupType(group?.type)

  const toggle = useCallback(() => {
    toggleModal(ModalTypes.None)
  }, [toggleModal])

  const form = useForm<FormProps>({
    initialValues: {
      name: group?.name || '',
      color: group?.color || '',
      factor: group?.factor || 0,
    },
  })

  const [depth, setDepth] = useState<ManualRepresentation>({
    fraction: {
      num: 0,
      den: 0,
    },
  })

  const [thickness, setThickness] = useState<ManualRepresentation>({
    fraction: {
      num: 0,
      den: 0,
    },
  })

  useEffect(() => {
    if (opened && group) {
      form.setValues({
        name: group.name,
        color: group.color,
        factor: group.factor,
      })
    } else {
      form.reset()
      setDepth({})
      setThickness({})
    }
    // we dont want to trigger a setValues every time the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, opened])

  const formSubmit = useCallback(
    (id: string, values: FormProps) => {
      if (!group || !renderer) {
        return
      }

      const currentGroup = { id, ...values }

      const repDepth = convertManualRepresentation(depth)
      const repThickness = convertManualRepresentation(thickness)

      const depthFactor = convertToFactor(repDepth)
      const thicknessFactor = convertToFactor(repThickness)

      const calcDepth = depthFactor > 0 ? repDepth : undefined
      const calcThickness = thicknessFactor > 0 ? repThickness : undefined
      updateGroup({ ...currentGroup, depth: calcDepth, thickness: calcThickness, order: groups.length + 1 })

      showNotification({
        title: t('Group.GroupAdded'),
        message: t('Group.GroupAddedSuccessfully'),
        color: 'green',
      })

      group.updateColor(values.color)
      renderer.addNewShape()

      toggle()
    },
    [group, depth, thickness, updateGroup, groups.length, t, renderer, toggle],
  )

  if (!group) {
    return null
  }

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={() => {
        void renderer?.finishDrawing(true)
        toggle()
      }}
      title="Group Settings"
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(group.id, values))}>
        <TextInput mt={16} label="Name" placeholder="Enter a group name" type="text" {...form.getInputProps('name')} error={undefined} />

        <ColorInput mt={16} label="Color" size="sm" placeholder="Enter a color" {...form.getInputProps('color')} />

        {groupType !== ShapeTypes.Count && (
          <>
            <MeasureField
              name={groupType === ShapeTypes.Area ? t('Group.Depth') : t('Group.WallHeight')}
              type={groupType as ShapeTypes}
              value={depth}
              setValue={setDepth}
            />

            {groupType !== ShapeTypes.Area && (
              <MeasureField name={t('Group.WallThickness')} value={thickness} type={groupType as ShapeTypes} setValue={setThickness} />
            )}
          </>
        )}

        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" type="submit">
            Submit
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
