import { createStore as createVanilla } from 'zustand/vanilla'
import {
  DrawingSlice,
  createDrawingSlice,
  createDrawerSlice,
  DrawerSlice,
  createUserSlice,
  UserSlice,
  ConfigSlice,
  createConfigSlice,
} from './app.store'
import { createModalSlice, ModalSlice } from './modal.store'
import { createPopoverSlice, PopoverSlice } from './popover.store'
import { RendererSlice, createRendererSlice } from './renderer.store'
import { ScaleSlice, createScaleSlice } from './scale.store'
import { GroupSlice, createGroupSlice } from './group.store'
import { ShapeSlice, createShapeSlice } from './shape.store'
import { GroupShapeSlice, SelectedSlice, createGroupShapeSlice, createSelectedSlice } from './group-shape.store'
import { ShapeTypes } from 'canvas/types'
import { LayerScale } from 'utils/scale/scale'

export const globalStore = createVanilla<UserSlice & DrawerSlice & ModalSlice & PopoverSlice>()((...a) => ({
  ...createUserSlice(...a),
  ...createDrawerSlice(...a),
  ...createModalSlice(...a),
  ...createPopoverSlice(...a),
}))

export const takeoffStore = createVanilla<
  RendererSlice & DrawingSlice & ScaleSlice & ConfigSlice & GroupSlice & ShapeSlice & SelectedSlice & GroupShapeSlice
>()((...a) => ({
  ...createConfigSlice(...a),
  ...createDrawingSlice(...a),
  ...createRendererSlice(...a),
  ...createScaleSlice(...a),
  ...createGroupSlice(...a),
  ...createShapeSlice(...a),
  ...createSelectedSlice(...a),
  ...createGroupShapeSlice(...a),
}))

export function resetStore() {
  takeoffStore.setState({
    isDrawing: false,
    drawingType: ShapeTypes.None,
    renderer: undefined,
    config: {},
    groups: [],
    selectedGroup: undefined,
    selectedIds: [],
    selectedShape: undefined,
    selectedIdsToDelete: [],
    scale: new LayerScale(),
    scaleAxis: undefined,
  })

  globalStore.setState({
    userId: undefined,
    drawerOpened: false,
    usersConnected: [],
  })
}
