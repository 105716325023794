import { System } from './project'

export enum PageCreationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CREATED = 'CREATED',
}
export interface Viewport {
  width: number
  height: number
}

export interface CreatePage {
  projectId: string
  page: {
    name: string
    system: System
    src?: string
    thumbnailSrc?: string
  }
}

export interface UpdatePageInfos {
  pageId: string
  projectId: string
  page: {
    id: string
    name?: string
    description?: string
  }
}

export interface UpdatePageName {
  pageId: string
  projectId: string
  name: string
}

export interface DeletePage {
  projectId: string
  pageId: string
}

export interface MovePage {
  projectId: string
  pageId: string
  order: number
}

export interface PageDto {
  id: string
  projectId: string
  name: string
  description: string
  viewport?: Viewport
  system: System
  userId?: string
  src?: string
  order?: number
  thumbnailSrc?: string
  createdAt?: string
  status?: PageCreationStatus
  lastModifiedAt?: string
  hasGroups?: boolean
  layers: { layerId: string; name: string; isOriginalLayer?: boolean }[]
}

export interface IAugmentedPageDto extends PageDto {
  projectId: string
  projectName?: string
}

interface SimpleLayer {
  layerId: string
  name: string
  isOriginalLayer?: boolean
  hasTakeoff?: boolean
}

export class Page {
  id: string
  projectId: string
  name: string
  description: string
  viewport?: Viewport
  system: System
  userId?: string
  src?: string
  srcUntainted?: string
  order: number
  thumbnailSrc?: string
  thumbnailSrcUntainted?: string
  createdAt?: string
  lastModifiedAt?: string
  status?: PageCreationStatus
  projectName?: string
  isReady?: boolean
  layers: SimpleLayer[]

  constructor(pageDto: IAugmentedPageDto) {
    if (!pageDto) {
      this.id = ''
      this.projectId = ''
      this.name = ''
      this.description = ''
      this.system = System.IMPERIAL
      this.layers = []
      this.order = 0
      return
    }

    this.id = pageDto.id
    this.projectId = pageDto.projectId
    this.name = pageDto.name
    this.description = pageDto.description
    this.viewport = pageDto.viewport
    this.system = pageDto.system
    this.userId = pageDto.userId
    this.srcUntainted = pageDto.src
    this.createdAt = pageDto.createdAt
    this.lastModifiedAt = pageDto.lastModifiedAt
    this.status = pageDto.status
    this.projectName = pageDto.projectName
    this.thumbnailSrcUntainted = pageDto.thumbnailSrc
    this.order = pageDto.order || 0
    this.layers = pageDto.layers || []
    this.src = pageDto.src
    this.thumbnailSrc = pageDto.thumbnailSrc
  }

  get sourceImage(): string | undefined {
    const srcSplitted = this.src?.split('/')
    if (srcSplitted && srcSplitted.length > 0) {
      return `https://${srcSplitted[0]}.s3.ca-central-1.amazonaws.com/${srcSplitted.slice(1).join('/')}`
    }

    return undefined
  }

  get thumbnailImage(): string | undefined {
    const thumbnailSrcSplitted = this.thumbnailSrc?.split('/')
    if (thumbnailSrcSplitted && thumbnailSrcSplitted.length > 0) {
      return `https://${thumbnailSrcSplitted[0]}.s3.ca-central-1.amazonaws.com/${thumbnailSrcSplitted.slice(1).join('/')}`
    }

    return undefined
  }

  get hasTakeoff(): boolean {
    return this.layers.some((l) => l.hasTakeoff)
  }

  get isInReadyState(): boolean {
    return this.status === PageCreationStatus.CREATED
  }

  get isInProgressState(): boolean {
    return this.status === PageCreationStatus.IN_PROGRESS
  }

  getOriginalLayerId(): string | undefined {
    return this.layers.find((l) => l.isOriginalLayer)?.layerId
  }

  get isImperial(): boolean {
    return this.system === System.IMPERIAL
  }
}
