export interface PermissionDto {
  id?: string
  userId?: string
  projectId: string
  email?: string
  fullName?: string
  access: Access
}

export enum Access {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
}

export interface IPermission {
  id?: string
  userId?: string
  projectId: string
  email?: string
  fullName?: string
  access: Access
}

export class Permission {
  id?: string
  userId?: string
  projectId: string
  email?: string
  fullName?: string
  access: Access

  constructor(data: PermissionDto) {
    this.id = data.id
    this.userId = data.userId
    this.projectId = data.projectId
    this.email = data.email
    this.fullName = data.fullName
    this.access = data.access
  }

  isEditor() {
    return this.access === Access.EDITOR || this.access === Access.MANAGER || this.access === Access.OWNER
  }

  isManager() {
    return this.access === Access.MANAGER || this.access === Access.OWNER
  }

  isOwner() {
    return this.access === Access.OWNER
  }

  isViewer() {
    return this.access === Access.VIEWER
  }
}
