/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react'
import { IGroupCanvas, ShapeTypes } from '../canvas/types'
import { useKeyPress } from './useKeyPress'
import { Vector2d } from 'konva/lib/types'
import { Axis } from '../utils/scale/scale'
import { globalStore, takeoffStore } from 'store/store'
import { PopoverTypes } from 'components/toolbar/types'
import { useStore } from 'zustand'
import { useGetMeUser } from 'api/query/user'
import { useParams } from 'react-router-dom'
import { GroupService } from 'api/services/group-service'
import { ModalTypes } from 'components/modals/types'

const drawingContext = createContext<ReturnType<typeof useProvideDrawing>>({} as any)

export function ProvideDrawing({ children }: { children: ReactNode }): JSX.Element {
  const drawing = useProvideDrawing()
  return <drawingContext.Provider value={drawing}>{children}</drawingContext.Provider>
}

export const useDrawing = () => useContext(drawingContext)

const useProvideDrawing = () => {
  const {
    renderer,
    isDrawing,
    setDrawingType,
    setScaleAxis,
    scale,
    findScaleGroup,
    deleteGroup,
    selectedShape,
    resetSelectedEntities,
    selectedGroup,
  } = useStore(takeoffStore, (state) => ({
    renderer: state.renderer,
    scale: state.scale,
    isDrawing: state.isDrawing,
    setDrawingType: state.setDrawingType,
    setScaleAxis: state.setScaleAxis,
    findScaleGroup: state.findScaleGroup,
    deleteGroup: state.deleteGroup,
    selectedShape: state.selectedShape,
    resetSelectedEntities: state.resetSelectedEntities,
    selectedGroup: state.selectedGroup,
  }))
  const { data: user } = useGetMeUser()

  const { togglePopover, toggleModal } = useStore(globalStore, (state) => ({
    togglePopover: state.togglePopover,
    toggleModal: state.toggleModal,
  }))

  const reset = useStore(takeoffStore, (state) => state.resetSelectedEntities)
  const escPressed = useKeyPress('Escape')

  const setManualScale = useCallback(
    (axis: Axis, scaleStringRepresentation: string) => {
      setScaleAxis(axis)

      // reset automatic
      scale.automatic = {}
      if (axis === Axis.Horizontal) {
        scale.manual.x = scaleStringRepresentation
      } else {
        scale.manual.y = scaleStringRepresentation
      }
    },
    [setScaleAxis, scale],
  )

  const onStartScaleDraw = useCallback(
    async (axis: Axis) => {
      if (isDrawing || !renderer) {
        return
      }

      if (selectedShape) {
        resetSelectedEntities()
      }

      const scaleGroup = findScaleGroup(axis)
      if (scaleGroup) {
        deleteGroup(scaleGroup.id)
        await GroupService.deleteGroup(scaleGroup.id)
      }

      const container = renderer?.stage.container()
      if (container) {
        container.style.cursor = 'crosshair'
      }

      renderer.addNewGroup(ShapeTypes.Scale)
      renderer.addNewShape()
    },
    [isDrawing, findScaleGroup, renderer, deleteGroup, selectedShape, resetSelectedEntities],
  )

  const onStart = useCallback(
    (shapeType: ShapeTypes, relatedGroup?: IGroupCanvas) => {
      if (isDrawing || !renderer) {
        return
      }

      if (selectedShape) {
        resetSelectedEntities()
      }

      if (!scale.hasCalculatedScale()) {
        if (!scale.calculated.xCalculated) {
          togglePopover(PopoverTypes.ScaleX)
          return
        }

        if (!scale.calculated.yCalculated) {
          togglePopover(PopoverTypes.ScaleY)
          return
        }
      }

      const container = renderer?.stage.container()
      if (container) {
        container.style.cursor = 'crosshair'
      }

      if (selectedGroup && shapeType !== ShapeTypes.Measure) {
        renderer.assignSelectedGroup(selectedGroup)
        renderer.addNewShape()
        return
      } else {
        renderer.addNewGroup(shapeType, relatedGroup)
      }

      if (shapeType !== ShapeTypes.Measure) {
        toggleModal(ModalTypes.NewGroup)
      } else {
        renderer.addNewShape()
      }
    },
    [isDrawing, renderer, scale, togglePopover, toggleModal, selectedShape, resetSelectedEntities, selectedGroup],
  )

  const onStop = useCallback(() => {
    if (!renderer || !isDrawing || !user) {
      return
    }

    void renderer.finishDrawing()
    const container = renderer?.stage.container()
    if (container) {
      container.style.cursor = 'default'
    }

    reset()
    setDrawingType(ShapeTypes.None)
    toggleModal(ModalTypes.None)
  }, [renderer, isDrawing, user, reset, setDrawingType, toggleModal])

  const getPointerPos = (): Vector2d | null => {
    const pointerPos = renderer?.stage.getPointerPosition()
    if (!pointerPos) {
      return null
    }

    const containerRec = renderer?.stage.container().getBoundingClientRect()

    if (!containerRec) {
      return null
    }

    return {
      x: containerRec.left + pointerPos.x + 10,
      y: containerRec.top + pointerPos.y - 10,
    } as Vector2d
  }

  useEffect(() => {
    if (escPressed) {
      onStop()
    }
  }, [escPressed, onStop, reset])

  return {
    isDrawing,
    onStart,
    onStop,

    setManualScale,
    onStartScaleDraw,

    getPointerPos,
  }
}
