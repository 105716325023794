import { CombinedShapeTypes, isShapeWithAnchors } from 'canvas/shape/types/utils'
import Konva from 'konva'

const types = ['plan', 'shapes', 'draw', 'collab'] as const

export type LayerType = (typeof types)[number]
export type LayerObject = { type: LayerType; layer: Konva.Layer }

export class LayerManager {
  layers: LayerObject[] = []

  constructor() {
    this.generateLayers()
  }

  get planLayer(): LayerObject {
    const wrapper = this.layers.find((l) => l.type === 'plan')
    if (!wrapper) {
      throw new Error('missing plan layer')
    }

    return wrapper
  }

  get drawLayer(): LayerObject {
    const wrapper = this.layers.find((l) => l.type === 'draw')
    if (!wrapper) {
      throw new Error('missing draw layer')
    }

    return wrapper
  }

  get shapeLayer(): LayerObject {
    const wrapper = this.layers.find((l) => l.type === 'shapes')
    if (!wrapper) {
      throw new Error('missing shape layer')
    }

    return wrapper
  }

  get collaborationLayer(): LayerObject {
    const wrapper = this.layers.find((l) => l.type === 'collab')
    if (!wrapper) {
      throw new Error('missing shape layer')
    }

    return wrapper
  }

  private generateLayers() {
    types.forEach((layer) => {
      const newLayer = new Konva.Layer({
        name: layer,
      })

      this.layers.push({
        type: layer,
        layer: newLayer,
      })
    })

    return this.layers
  }

  public addToLayer(layerObject: LayerObject, shape: Konva.Shape | Konva.Group) {
    layerObject.layer.add(shape)
  }

  public updateStage() {
    this.layers.forEach((l) => l.layer.draw())
  }

  public removeChildren() {
    this.layers.forEach((l) => {
      l.layer.destroyChildren()
    })
  }

  public removeLayers() {
    this.layers.forEach((l) => {
      l.layer.destroy()
    })
  }
}
