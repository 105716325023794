import { IPoint, IShape } from 'canvas/types'
import Konva from 'konva'
import { takeoffStore } from 'store/store'
import { Axis, LayerScale } from 'utils/scale/scale'
import { BaseShape } from './abstract-shape'
import { Anchor } from './anchor'
import { LayerService } from 'api/services/layer-service'
import { hasAccess } from 'utils/features'
import { TakeoffFeature } from 'api/dto/feature'

/**
 * global state needed for shapes
 * this.isDrawing
 */

export class Scale extends BaseShape<Konva.Line> implements IShape {
  axis: Axis

  anchorGroup: Konva.Group
  anchors: Anchor[] = []

  unsubscribe: () => void

  constructor(shape: IShape, axis: Axis) {
    const currentPoints = shape.points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b), [])

    const instance = new Konva.Line({
      points: currentPoints,
      stroke: shape.color,
      strokeWidth: 10,
      lineCap: 'round',
      lineJoin: 'round',
    })

    super(shape, instance)

    this.axis = axis

    this.anchorGroup = new Konva.Group()

    this.instance.perfectDrawEnabled(false)

    this.instance.on('click', this.onMouseClick)
    this.instance.on('tap', this.onMouseClick)
    this.instance.on('dragend', this.onMouseMoveEnd)

    this.points.forEach((point) => {
      if (hasAccess(TakeoffFeature.TOOLBAR)) {
        this.#addAnchor(point)
      }
    })

    this.unsubscribe = takeoffStore.subscribe((state) => {
      if (state.scaleIsLocked) {
        this.anchorGroup.hide()
      } else {
        this.anchorGroup.show()
      }
    })
  }

  remove(): void {
    this.instance.remove()
    this.instance.destroy()

    this.anchors.forEach((s) => s.remove())
    this.anchorGroup.remove()
    this.anchorGroup.destroy()
  }

  saveScale = () => {
    const { scale, setScale, config } = takeoffStore.getState()
    const { layerId } = config || {}
    const calculatedScale = scale.calculateManualScale(this.axis, this.points[0], this.points[1])
    if (calculatedScale) {
      void LayerService.updateLayer({ id: layerId!, scale: calculatedScale })
    }
    setScale(new LayerScale(calculatedScale))
    takeoffStore.getState().recalculateGroups()
  }

  addPoint(point: IPoint) {
    const layer = this.instance.getLayer()
    if (layer == null) {
      return
    }

    this.points.push(point)

    const points = this.points || []

    this.instance.points(points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))

    this.#addAnchor(point)
  }

  movePoint(point: IPoint) {
    const pointIndex = this.points.findIndex((p) => p.id === point.id)
    if (pointIndex === -1) {
      return
    }

    this.points[pointIndex] = point

    this.instance.points(this.points.map((point) => [point.x, point.y]).reduce((a, b) => [...a].concat(b)))
  }

  #addAnchor(point: IPoint, index?: number) {
    const anchor = new Anchor({
      shapeId: this.id,
      groupId: this.groupId,
      point,
      movePoint: (p: IPoint) => this.movePoint(p),
      onSave: this.saveScale,
      axis: this.axis,
    })

    this.anchorGroup.add(anchor.instance)

    if (index) {
      this.anchors.splice(index, 0, anchor)
    } else {
      this.anchors.push(anchor)
    }
  }
}
