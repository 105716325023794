import { ShapeTypes } from '../canvas/types'
import AreaIcon from 'assets/icons/area.svg?react'
import LinearIcon from 'assets/icons/linear.svg?react'
import MeasureIcon from 'assets/icons/measure.svg?react'
import CountIcon from 'assets/icons/count.svg?react'

export function getShapeIcon(shapeType: ShapeTypes, color = '#000') {
  switch (shapeType) {
    case ShapeTypes.Area:
      return <AreaIcon style={{ minWidth: '14px', minHeight: '14px' }} width="14px" height="14px" />
    case ShapeTypes.Line:
      return <LinearIcon style={{ minWidth: '14px', minHeight: '14px' }} width="14px" height="14px" />
    case ShapeTypes.Measure:
      return <MeasureIcon style={{ minWidth: '14px', minHeight: '14px' }} width="14px" height="14px" />
    case ShapeTypes.Count:
      return <CountIcon style={{ minWidth: '14px', minHeight: '14px' }} width="14px" height="14px" />
    default:
      return null
  }
}

export function getShapeToolbarIcon(shapeType: ShapeTypes) {
  switch (shapeType) {
    case ShapeTypes.Area:
      return <AreaIcon width="30px" height="30px" />
    case ShapeTypes.Line:
      return <LinearIcon width="30px" height="30px" />
    case ShapeTypes.Measure:
      return <MeasureIcon width="30px" height="30px" />
    case ShapeTypes.Count:
      return <CountIcon width="30px" height="30px" />
    default:
      return null
  }
}

export function getGroupName(shapeType: ShapeTypes) {
  // todo translate
  switch (shapeType) {
    case ShapeTypes.Area:
      return 'Area'
    case ShapeTypes.Line:
      return 'Line'
    case ShapeTypes.Measure:
      return 'Measure'
    case ShapeTypes.Count:
      return 'Count'
  }

  return 'N/A'
}
