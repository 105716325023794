import { ActionIcon, Avatar, Box, Button, Grid, Menu, Select, SimpleGrid, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { Access, Permission } from 'api/dto/permission'
import { useDeletePermission, useUpdatePermission } from 'api/query/permission'
import capitalize from 'lodash/capitalize'
import { getInitials } from 'utils/initials'

interface UserPermissionProps {
  userPermission: Permission
}

export function UserPermission({ userPermission }: UserPermissionProps) {
  const { access: currentAccess, email, fullName } = userPermission
  const { mutateAsync: updatePermission } = useUpdatePermission()
  const { mutateAsync: deletePermission } = useDeletePermission()

  return (
    <Grid mb={20}>
      <Grid.Col span={1}>
        <Avatar size={40} alt={fullName} radius="sm" sx={{ justifySelf: 'center' }}>
          {getInitials(fullName || 'Not available')}
        </Avatar>
      </Grid.Col>
      <Grid.Col
        span={7}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Box>
          <Text m={0} fz={15} fw={700} style={{ width: '100%' }}>
            {fullName || 'Not available'}
          </Text>
          <Text fz={12}>{email}</Text>
        </Box>
      </Grid.Col>
      <Grid.Col
        span={3}
        display="flex"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          value={currentAccess}
          data={Object.values(Access).map((a) => ({ label: capitalize(a), value: a }))}
          onChange={(e) => {
            if (e) {
              void updatePermission({ ...userPermission, access: e as Access })
            }
          }}
          comboboxProps={{
            position: 'bottom',
            withinPortal: true,
          }}
        />
      </Grid.Col>

      <Grid.Col
        span={1}
        display="flex"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActionIcon c="red" onClick={() => deletePermission(userPermission)}>
          <IconTrash />
        </ActionIcon>
      </Grid.Col>
    </Grid>
  )
}
