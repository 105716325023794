import { Axis, LayerScale } from 'utils/scale/scale'
import { StateCreator } from 'zustand'

export interface ScaleSlice {
  scale: LayerScale
  scaleIsLocked: boolean
  scaleAxis?: Axis
  setScaleLockedState: (locked: boolean) => void
  setScale: (scale: LayerScale) => void
  resetScaleBoxState: () => void
  setScaleAxis: (axis: Axis) => void
}

export const createScaleSlice: StateCreator<ScaleSlice, [], [], ScaleSlice> = (set) => ({
  scale: new LayerScale(),
  scaleIsLocked: true,
  setScaleLockedState: (locked) => set(() => ({ scaleIsLocked: locked })),
  setScale: (scale: LayerScale) => set(() => ({ scale })),
  resetScaleBoxState: () => set(() => ({ scaleIsLocked: true })),
  setScaleAxis: (axis) => set(() => ({ scaleAxis: axis })),
})
