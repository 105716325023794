/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Text, Group, Popover, Button, Box, ActionIcon, UnstyledButton, TextInput, NumberInput } from '@mantine/core'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrawing } from 'hooks/useDrawing'
import { Axis } from 'utils/scale/scale'
import { PopoverTypes } from './types'
import { useClickOutside } from '@mantine/hooks'
import { IconLock, IconMinimize } from '@tabler/icons-react'
import { globalStore, takeoffStore } from 'store/store'
import { System } from 'api/dto'
import { ManualRepresentation } from 'utils/measure'
import { ColorScheme } from 'theme/theme'
import { useStore } from 'zustand'
import { useScaleToolbarStyles } from './scale.styles'
import { useParams } from 'react-router-dom'
import { useGetPage } from 'api/query/page'
import { useGetLayer } from 'api/query/layer'
import { TakeoffFeature } from 'api/dto/feature'

export const ScaleToolbar = () => {
  const { t } = useTranslation()
  const { projectId, pageId, layerId } = useParams()
  const { data: layer } = useGetLayer(projectId!, pageId!, layerId!)
  const { scale, scaleIsLocked, setLock } = useStore(takeoffStore, (state) => ({
    scale: state.scale,
    scaleIsLocked: state.scaleIsLocked,
    setLock: state.setScaleLockedState,
  }))
  const { classes } = useScaleToolbarStyles()
  const { popoverTypeOpened, togglePopover } = useStore(globalStore, (state) => ({
    popoverTypeOpened: state.popoverTypeOpened,
    togglePopover: state.togglePopover,
  }))
  const canEdit = layer?.hasAccess(TakeoffFeature.SCALE)
  const [isMinimized, setIsMinimized] = useState(canEdit ? scale.hasCalculatedScale() : true)
  const close = () => togglePopover(PopoverTypes.None)

  useEffect(() => {
    setIsMinimized(canEdit ? scale.hasCalculatedScale() : true)
  }, [scale, canEdit])

  return !isMinimized ? (
    <Group className={classes.box}>
      <Box>
        <Text>{t('Page.Scale')}</Text>
      </Box>
      <ManualScale
        axis={Axis.Horizontal}
        opened={popoverTypeOpened === PopoverTypes.ScaleX}
        setOpen={() => togglePopover(PopoverTypes.ScaleX)}
        close={close}
      />
      <ManualScale
        axis={Axis.Vertical}
        opened={popoverTypeOpened === PopoverTypes.ScaleY}
        setOpen={() => togglePopover(PopoverTypes.ScaleY)}
        close={close}
      />

      <ActionIcon variant="transparent" color={scaleIsLocked ? 'red' : 'gray'} onClick={() => setLock(!scaleIsLocked)}>
        <IconLock />
      </ActionIcon>
      <ActionIcon
        c="gray"
        variant="transparent"
        onClick={() => {
          setLock(true)
          setIsMinimized((val) => !val)
        }}
      >
        <IconMinimize />
      </ActionIcon>
    </Group>
  ) : (
    <Box
      sx={(theme) => ({
        cursor: canEdit ? 'pointer' : 'not-allowed',
        position: 'absolute',
        bottom: 0,
        backgroundColor: theme.colors[ColorScheme.customGray][5],
        color: theme.white,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        padding: '10px 15px',

        display: 'flex',
        alignItems: 'center',
      })}
      onClick={() => (canEdit ? setIsMinimized(!isMinimized) : null)}
    >
      <Text>{t('Page.Scale')}</Text>

      <Box
        ml={10}
        p={6}
        display="flex"
        sx={{
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <Text pr={5} size="sm" sx={{ borderRight: '1px solid #cecece' }}>
          X
        </Text>

        <Text size="sm" ml={7}>
          {getScaleRepresentationInString(scale?.manual.x)}
        </Text>
      </Box>

      <Box
        ml={10}
        p={6}
        display="flex"
        sx={{
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <Text pr={5} sx={{ borderRight: '1px solid #cecece' }} size="sm">
          Y
        </Text>

        <Text size="sm" ml={7}>
          {getScaleRepresentationInString(scale?.manual.y)}
        </Text>
      </Box>
    </Box>
  )
}

interface ScalePopoverProps {
  axis: Axis
  opened: boolean
  setOpen: () => void
  close: () => void
}

function ManualScale({ axis, opened, setOpen, close }: ScalePopoverProps) {
  const isLocked = useStore(takeoffStore, (state) => state.scaleIsLocked)
  const { scale } = useStore(takeoffStore, (state) => ({ scale: state.scale }))
  const { classes } = useScaleToolbarStyles()
  const scaleRepresentation = useMemo(() => {
    if (axis === Axis.Horizontal) {
      return getScaleRepresentationInString(scale?.manual.x)
    }

    return getScaleRepresentationInString(scale?.manual.y)
  }, [axis, scale])

  return (
    <Popover opened={opened} width={400} position="top" withArrow shadow="md" transitionProps={{ transition: 'pop' }}>
      <Popover.Target>
        <UnstyledButton
          w={180}
          className={classes.button}
          sx={{
            cursor: isLocked ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (opened) {
              close()
            } else {
              setOpen()
            }
          }}
          disabled={isLocked}
        >
          <Text fz={12} fw={700}>
            {axis === Axis.Horizontal ? 'X' : 'Y'}
          </Text>
          <Box sx={{ width: '1px', height: '20px', backgroundColor: '#DDDDDD', margin: '0px 7px' }} />
          <Text fz={14}>{scaleRepresentation}</Text>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown className={classes.popover}>
        <ScaleForm axis={axis} toggle={close} />
      </Popover.Dropdown>
    </Popover>
  )
}
interface ScaleFormProps {
  axis: Axis
  toggle: () => void
}

function ScaleForm({ axis, toggle }: ScaleFormProps) {
  const { t } = useTranslation()
  const { projectId, pageId } = useParams()
  const { data: page, isSuccess, isLoading } = useGetPage(projectId!, pageId!)

  const { scale } = useStore(takeoffStore, (state) => ({ scale: state.scale }))
  const [value, setValue] = useState<{ first?: number | string; second?: number | string; fraction?: { num?: number; den?: number } }>({
    first: axis === Axis.Horizontal ? scale.parsedX?.first : scale.parsedY?.first,
    second: axis === Axis.Horizontal ? scale.parsedX?.second : scale.parsedY?.second,
    fraction: {
      num: (axis === Axis.Horizontal ? scale.parsedX?.fraction?.num : scale.parsedY?.fraction?.num) || 0,
      den: (axis === Axis.Horizontal ? scale.parsedX?.fraction?.den : scale.parsedY?.fraction?.den) || 0,
    },
  })
  const { onStartScaleDraw, setManualScale } = useDrawing()
  const clickOutsideRef = useClickOutside(() => toggle())

  if (!isSuccess || isLoading) {
    return null
  }

  return (
    <Box ref={clickOutsideRef} display="flex" sx={{ justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
      <Text c="white" size="md" fw={400}>{`${t('Scale.InputScale') || ''} for ${axis} axis`}</Text>
      <Box mt={10} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box mr={5}>
          <NumberInput
            variant="outlined"
            value={value.first}
            label={t('Common.Feet')}
            radius="xs"
            styles={{
              label: {
                color: '#fff',
              },
              input: {
                outline: '1px solid #fff',
                color: '#fff',

                ':focus': {
                  outline: '1px solid #fff',
                },
              },
              control: {
                color: '#fff',
              },
            }}
            onChange={(e) => setValue((old) => ({ ...old, first: e ? Number(e) : undefined }))}
            min={0}
          />
        </Box>

        {page.isImperial && (
          <>
            <Box mr={5}>
              <NumberInput
                variant="outlined"
                label={t('Common.Inches')}
                value={value.second}
                radius="xs"
                styles={{
                  label: {
                    color: '#fff',
                  },
                  input: {
                    outline: '1px solid #fff',
                    color: '#fff',
                    ':focus': {
                      outline: '1px solid #fff',
                    },
                  },
                  control: {
                    color: '#fff',
                  },
                }}
                onChange={(e) => setValue((old) => ({ ...old, second: e ? Number(e) : undefined }))}
                min={0}
              />
            </Box>

            <Box ml={20} display="flex" sx={{ alignItems: 'flex-end' }}>
              <NumberInput
                label={t('Common.Fraction')}
                variant="outlined"
                value={value.fraction?.num}
                radius="xs"
                styles={{
                  label: {
                    color: '#fff',
                  },
                  input: {
                    outline: '1px solid #fff',
                    color: '#fff',
                    ':focus': {
                      outline: '1px solid #fff',
                    },
                  },
                  control: {
                    color: '#fff',
                  },
                }}
                onChange={(e) =>
                  setValue((old) => ({
                    ...old,
                    fraction: { den: old.fraction?.den || 1, num: e ? Number(e) : undefined },
                  }))
                }
                min={0}
              />

              <NumberInput
                variant="outlined"
                value={value.fraction?.den}
                ml={5}
                radius="xs"
                styles={{
                  input: {
                    outline: '1px solid #fff',
                    color: '#fff',
                    ':focus': {
                      outline: '1px solid #fff',
                    },
                  },
                  control: {
                    color: '#fff',
                  },
                }}
                onChange={(e) =>
                  setValue((old) => ({
                    ...old,
                    fraction: { den: e ? Number(e) : undefined, num: old.fraction?.num || 1 },
                  }))
                }
                min={0}
              />
            </Box>
          </>
        )}
      </Box>

      <Box mt={20} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="filled"
          sx={{
            backgroundColor: '#fff',
            color: '#000',

            ':hover': {
              backgroundColor: '#cecece',
              color: '#000',
            },
          }}
          disabled={value.first === undefined && value.second === undefined}
          onClick={async () => {
            const scale = {
              first: value.first ? value.first + ' ft' : undefined,
              second: value.second ? value.second + ' in' : undefined,
              fraction: assertFractionIsFiniteAndANumber(value.fraction) ? value.fraction : undefined,
            }
            setManualScale(axis, JSON.stringify(scale))
            await onStartScaleDraw(axis)
            toggle()
          }}
        >
          {t('Scale.SetScale')}
        </Button>
      </Box>
    </Box>
  )
}

function assertFractionIsFiniteAndANumber(fraction?: { num?: number; den?: number }) {
  if (!fraction) {
    return false
  }

  const num = fraction.num || 0
  const den = fraction.den || 0

  if (!isFinite(num / den)) {
    return false
  }

  if (isNaN(num / den)) {
    return false
  }

  return true
}

function getScaleRepresentationInString(scale?: string) {
  try {
    const arr: (number | string)[] = []
    const { first, fraction, second }: ManualRepresentation = JSON.parse(scale || '')
    if (first) {
      if (typeof first === 'string') {
        const split = first.split(' ')
        arr.push(`${split[0]}'`)
      } else {
        arr.push(first)
      }
    }
    if (second) {
      if (typeof second === 'string') {
        const split = second.split(' ')
        arr.push(`${split[0]}"`)
      } else {
        arr.push(second)
      }
    }

    if (fraction) {
      arr.push(fraction.num + '/' + fraction.den + ' "')
    }
    return arr.join(' ')
  } catch (error) {
    return ''
  }
}
