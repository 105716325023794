import { LayerScale } from 'utils/scale/scale'
import { Group, GroupDto } from './group'
import { TakeoffFeature } from './feature'

export interface ScaleDto {
  automatic: {
    chosenScale?: string
  }
  manual: {
    x?: string
    y?: string
  }
  calculated?: {
    xCalculated?: number
    yCalculated?: number
  }
}

export interface LayerDto {
  id: string
  projectId: string
  pageId: string
  organizationId: string

  name: string
  scale?: ScaleDto
  isOriginalLayer: boolean
  hasTakeoff?: boolean

  groups: GroupDto[]

  takeoffFeatures: TakeoffFeature[]
}

export interface CreateLayerDto {
  projectId: string
  pageId: string
  organizationId: string

  name: string
  scale?: ScaleDto
  isOriginalLayer: boolean
  hasTakeoff?: boolean
}
export interface UpdateLayerDto {
  id: string
  name: string
  scale?: ScaleDto
}

export class Layer {
  id: string
  projectId: string
  pageId: string
  organizationId: string

  name: string
  scale: LayerScale
  isOriginalLayer: boolean
  hasTakeoff?: boolean

  groups: Group[]
  takeoffFeatures: TakeoffFeature[]

  constructor(layerDto: LayerDto) {
    if (!layerDto) {
      this.id = ''
      this.projectId = ''
      this.pageId = ''
      this.organizationId = ''
      this.name = ''
      this.scale = new LayerScale()
      this.isOriginalLayer = false
      this.groups = []
      this.takeoffFeatures = []
      return
    }

    this.id = layerDto.id
    this.projectId = layerDto.projectId
    this.pageId = layerDto.pageId
    this.organizationId = layerDto.organizationId

    this.name = layerDto.name
    this.scale = new LayerScale(layerDto.scale)
    this.isOriginalLayer = layerDto.isOriginalLayer
    this.hasTakeoff = layerDto.hasTakeoff

    this.groups = layerDto.groups?.map((g) => new Group(g))
    this.takeoffFeatures = layerDto.takeoffFeatures
  }

  get hasCalculatedScale() {
    return this.scale.calculated != null
  }

  get hasGroupsWithCalculations() {
    return this.groups?.some((g) => g.isCalculatedGroup)
  }

  toUpdateDto(): UpdateLayerDto {
    return {
      id: this.id,
      name: this.name,
      scale: this.scale.toDto(),
    }
  }

  hasAccess(features: TakeoffFeature | TakeoffFeature[]) {
    if (Array.isArray(features)) {
      return features.every((s) => this.takeoffFeatures.includes(s))
    }

    return this.takeoffFeatures.includes(features)
  }
}
