import { nanoid } from 'nanoid'
import { Axis } from 'utils/scale/scale'
import { GroupCanvas } from '../group'
import { BuildFn, IPoint, ShapeTypes } from '../types'
import { Area, Count, Line } from './types'
import { Measure } from './types/measure'
import { Scale } from './types/scale'
import { CombinedShapeTypes, isMeasure, isShapeWithAnchors } from './types/utils'

export interface ShapeState {
  id?: string
  group: GroupCanvas
  color?: string
  stroke?: number
  points?: IPoint[]
  radius?: number
  index?: number
  axis?: Axis
}

export class ShapeBuilder<TState extends Partial<ShapeState>> {
  constructor(readonly state: TState) {}

  id(id: string): ShapeBuilder<TState & Pick<ShapeState, 'id'>> {
    return new ShapeBuilder({
      ...this.state,
      id,
    })
  }

  stroke(stroke?: number): ShapeBuilder<TState & Pick<ShapeState, 'stroke'>> {
    return new ShapeBuilder({
      ...this.state,
      stroke,
    })
  }

  group(group: GroupCanvas): ShapeBuilder<TState & Pick<ShapeState, 'group'>> {
    return new ShapeBuilder({
      ...this.state,
      group,
    })
  }

  axis(axis?: Axis): ShapeBuilder<TState & Pick<ShapeState, 'axis'>> {
    return new ShapeBuilder({
      ...this.state,
      axis,
    })
  }

  points(points: IPoint[]): ShapeBuilder<TState & Pick<ShapeState, 'points'>> {
    return new ShapeBuilder({
      ...this.state,
      points,
    })
  }

  radius(radius: number): ShapeBuilder<TState & Pick<ShapeState, 'radius'>> {
    return new ShapeBuilder({
      ...this.state,
      radius,
    })
  }

  index(index: number): ShapeBuilder<TState & Pick<ShapeState, 'index'>> {
    return new ShapeBuilder({
      ...this.state,
      index,
    })
  }

  build = (() => {
    const { id, points, group, stroke, radius, index, axis } = this.state as ShapeState
    let shape: CombinedShapeTypes

    switch (Number(group.type as unknown as string | number)) {
      case ShapeTypes.Area:
        shape = new Area({
          id: id || nanoid(),
          color: group.color,
          points: points || [],
          groupId: group.id,
          type: group.type,
          stroke: { color: group.color || '#000', width: stroke || 10 },
        })
        break

      case ShapeTypes.Count: {
        if (index === undefined) {
          throw new Error('Missing index')
        }

        shape = new Count(
          {
            id: id || nanoid(),
            color: group.color,
            points: points || [],
            groupId: group.id,
            type: group.type,
            stroke: { color: group.color || '#000', width: stroke },
            radius,
          },
          index,
        )
        break
      }

      case ShapeTypes.Measure:
        shape = new Measure({
          id: id || nanoid(),
          color: group.color,
          points: points || [],
          groupId: group.id,
          type: group.type,
          stroke: { color: group.color || '#000', width: stroke || 10 },
        })
        break

      case ShapeTypes.Scale: {
        if (axis === undefined) {
          throw new Error('Missing axis')
        }

        shape = new Scale(
          {
            id: id || nanoid(),
            color: group.color,
            points: points || [],
            groupId: group.id,
            type: group.type,
            stroke: { color: group.color || '#000', width: stroke || 10 },
          },
          axis || Axis.Horizontal,
        )
        break
      }

      case ShapeTypes.Line:
        shape = new Line({
          id: id || nanoid(),
          color: group.color,
          points: points || [],
          groupId: group.id,
          type: group.type,
          stroke: { color: group.color || '#000', width: stroke || 10 },
        })
        break

      default:
        throw new Error(group.type.toString())
    }

    group.addShape(shape)

    if (isShapeWithAnchors(shape)) {
      group.addShapeGroup(shape.anchorGroup)
    }

    if (isMeasure(shape)) {
      group.addShapeGroup(shape.label)
    }

    return shape
  }) as BuildFn<TState, ShapeState, CombinedShapeTypes>
}

export const shapeBuilder = () => new ShapeBuilder({})
