import Konva from 'konva'

export const ShapeTypes = {
  Count: 0,
  Area: 1,
  Line: 2,
  Measure: 4,
  Scale: 5,
  None: -1,
} as const

export type ShapeTypes = (typeof ShapeTypes)[keyof typeof ShapeTypes]
export interface IPoint {
  id: string
  x: number
  y: number
}

export type CalculationValue = {
  volume?: number
  area?: number
  perimeter?: number
  count?: number
}

export type Calculation = {
  volume?: string
  area?: string
  perimeter?: string
  count?: string
}

export interface IShape {
  id: string

  type: ShapeTypes
  color?: string
  stroke?: {
    width?: number
    color: string
  }
  radius?: number
  points: IPoint[]
  groupId: string
  calculation?: Calculation
  closed?: boolean
  instance?: Konva.Shape | Konva.Group
}
export interface IGroupCanvas {
  id: string
  type: ShapeTypes
  unit?: string
  name?: string
  color?: string
  depth?: string
  thickness?: string
  order: number

  factor?: number
  relatedGroupId?: string
  deleted?: boolean
  productId?: string
}

export interface ICountGroupCircle extends IGroupCanvas {
  color: string
  radius: number
}

export const PointerButton = {
  Left: 1,
  Right: 2,
  Middle: 4,
} as const

export const Units = {
  in: 1,
  ft: 2,
  cm: 3,
  m: 4,
} as const

export type BuildFn<TState, TExpected, TBuild = TExpected> = TState extends TExpected ? () => TBuild : never
