import { useEffect } from 'react'
import { useWebsocket } from './useWebsocket'
import { globalQueryClient } from 'api/client'

export const usePageUpdated = () => {
  const { result } = useWebsocket()

  useEffect(() => {
    if (!result) {
      return
    }

    if (result.isPageUpdated) {
      void globalQueryClient.invalidateQueries({
        queryKey: ['projects', result.message.projectId, 'pages', result.message.pageId],
      })
    }
  }, [result])
}
