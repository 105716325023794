import { useListMyProjects } from 'api/query/project'
import { Projects } from './projects'
import { Loading } from 'components/loading/loading'
import { ProjectView } from 'api/dto'

export function MyProjects() {
  const { isLoading, data: projects } = useListMyProjects()

  if (isLoading) {
    return <Loading height="100%" />
  }

  return <Projects view={ProjectView.MY_PROJECTS} projects={projects || []} isLoading={isLoading} />
}
