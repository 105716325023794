import { PopoverTypes } from 'components/toolbar/types'
import { StateCreator } from 'zustand'

export interface PopoverSlice {
  popoverTypeOpened: PopoverTypes
  togglePopover: (popoverType: PopoverTypes) => void
}

export const createPopoverSlice: StateCreator<PopoverSlice, [], [], PopoverSlice> = (set) => ({
  popoverTypeOpened: PopoverTypes.None,
  togglePopover: (type) => set(() => ({ popoverTypeOpened: type })),
})
