import { MantineThemeOverride } from '@mantine/core'

export enum ColorScheme {
  neutralGray = 'neutral-gray',
  neutralBlack = 'neutral-black',
  neutralWhite = 'neutral-white',
  redMagenta = 'red-magenta',
  blackShadow = 'black-shadow',
  customGray = 'custom-gray',
}

export const theme: MantineThemeOverride = {
  fontFamily: 'Articulat',
  primaryColor: 'dark',
  radius: {
    xxs: '4px',
    xs: '6px',
    sm: '8px',
    md: '10px',
    lg: '15px',
    xl: '20px',
  },
  defaultRadius: 'xs',
  breakpoints: {
    xs: '694px',
    sm: '834px',
    md: '1264px',
    lg: '1440px',
    xl: '1880px',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '18px',
    lg: '30px',
    xl: '40px',
  },
  colors: {
    // TODO sorry for the colors, scheme wasnt defined at the start so we might need to refactor
    [ColorScheme.customGray]: [
      '#F8F8F8',
      '#727272',
      '#333333',
      '#A3A3A3',
      '#E3E3E3',
      '#707070',
      '#575757',
      '#4D4D4D',
      '#4D4D4D',
      '#4D4D4D',
    ],
  },
}
