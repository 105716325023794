import { Layer } from 'konva/lib/Layer'
import { Group, Shape } from 'api/dto/group'
import { GroupCanvas } from 'canvas/group'
import { shapeBuilder } from 'canvas/shape/shape-builder'
import { ShapeTypes } from 'canvas/types'
import { CombinedShapeTypes } from 'canvas/shape/types/utils'
import { getShapeStroke } from 'utils/shape-stroke'

export class TakeoffFactory {
  static createCanvasGroup(group: Group, layer: Layer): GroupCanvas {
    const type = Number.parseInt(group.type) as ShapeTypes
    const newGroup = new GroupCanvas({
      ...group,
      type,
    })

    const shapes = group.shapes

    if (shapes) {
      shapes.forEach((shape, index) => {
        this.createCanvasShape(shape, newGroup, index + 1)
      })
    }

    layer.add(newGroup.instance)
    layer.draw()
    return newGroup
  }

  static createCanvasShape(shape: Shape, group: GroupCanvas, index: number): CombinedShapeTypes {
    const stroke = getShapeStroke(group.type)
    return shapeBuilder().id(shape.id).points(shape.points).stroke(stroke).group(group).index(index).axis(shape.axis).build()
  }
}
