/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Group, Modal, Text, Box, Checkbox, Progress, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { ProjectDto, Project, ProjectView, System } from 'api/dto/project'
import { fileAxios } from 'api/file/file-axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UploadPdf } from 'components/dropzone/dropzone'
import { ColorScheme } from 'theme/theme'
import { IconPlus } from '@tabler/icons-react'
import { showNotification } from '@mantine/notifications'
import { AxiosProgressEvent } from 'axios'
import { useCreateProject, useGetProjectSignedUrl } from 'api/query/project'

interface AddProjectModalProps {
  withTile?: boolean
  organizationId?: string
  view: ProjectView
}
interface FormProps {
  name: string
  system: System
  organizationId?: string
}

export function AddProject({ withTile, organizationId, view }: AddProjectModalProps) {
  const navigate = useNavigate()
  const [file, setFile] = useState<File | null>()
  const { t } = useTranslation()
  const { mutateAsync: createProject } = useCreateProject()
  const { mutateAsync: getSignedUrl } = useGetProjectSignedUrl()
  const [opened, showAddProjectModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercentageCompleted, setUploadPercentageCompleted] = useState<number | null>(null)
  const form = useForm<FormProps>({
    initialValues: {
      name: '',
      system: System.IMPERIAL,
      organizationId,
    },
    validate: {
      name: (value) => (value ? null : 'Invalid name'),
    },
  })

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 100))
    setUploadPercentageCompleted(percentCompleted)
  }

  const formSubmit = useCallback(
    async (values: FormProps) => {
      const { name, system, organizationId } = values
      const result = await createProject({ name, system, organizationId } as ProjectDto)
      const projectId = result.data.id

      if (file && projectId) {
        const response = await getSignedUrl({ projectId, fileName: file.name })

        if (response) {
          await fileAxios.put(response.data, file, {
            headers: {
              'content-type': file.type,
            },
            onUploadProgress,
          })
        }
      }

      if (projectId) {
        let url = `/app/projects/${projectId}?polling=true`
        if (view === ProjectView.ORGANIZATION) {
          url = `/app/organization-projects/${projectId}?polling=true`
        }

        navigate(url)
      }

      showNotification({
        message: `Project '${name}' created successfully`,
        color: 'green',
      })

      showAddProjectModal(false)
      form.reset()
      setFile(null)
    },
    [createProject, file, form, getSignedUrl, navigate, view],
  )

  useEffect(() => {
    if (organizationId) {
      form.setFieldValue('organizationId', organizationId)
    } else {
      form.setFieldValue('organizationId', undefined)
    }

    if (!opened) {
      form.reset()
      setFile(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return (
    <>
      {withTile && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: '241px',
            height: '154px',
            borderRadius: theme.radius.xs,
            backgroundColor: theme.colors[ColorScheme.customGray][1],
          })}
          onClick={() => showAddProjectModal(true)}
        >
          <Box mb={5}>
            <IconPlus color="white" width={50} height={50} />
          </Box>
          <Text c="white" size="sm" fw={400} sx={{ textTransform: 'uppercase' }}>
            {organizationId ? t('Project.CreateProjectInOrganization') : t('Project.CreateProject')}
          </Text>
        </Box>
      )}
      {!withTile && (
        <Button size="lg" radius="xxs" onClick={() => showAddProjectModal(true)}>
          <Text size="md" fw={400}>
            {organizationId ? t('Project.CreateProjectInOrganization') : t('Project.CreateProject')}
          </Text>
        </Button>
      )}

      <Modal
        opened={opened}
        size="lg"
        radius="xxs"
        withCloseButton={!isUploading}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => {
          showAddProjectModal(false)
          form.reset()
          setFile(null)
        }}
        title={
          <Text fz={20} lh="30px" fw={400}>
            {t('Project.AddProject')}
          </Text>
        }
      >
        <form
          onSubmit={form.onSubmit(async (val) => {
            setIsUploading(true)
            await formSubmit(val)
            setIsUploading(false)
          })}
        >
          <Box display="flex" mt={10} sx={{ flexFlow: 'column', marginBottom: '30px' }}>
            <TextInput
              mb={10}
              label={t('Project.ProjectName')}
              placeholder={t('Project.EnterProjectName') || ''}
              type="text"
              withAsterisk
              size="sm"
              radius="xs"
              {...form.getInputProps('name')}
            />

            <Select
              size="sm"
              label={t('Project.ProjectUnitSystem')}
              withAsterisk
              data={[{ value: System.IMPERIAL, label: t('Unit.Imperial') }]}
              {...form.getInputProps('system')}
            />
          </Box>

          <Box>
            <UploadPdf onDrop={(files) => setFile(files[0])} />
          </Box>

          {uploadPercentageCompleted && (
            <Box mb={10} mt={10} display="flex" sx={{ flexFlow: 'column', alignItems: 'center' }}>
              <Text size="sm" fw={400}>
                Uploading file... {uploadPercentageCompleted}% completed
              </Text>
              <Progress w="100%" striped animated value={uploadPercentageCompleted} color="teal" size="sm" mt={10} mb={10} />
            </Box>
          )}

          <Group justify="right" mt="md">
            <Button size="xs" disabled={isUploading || !file || !form.isValid()} radius="xxs" loading={isUploading} type="submit">
              {t('Common.Create')}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}
