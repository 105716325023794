import { Role } from './role'

export interface ITokenUserDto {
  id: string
  email: string
  isAdmin: boolean
  organizationId?: string
  currentOrgId?: string
  organizations?: { id: string; name: string }[]
  role?: Role
}

export class UserToken implements ITokenUserDto {
  id: string
  email: string
  isAdmin: boolean
  organizationId?: string
  currentOrgId?: string
  role?: Role

  constructor(userToken: ITokenUserDto) {
    this.id = userToken.id
    this.email = userToken.email
    this.isAdmin = userToken.isAdmin
    this.organizationId = userToken.organizationId
    this.currentOrgId = userToken.currentOrgId
    this.role = userToken.role
  }

  isOrgAdmin(): boolean {
    return this.role === Role.ADMIN
  }

  hasCreatedAnOrganization(): boolean {
    return !!this.organizationId
  }
}

export interface IUserDto {
  id: string
  email: string
  firstName: string
  lastName: string
  customSettings: CustomSettings
  currentOrgId?: string
  currentOrgName?: string
  registeredAt: string
  role?: Role
  status: string
  organizations?: { id: string; name: string }[]
}

export interface CustomSettings {
  zoom: number
}

export class UserDto implements IUserDto {
  id: string
  email: string
  firstName: string
  lastName: string
  customSettings: CustomSettings
  currentOrgId?: string
  currentOrgName?: string
  registeredAt: string
  role?: Role
  status: string
  organizations?: { id: string; name: string }[]

  constructor(userDto: IUserDto) {
    this.id = userDto.id
    this.email = userDto.email
    this.firstName = userDto.firstName
    this.lastName = userDto.lastName
    this.customSettings = userDto.customSettings
    this.currentOrgId = userDto.currentOrgId
    this.currentOrgName = userDto.currentOrgName
    this.registeredAt = userDto.registeredAt
    this.role = userDto.role
    this.status = userDto.status
    this.organizations = userDto.organizations
  }

  get isActive(): boolean {
    return this.status === 'ACTIVE'
  }

  get isInactive(): boolean {
    return this.status === 'INACTIVE'
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  getInitials(): string {
    return this.firstName[0] + this.lastName[0]
  }

  hasOrganizationsToSwitch() {
    const filtered = this.organizations?.filter((o) => o.id !== this.currentOrgId)
    return filtered && filtered.length > 0
  }
}

export interface CreateUser {
  user: {
    firstName: string
    lastName: string
    email: string
    organizationId?: string
    role?: Role
  }
}

export interface AddUserToOrganization {
  user: {
    firstName: string
    lastName: string
    email: string
  }
}

export interface AdminUpdateUser {
  id: string
  user: {
    firstName?: string
    lastName?: string
    role?: Role
    orgId?: string
  }
}

export interface AdminForcePassword {
  email: string
  password: string
}

export interface UpdateUser {
  user: {
    firstName?: string
    lastName?: string
  }
}
