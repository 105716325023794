import { Group, Button, Text, Box, Image, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import Plan from 'assets/img/plan.png'
import Logo from 'assets/img/ttlogowithnamewhite.svg?react'
import MailIcon from 'assets/icons/envelope.svg?react'

interface FormValues {
  email: string
}

export const ForgotPassword = () => {
  const { forgotPassword } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    await forgotPassword(values.email)
    setIsLoading(false)
  }

  return (
    <Box display="flex" w="100%">
      <Box
        w={416}
        bg="black"
        p={30}
        mx="auto"
        sx={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', color: 'white', overflow: 'hidden' }}
      >
        <Box sx={{ position: 'absolute', top: '-100px', left: '-70px', right: 0, bottom: 0, height: '100%', width: '100vw' }}>
          <Image src={Plan} alt="plan" height="100%" width="100%" />
        </Box>
        <Logo width={100} height={70} />
        <Box w="100%" pt={150} display="flex" sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', flexFlow: 'column' }}>
          <Text sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>Already have </Text>
          <Text mb={20} sx={{ fontSize: '35px', lineHeight: '42px', fontWeight: 600 }}>
            an account?
          </Text>
          <Text mb={211} sx={{ fontSize: '20px', lineHeight: '24px' }}>
            Welcome back!
          </Text>
          <Button
            variant="filled"
            onClick={() => navigate('/session/login')}
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              color: 'white',
              fontWeight: 400,
              borderRadius: '4px',
              width: '216px',
              height: '50px',
              borderColor: 'white',
            }}
          >
            Sign in here
          </Button>
        </Box>
      </Box>
      <Box display="flex" sx={{ flex: 1, justifyContent: 'center', flexFlow: 'column', zIndex: 100 }}>
        <Box mb={50} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text size="xl">{t('Authentication.ForgotPassword')}</Text>
        </Box>

        <Box display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
          <form style={{ width: '80%' }} onSubmit={form.onSubmit(onSubmit)}>
            <Group mb={10} sx={{ justifyContent: 'center' }}>
              <TextInput
                maw={400}
                sx={{ flex: 1 }}
                leftSection={<MailIcon width={20} height={20} />}
                withAsterisk
                label={t('User.Email')}
                {...form.getInputProps('email')}
              />
            </Group>

            <Group justify="center" sx={{ marginTop: 30 }}>
              <Button w={208} sx={{ height: 50, borderRadius: '4px' }} type="submit" loading={isLoading}>
                <Text sx={{ fontSize: '18px', lineHeight: '22px', fontWeight: 400 }}>{t('Authentication.SendCode')}</Text>
              </Button>
            </Group>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
