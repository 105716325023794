import { Button, Group, Modal, Text, Box, TextInput, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { User, UserDto } from 'api/dto'
import { Role } from 'api/dto/role'
import { showNotification } from '@mantine/notifications'
import { useGetOrganizations } from 'api/query/organization'
import { useUpdateUser } from 'api/query/user'

interface EditUserProps {
  opened: boolean
  toggle: () => void
  user?: User
}

interface FormProps {
  email?: string
  firstName?: string
  lastName?: string
  orgId?: string
  role?: Role
}

export function EditUser({ opened, toggle, user }: EditUserProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()
  const { data: organizations } = useGetOrganizations()
  const { mutateAsync: updateUser } = useUpdateUser()
  const form = useForm<FormProps>({
    initialValues: {
      ...user,
    },
  })

  const formSubmit = useCallback(
    async (values: FormProps) => {
      if (!user?.id) {
        return
      }

      setIsSubmitting(true)

      // TODO fix typing
      const updatedUser: UserDto = {
        id: user.id,
        ...values,
      } as any

      await updateUser(updatedUser)

      showNotification({
        title: t('User.UserUpdated'),
        message: t('User.UserUpdatedSuccessfully'),
        color: 'green',
      })

      toggle()
      setIsSubmitting(false)
      form.reset()
    },
    [toggle, form, t, user],
  )

  useEffect(() => {
    if (opened) {
      form.setValues({
        ...user,
      })
    } else {
      form.reset()
    }
    // we dont want to trigger a setValues every time the form changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, opened])

  return (
    <Modal
      opened={opened}
      size="lg"
      radius="xxs"
      onClose={toggle}
      title={
        <Text fz={20} lh="30px" fw={400}>
          {t('User.EditUser')}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
        <Box display="flex" mt={10} mb={10} sx={{ flexFlow: 'row', marginBottom: '30px' }}>
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            label={t('User.FirstName')}
            placeholder={t('User.FirstName') || ''}
            type="text"
            {...form.getInputProps('firstName')}
          />

          <TextInput
            withAsterisk
            sx={{ marginLeft: '10px', flex: 1 }}
            label={t('User.LastName')}
            placeholder={t('User.LastName') || ''}
            type="text"
            {...form.getInputProps('lastName')}
            error={undefined}
          />
        </Box>
        <Box mb={10} display="flex">
          <TextInput
            sx={{ flex: 1 }}
            withAsterisk
            label={t('User.Email')}
            placeholder={t('User.Email') || ''}
            type="text"
            {...form.getInputProps('email')}
            error={undefined}
          />
        </Box>
        {/* <Box mb={10} display="flex">
          <FormControl fullWidth>
            <InputLabel id="select-org">{t('User.Organization')}</InputLabel>

            <Select
              sx={{ flex: 1 }}
              label={t('User.Organization')}
              labelId="select-org"
              value={form.values.orgId || ''}
              onChange={(e) => {
                form.setFieldValue('orgId', e.target.value)
              }}
            >
              {organizations.map((organization) => (
                <MenuItem value={organization.id}>{organization.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box> */}
        {form.values.orgId && (
          <Box mb={20} display="flex">
            <Select
              sx={{ flex: 1 }}
              label={t('User.Role')}
              withAsterisk
              value={form.values.role || ''}
              onChange={(e) => {
                form.setFieldValue('role', e as Role)
              }}
              data={[
                { value: Role.USER, label: t('User.UserRole.User') },
                { value: Role.ADMIN, label: t('User.UserRole.Admin') },
              ]}
            />
          </Box>
        )}
        <Group justify="right" mt="md">
          <Button size="sm" radius="xxs" loading={isSubmitting} disabled={isSubmitting} type="submit">
            {t('Common.Update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
