import { Box, Button, Text } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { showNotification } from '@mantine/notifications'
import { Project } from 'api/dto'
import { ProjectService } from 'api/services/project-service'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

enum SortBy {
  TYPE = 'TYPE',
  CREATED_AT = 'CREATED_AT',
}

const useExportStyles = createStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    padding: '20px',
    bottom: 0,
    left: 0,
    backgroundColor: '#f3f0e0',
    zIndex: 5,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface ExportProps {
  pages: string[]
  reset: () => void
  project: Project
}

export function Export({ project, pages, reset }: ExportProps) {
  const { t } = useTranslation()
  const { classes } = useExportStyles()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Box className={classes.container}>
      <Box className={classes.buttons}>
        {/* <Button
          ml={20}
          size="xs"
          radius="xxs"
          color="dark"
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true)
            // TODO
            const res = await ProjectService.generateProjectReport(project?.id || '', pages)
            const pdf = res.data?.exportProject

            if (!pdf) {
              showNotification({
                title: t('Project.PDFExportFailed'),
                message: t('Project.PDFExportFailedMessage'),
                color: 'red',
              })
              setIsLoading(false)
              return
            }

            const linkSource = `data:application/pdf;base64,${pdf}`
            const downloadLink = document.createElement('a')
            const projectName = project?.name || 'NA'
            const fileName = `${projectName}-${dayjs().format('YYYY-MM-DD')}.pdf`
            downloadLink.href = linkSource
            downloadLink.download = fileName
            downloadLink.click()
            setIsLoading(false)
          }}
        >
          <Text>{t('Project.GenerateReport')}</Text>
        </Button> */}
        <Button
          // ml={10}
          size="xs"
          radius="xxs"
          color="dark"
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true)
            try {
              const res = await ProjectService.generateProjectReport(project?.id || '', pages, 'csv')
              const csv = res.data

              if (!csv) {
                showNotification({
                  title: t('Project.CSVExportFailed'),
                  message: t('Project.CSVExportFailedMessage'),
                  color: 'red',
                })
                setIsLoading(false)
                return
              }

              const linkSource = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
              const downloadLink = document.createElement('a')
              const projectName = project?.name || 'NA'
              const fileName = `${projectName}-${dayjs().format('YYYY-MM-DD')}.csv`
              downloadLink.href = linkSource
              downloadLink.download = fileName
              downloadLink.click()
              setIsLoading(false)
            } catch (error) {
              setIsLoading(false)
              return
            }
          }}
        >
          <Text>{t('Project.GenerateReportCSV')}</Text>
        </Button>
      </Box>
      <Box>
        <Button radius="xxs" size="xs" variant="subtle" onClick={reset}>
          <Text> {t('Common.Cancel')}</Text>
        </Button>
      </Box>
    </Box>
  )
}
