import { Box, Button, Tabs, Text, TextInput, Textarea } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '../api/dto'
import { AddUser } from '../components/modals/add-user.modal'
import { Table } from '../components/table/table'
import { PageTitle } from 'components/title/page-title'
import { useForm } from '@mantine/form'
import { ChangeRole } from 'components/modals/change-role.modal'
import { showNotification } from '@mantine/notifications'
import { useGetMeUser, useGetUsersInOrganization } from 'api/query/user'
import { ColumnDef } from '@tanstack/table-core'
import { useGetOrganization, useRemoveUserInOrganization, useUpdateOrganization } from 'api/query/organization'

interface OrganizationValues {
  name: string
  description?: string
}

const useOrganizationSettingsStyles = createStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export const OrganizationSettings = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetMeUser()
  const { classes } = useOrganizationSettingsStyles()
  const [opened, setOpened] = useState(false)
  const [user, setUser] = useState<User | undefined>(undefined)
  const { refetch, data: users } = useGetUsersInOrganization()
  const { mutateAsync: removeFromOrganization } = useRemoveUserInOrganization()

  const columns: ColumnDef<User>[] = useMemo(
    () =>
      [
        {
          id: 'name',
          minSize: 200,
          maxSize: 200,
          header: t('User.FullName'),
          accessorKey: 'fullName',
        },
        {
          id: 'email',
          minSize: 400,
          maxSize: 400,
          header: t('User.Email'),
          accessorKey: 'email',
        },
        {
          id: 'role',
          minSize: 100,
          maxSize: 100,
          header: t('User.Role'),
          accessorKey: 'role',
          cell: (p) => {
            const role = p.row.original.role
            return t(`Role.${role}`)
          },
        },
        {
          id: 'actions',
          minSize: 150,
          maxSize: 150,
          header: t('User.Actions'),
          cell: (p) => {
            const user = p.row.original
            if (user.id === currentUser?.id) return

            return (
              <Box display="flex">
                <Button
                  size="xs"
                  radius="xxs"
                  variant="outline"
                  color="blue"
                  onClick={() => {
                    setUser(user)
                    setOpened(true)
                  }}
                >
                  {t('Organization.UpdateRole')}
                </Button>
                <Button
                  radius="xxs"
                  ml={10}
                  size="xs"
                  variant="outline"
                  color="red"
                  onClick={async () => {
                    await removeFromOrganization(user.id)
                  }}
                >
                  {t('Organization.RemoveFromOrganization')}
                </Button>
              </Box>
            )
          },
        },
      ] as ColumnDef<User>[],
    [t, removeFromOrganization, currentUser],
  )

  return (
    <>
      <Box className={classes.title} mb={30}>
        <PageTitle title={t('Pages.Title.Organization')} />
      </Box>
      <Tabs defaultValue="users">
        <Tabs.List mb={30}>
          <Tabs.Tab value="users">{t('Organization.Users')}</Tabs.Tab>
          <Tabs.Tab value="infos">{t('Organization.Infos')}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="users" sx={{ position: 'relative' }}>
          <Table<User> columns={columns as never} data={users || []} actionButtons={() => [<AddUser />]} />
        </Tabs.Panel>
        <Tabs.Panel value="infos">
          <OrganizationInfos />
        </Tabs.Panel>
      </Tabs>
      <ChangeRole opened={opened} user={user} toggle={() => setOpened(false)} />
    </>
  )
}

function OrganizationInfos() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { isLoading: isLoadingOrg, data: organization } = useGetOrganization()
  const { mutateAsync: updateOrganization } = useUpdateOrganization()

  const form = useForm<OrganizationValues>({
    initialValues: {
      name: '',
      description: '',
    },
  })

  useEffect(() => {
    if (isLoadingOrg) {
      return
    }
    form.setValues({
      name: organization?.name,
      description: organization?.description,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrg, organization])

  const onSubmit = useCallback(
    async (values: OrganizationValues) => {
      setIsLoading(true)
      if (organization) {
        await updateOrganization({ ...values })
        showNotification({
          title: t('Organization.OrganizationUpdated'),
          message: t('Organization.OrganizationUpdatedSuccessfully'),
          color: 'green',
        })
      }
      setIsLoading(false)
    },
    [organization, t, updateOrganization],
  )

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box mb={30} display="flex" sx={{ flexDirection: 'column', maxWidth: '400px' }}>
        <TextInput
          mb={10}
          withAsterisk
          label={t('Organization.Name')}
          placeholder={t('Organization.Name') || ''}
          {...form.getInputProps('name')}
        />
        <Textarea
          label={t('Organization.Description') || ''}
          placeholder={t('Organization.Description') || ''}
          rows={4}
          {...form.getInputProps('description')}
        />
      </Box>
      <Button radius="xxs" loading={isLoading} type="submit" size="sm">
        {t('Common.Update')}
      </Button>
    </form>
  )
}
