import { IPoint } from '../../canvas/types'
import { convertManualRepresentation, convertToFactor } from '../measure/measure'
import { ScaleFactor } from '../scale/scale'

export function getDistanceBetweenTwoPoints(a: IPoint, b: IPoint) {
  return Math.hypot(b.x - a.x, b.y - a.y)
}

/**
 * Calculate the volume of a shape given the area and depth
 *
 * @param area the area of the shape
 * @param depth the depth of the shape
 * @returns an integer representing the volume of the shape in IN^3
 */
export function calculateVolume(area: number, depth: string) {
  const factor = convertToFactor(convertManualRepresentation(JSON.parse(depth)))
  return area * factor
}

/**
 * Calculate the area of a shape given the perimeter and height
 *
 * @param perimeter the perimeter of the shape
 * @param height the height of the shape
 * @returns an integer representing the area of the shape in IN^2
 */

export function calculateAreaWithHeight(perimeter: number, height: string) {
  const factor = convertToFactor(convertManualRepresentation(JSON.parse(height)))
  return perimeter * factor
}

/**
 * Calculate the volume of a shape given the area and thickness
 *
 * @param area the area of the shape
 * @param thickness the thickness of the shape
 * @returns an integer representing the volume of the shape in IN^3
 */

export function calculateVolumeWithThickness(area: number, thickness: string) {
  const factor = convertToFactor(convertManualRepresentation(JSON.parse(thickness)))
  return area * factor
}

export function calculateArea(points: IPoint[], scale: ScaleFactor) {
  let area = 0
  const { x: xScale, y: yScale } = scale

  for (let i = 0; i < points.length; i++) {
    const { x: x1, y: y1 } = points[i]
    const { x: x2, y: y2 } = points[(i + 1) % points.length]

    area += x1 * xScale * (y2 * yScale) - x2 * xScale * (y1 * yScale)
  }

  return area / 2
}

export function calculatePerimeter(points: IPoint[], scale: ScaleFactor, isClosed: boolean) {
  let perimeter = 0

  const { x: xScale, y: yScale } = scale

  for (let i = 0; i < points.length; i++) {
    const { x: x1, y: y1 } = points[i]
    const p2 = isClosed && points[i + 1] == null ? points[0] : points[i + 1]

    if (p2 != null) {
      const { x: x2, y: y2 } = p2

      perimeter += Math.abs(Math.hypot(x2 * xScale - x1 * xScale, y2 * yScale - y1 * yScale))
    }
  }

  return perimeter
}
