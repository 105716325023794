import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { PageDto, Page } from 'api/dto'
import axios from 'config/axios'
import environment from 'config/environment'
import { useCallback } from 'react'

export const pagesLoader = ({ params }: { params: Record<string, any> }) => {
  const query = listPageQuery(params.projectId)
  return globalQueryClient.ensureQueryData(query)
}

export const listPages = async (projectId: string) => {
  const { data } = await axios.get<PageDto[]>(
    environment.restApi.resourceUrl.projects + '/' + projectId + environment.restApi.resourceUrl.pages,
  )
  return data
}

const listPageQuery = (projectId: string) => ({
  queryKey: ['projects', projectId, 'pages'],
  queryFn: () => listPages(projectId),
})

export const useListPages = (projectId: string) => {
  return useQuery({
    ...listPageQuery(projectId),
    select: (response) => response.map((p) => new Page({ ...p, projectId })),
  })
}

const getPage = async (projectId: string, pageId: string) => {
  const { data } = await axios.get<PageDto>(
    environment.restApi.resourceUrl.projects + '/' + projectId + environment.restApi.resourceUrl.pages + '/' + pageId,
  )
  return data
}

export const getPageQuery = (projectId: string, pageId: string) => ({
  queryKey: ['projects', projectId, 'pages', pageId],
  queryFn: () => getPage(projectId, pageId),
})

export const pageLoader = ({ params }: { params: Record<string, any> }) => {
  const query = getPageQuery(params.projectId, params.pageId)
  return globalQueryClient.ensureQueryData(query)
}

export const useGetPage = (projectId: string, pageId: string) => {
  return useQuery({
    ...getPageQuery(projectId, pageId),
    select: useCallback((response) => new Page({ ...response, projectId }), []),
  })
}

export const useCreatePage = () => {
  return useMutation({
    mutationFn: ({ projectId, page }: { projectId: string; page: PageDto }) => {
      return axios.post(environment.restApi.resourceUrl.projects + '/' + projectId + environment.restApi.resourceUrl.pages, page)
    },
  })
}

export const useUpdatePage = () => {
  return useMutation({
    mutationFn: ({ projectId, page }: { projectId: string; page: PageDto }) => {
      const dto = {
        name: page.name,
        description: page.description,
        order: page.order,
      }

      return axios.put('/projects/' + projectId + '/pages/' + page.id, dto)
    },
    onMutate: async ({ projectId, page }: { projectId: string; page: PageDto }) => {
      await globalQueryClient.cancelQueries({
        queryKey: ['projects', projectId, 'pages', page.id],
      })
      const previousPage = globalQueryClient.getQueryData<PageDto>(['projects', projectId, 'pages', page.id])
      globalQueryClient.setQueryData<PageDto>(['projects', projectId, 'pages', page.id], (oldPage) => {
        const newPage = new Page({ ...oldPage, ...page })
        return newPage
      })

      globalQueryClient.setQueryData<PageDto[]>(['projects', projectId, 'pages'], (oldPages) => {
        return oldPages?.map((p) => {
          if (p.id === page.id) {
            const newPage = new Page({ ...p, ...page })
            return newPage
          }
          return p
        })
      })

      return { previousPage }
    },
    onError: (error, variables, context) => {
      if (!context?.previousPage) {
        return
      }

      globalQueryClient.setQueryData<PageDto>(['projects', variables.projectId, 'pages', variables.page.id], context?.previousPage)

      globalQueryClient.setQueryData<PageDto[]>(['projects', variables.projectId, 'pages'], (oldPages) => {
        return oldPages?.map((p) => {
          if (p.id === variables.page.id) {
            return context.previousPage!
          }
          return p
        })
      })
    },
  })
}

export const useDeletePage = () => {
  return useMutation({
    mutationFn: ({ projectId, pageId }: { projectId: string; pageId: string }) => {
      return axios.delete(environment.restApi.resourceUrl.projects + '/' + projectId + environment.restApi.resourceUrl.pages + '/' + pageId)
    },
    onMutate: async ({ projectId, pageId }: { projectId: string; pageId: string }) => {
      await globalQueryClient.cancelQueries({
        queryKey: ['projects', projectId, 'pages'],
      })

      const previousPages = globalQueryClient.getQueryData<PageDto[]>(['projects', projectId, 'pages'])

      globalQueryClient.setQueryData<PageDto[]>(['projects', projectId, 'pages'], (oldPages) => {
        return oldPages?.filter((p) => p.id !== pageId)
      })

      return { previousPages }
    },
    onError: (error, variables, context) => {
      if (!context?.previousPages) {
        return
      }

      globalQueryClient.setQueryData<PageDto[]>(['projects', variables.projectId, 'pages'], context?.previousPages)
    },
    onSettled: (_, _error, { projectId }) => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'pages'],
      })
    },
  })
}

export const useListRecentPages = () => {
  return useQuery({
    queryKey: ['pages', 'recent'],
    queryFn: async () => {
      const { data } = await axios.get<PageDto[]>(environment.restApi.resourceUrl.pages + '/recent')
      return data
    },
    select: (response) => response.map((p) => new Page(p)),
  })
}
