import { ActionIcon, Box, Button, Group } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'api/dto'
import { useActivateUser, useDeactivateUser, useGetUsers } from 'api/query/user'
import { ForcePassword } from 'components/admin/force-password.modal'
import { AdminCreateUser } from 'components/modals/admin-create-user.modal'
import { EditUser } from 'components/modals/edit-user.modal'
import { Table } from 'components/table/table'
import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconPassword, IconAdjustmentsCog, IconBulbOff, IconBulb } from '@tabler/icons-react'

export function Users() {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [editUserOpened, setEditUserOpened] = useState(false)
  const [forcePasswordOpened, setForcePasswordOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const { data: users } = useGetUsers()
  const { mutateAsync: activate } = useActivateUser()
  const { mutateAsync: deactivate } = useDeactivateUser()

  const activateUser = useCallback(
    (userId: string) => {
      return activate(userId)
    },
    [activate],
  )

  const deactivateUser = useCallback(
    (userId: string) => {
      return deactivate(userId)
    },
    [deactivate],
  )

  const columns: ColumnDef<User>[] = useMemo(
    () =>
      [
        {
          id: 'fullName',
          header: t('User.FullName'),
          accessorKey: 'fullName',
          width: '15%',
        },
        {
          id: 'email',
          header: t('User.Email'),
          accessorKey: 'email',
          width: '30%',
        },
        {
          id: 'currentOrgName',
          header: t('User.Organization'),
          accessorKey: 'currentOrgName',
          width: '20%',
        },
        {
          id: 'registeredAt',
          header: t('User.RegisteredAt'),
          accessorFn: (p) => dayjs(p.registeredAt).format('YYYY-MM-DD'),
          width: '10%',
        },
        {
          id: 'role',
          header: t('User.Role'),
          accessorKey: 'role',
          accessor: (p) => capitalize(p.role),
          width: '10%',
        },
        {
          id: 'actions',
          header: t('User.Actions'),
          // delete
          cell: (r) => {
            const user = r.row.original

            return (
              <Group>
                {user.isActive && (
                  <ActionIcon size="md" variant="outline" color="red" onClick={() => deactivateUser(user.id)}>
                    <IconBulbOff />
                  </ActionIcon>
                )}
                {user.isInactive && (
                  <ActionIcon size="md" variant="outline" color="green" onClick={() => activateUser(user.id)}>
                    <IconBulb />
                  </ActionIcon>
                )}
                <ActionIcon
                  size="md"
                  variant="outline"
                  color="blue"
                  onClick={() => {
                    setSelectedUser(user)
                    setEditUserOpened(true)
                  }}
                >
                  <IconAdjustmentsCog />
                </ActionIcon>
                <ActionIcon
                  size="md"
                  variant="outline"
                  color="blue"
                  onClick={() => {
                    setSelectedUser(user)
                    setForcePasswordOpened(true)
                  }}
                >
                  <IconPassword />
                </ActionIcon>
              </Group>
            )
          },
        },
      ] as ColumnDef<User>[],
    [t, activateUser, deactivateUser],
  )

  return (
    <Box>
      <Table<User>
        columns={columns}
        data={users || []}
        // actionButtons={[
        //   <Button size="xs" variant="outline" onClick={() => setOpened(true)}>
        //     {t('User.CreateUser')}
        //   </Button>,
        // ]}
      />
      <AdminCreateUser opened={opened} toggle={() => setOpened(false)} />
      <EditUser user={selectedUser} opened={editUserOpened} toggle={() => setEditUserOpened(false)} />
      <ForcePassword user={selectedUser} opened={forcePasswordOpened} toggle={() => setForcePasswordOpened(false)} />
    </Box>
  )
}
