import dayjs from 'dayjs'

export function formatMoment(lastEdited: string): string {
  const editedDate = dayjs(lastEdited)
  if (!editedDate.isValid()) {
    return ''
  }

  const currentDate = dayjs()
  const diffInMinutes = currentDate.diff(editedDate, 'minute')
  const diffInHours = currentDate.diff(editedDate, 'hour')
  const diffInDays = currentDate.diff(editedDate, 'day')

  if (diffInMinutes < 1) {
    return 'A few seconds ago'
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`
  } else if (diffInDays < 5) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
  } else {
    return `Last edited: ${editedDate.format('YYYY-MM-DD')}`
  }
}
