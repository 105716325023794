import { Box, Text } from '@mantine/core'
import { Brand } from 'components/brand/brand'

export function Mobile() {
  return (
    <Box
      display="flex"
      mt={30}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Brand />
      <Box mt={30}>
        <Text>Touch Takeoff is an online takeoff platform that works better on a computer or tablet!</Text>
      </Box>
    </Box>
  )
}
