/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation, useQuery } from '@tanstack/react-query'
import { globalQueryClient } from 'api/client'
import { UserDto, User } from 'api/dto'
import axios from 'config/axios'
import environment from 'config/environment'
import { useCallback } from 'react'

export const useGetUser = (id: string) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => axios.get<UserDto>(environment.restApi.resourceUrl.users + '/' + id),
    select: useCallback((response) => new User(response.data), []),
  })
}

export const useGetUsersInOrganization = () => {
  return useQuery({
    queryKey: ['users', 'list', 'organization'],
    queryFn: () => axios.get<UserDto[]>(environment.restApi.resourceUrl.organizations + '/me/users'),
    select: useCallback((response) => response.data.map((u) => new User(u)), []),
  })
}

export const useGetMeUser = () => {
  return useQuery({
    queryKey: ['user', 'me'],
    queryFn: () => axios.get<UserDto>(environment.restApi.resourceUrl.users + '/me'),
    select: useCallback((response) => new User(response.data), []),
  })
}

export const useUpdateMeUser = () => {
  return useMutation({
    mutationFn: (user: UserDto) => {
      const { id, ...rest } = user
      return axios.put(environment.restApi.resourceUrl.users + '/me', rest)
    },
  })
}

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: (user: UserDto) => {
      const { id, ...rest } = user
      return axios.put(environment.restApi.resourceUrl.users + '/' + user.id, user)
    },
  })
}

export const useSwitchOrganization = () => {
  return useMutation({
    mutationFn: (organizationId: string) =>
      axios.put(environment.restApi.resourceUrl.users + '/me/switch-organization', { organizationId }),
  })
}

export const useGetUsers = () => {
  return useQuery({
    queryKey: ['users', 'list'],
    queryFn: () => axios.get<UserDto[]>(environment.restApi.resourceUrl.users),
    select: useCallback((response) => response.data.map((u) => new User(u)), []),
  })
}

export const useActivateUser = () => {
  return useMutation({
    mutationFn: (userId: string) => axios.put(environment.restApi.resourceUrl.users + '/' + userId + '/activate'),
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list'] })
    },
  })
}

export const useDeactivateUser = () => {
  return useMutation({
    mutationFn: (userId: string) => axios.put(environment.restApi.resourceUrl.users + '/' + userId + '/deactivate'),
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list'] })
    },
  })
}

export const useCreateUser = () => {
  return useMutation({
    mutationFn: (user: Omit<UserDto, 'id'>) => axios.post(environment.restApi.resourceUrl.users, user),
    onSuccess: async () => {
      await globalQueryClient.invalidateQueries({ queryKey: ['users', 'list'] })
    },
  })
}
