import Konva from 'konva'
import { Area } from './area'
import { Count } from './count'
import { Line } from './line'
import { Measure } from './measure'
import { Scale } from './scale'

export type CombinedShapeTypes = Area | Line | Count | Measure | Scale
export type ShapeWithAnchors = Area | Line | Measure | Scale

export function isGroup(instance: Konva.Group | Konva.Shape): instance is Konva.Group {
  return (instance as any).getSelfRect === undefined
}

export function isMeasure(shape: CombinedShapeTypes): shape is Measure {
  return (shape as any).label !== undefined
}

export function isScale(shape: CombinedShapeTypes): shape is Scale {
  return (shape as any).axis !== undefined
}

export function isShapeWithAnchors(shape: CombinedShapeTypes): shape is Area | Line | Measure | Scale {
  return (shape as any).anchorGroup !== undefined
}

export function isCount(shape: CombinedShapeTypes): shape is Count {
  return (shape as any).index !== undefined
}

export function getGroupType(type: unknown) {
  return Number(type as number | string)
}
