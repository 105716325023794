import { globalQueryClient } from 'api/client'
import { getLayerQuery } from 'api/query/layer'
import { getPageQuery } from 'api/query/page'
import { getProjectQuery } from 'api/query/project'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { resetStore, takeoffStore } from 'store/store'

export function ProjectLoader() {
  const { projectId, pageId, layerId } = useParams()

  useEffect(() => {
    // reset store state
    resetStore()

    void globalQueryClient.prefetchQuery({
      queryKey: ['projects', projectId],
      queryFn: () => getProjectQuery(projectId),
    })

    void globalQueryClient.prefetchQuery({
      queryKey: ['projects', projectId, 'pages', pageId],
      queryFn: () => getPageQuery(projectId!, pageId!),
    })

    void globalQueryClient.prefetchQuery({
      queryKey: ['projects', projectId, 'pages', pageId, 'layers', layerId],
      queryFn: () => getLayerQuery(projectId!, pageId!, layerId!),
    })

    if (projectId == null || pageId == null || layerId == null) {
      return
    }

    // Set the config for the store
    const { setConfig } = takeoffStore.getState()
    setConfig({ projectId, pageId, layerId })
  }, [projectId, pageId, layerId])

  return <Outlet />
}
