export interface IOrganizationDto {
  id: string
  name: string
  description?: string
}

export interface CreateOrganization {
  name: string
  description?: string
}

export interface UpdateOrganization {
  name: string
  description?: string
}

export class OrganizationDto {
  id: string
  name: string
  description?: string

  constructor(organization: IOrganizationDto) {
    this.id = organization.id
    this.name = organization.name
    this.description = organization.description
  }
}
