/* eslint-disable @typescript-eslint/naming-convention */
import { ActionIcon, Box, SimpleGrid } from '@mantine/core'
import { useState } from 'react'
import ListIcon from 'assets/icons/list.svg?react'
import ThumbnailIcon from 'assets/icons/thumbnail.svg?react'
import { ColorScheme } from 'theme/theme'
import { Data, ITableProps, Table } from './table'
import { TileProps } from 'components/tile/tile'
import { TableMode } from './mode'

interface AugmentedTableProps<T extends Data> extends ITableProps<T> {
  mode?: TableMode
  Tile: React.FunctionComponent<TileProps<T>>
  canEdit?: boolean
  checked: string[]
  showMenu?: boolean
  showChangeOrderButtons?: boolean
  onCheck?: (item: T) => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AugmentedTable<T extends Data>({ mode, Tile, canEdit, onCheck, showMenu, checked, ...props }: AugmentedTableProps<T>) {
  const [selectedMode, setSelectedMode] = useState(TableMode.THUMBNAIL)

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ActionIcon
          variant="transparent"
          sx={(theme) => ({
            svg: {
              path: {
                fill: `${selectedMode === TableMode.THUMBNAIL ? theme.black : theme.colors[ColorScheme.customGray][5]} !important`,
              },
            },
          })}
          onClick={() => setSelectedMode(TableMode.THUMBNAIL)}
        >
          <ThumbnailIcon height={28} width={28} />
        </ActionIcon>
        <ActionIcon
          ml={10}
          variant="transparent"
          sx={(theme) => ({
            svg: {
              path: {
                fill: `${selectedMode === TableMode.LIST ? theme.black : theme.colors[ColorScheme.customGray][5]} !important`,
              },
            },
          })}
          onClick={() => setSelectedMode(TableMode.LIST)}
        >
          <ListIcon height={28} width={28} />
        </ActionIcon>
      </Box>

      <Box mt={selectedMode === TableMode.THUMBNAIL ? 30 : 20}>
        {selectedMode === TableMode.LIST && <Table<T> {...props} />}
        {selectedMode === TableMode.THUMBNAIL && (
          <SimpleGrid
            cols={{
              base: 1,
              xs: 2,
              sm: 3,
              md: 3,
              xl: 4,
            }}
            spacing="sm"
          >
            {props.data.map((item, i) => (
              <Tile
                key={item.id}
                index={i}
                total={props.data.length}
                item={item}
                canEdit={canEdit}
                onCheck={onCheck}
                checked={checked}
                showMenu={showMenu}
                canNavigate
              />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Box>
  )
}
